import theme from './theme';
import variables from './variables';

export const contentWrapper = {
  contentWrapper: {
    margin: variables.contentWrapperMarginXs,
    [theme.breakpoints.up('sm')]: {
      margin: variables.contentWrapperMarginSm,
    },
    [theme.breakpoints.up('md')]: {
      margin: variables.contentWrapperMarginMd,
    },
  },
  contentWrapperDivider: {
    margin: variables.contentWrapperDividerMargin,
  },
};

const tableCell = {
  paddingRight: '8px !important',
  paddingLeft: '8px',
  [theme.breakpoints.up('md')]: {
    paddingRight: '24px !important',
    paddingLeft: '24px',
  },
};

const headingCell = {
  ...tableCell,
  fontSize: '0.8125rem',
};

export const tableStyles = {
  tableCell,
  headingCell,
  firstHeadingCell: {
    ...headingCell,
    [theme.breakpoints.down('sm')]: {
      width: '90px',
    },
  },
};

export default {
  contentWrapper,
  tableStyles,
};
