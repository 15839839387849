import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography, Link } from '@material-ui/core';
import layouts from '../../styles/layouts';

import area21Logos from '../../images/AREA21_logot.png';
import tuniLogo from '../../images/tuni_logo.jpg';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    logos: {
      width: '500px',
      maxWidth: '100%',
      maxHeight: '110px',
    },
  });
class About extends React.Component<{ classes: any }> {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          About
        </Typography>
        <Typography paragraph={true}>
          This application was made in joint operation by Tampere University of
          Applied Sciences students and staff.
          <br />
          The development of the application was started in Autumn 2018. Version
          1.0.0 was released roughly one year after that.
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Typography variant="h5" color="secondary" gutterBottom={true}>
          Disclaimer
        </Typography>
        <Typography paragraph={true}>
          The Service Provider has made every effort to ensure that the content
          of the Service complies with applicable legislation and that the
          information contained on the Site is accurate and up-to-date, the
          Service Provider does not guarantee the correctness of the information
          and is not responsible for the possible consequences of using the
          Service. The service has been supported by INTERREG Baltic Sea
          Program. The Managing Authority of the Program is not responsible for
          how the information may be used.
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Link href="https://area21-project.eu" target="_blank">
          <img
            src={area21Logos}
            className={classes.logos}
            alt="Interreg Baltic Sea Region, EU European Regional Development Fund"
            title="Interreg Baltic Sea Region, EU European Regional Development Fund"
          />
        </Link>
        <Link href="https://www.tuni.fi/en" target="_blank">
          <img
            src={tuniLogo}
            className={classes.logos}
            alt="Tampere University of Applied Sciences"
            title="Tampere University of Applied Sciences"
          />
        </Link>
      </section>
    );
  }
}

export default withStyles(styles)(About);
