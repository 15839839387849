export default {
  drawerWidth: '300px',
  loadingBarWidth: '360px',
  dialogWidth: '360px',
  formWidth: '360px',
  paperFormWidth: '408px',
  paperPadding: '24px',
  appBarHeight: '64px',
  tabsMargin: '30px 0px',
  subMenuItemPadding: '34px',
  tableMaxWidth: '1000px',
  contentWrapperMarginXs: '30px 10px',
  contentWrapperMarginSm: '30px 20px',
  contentWrapperMarginMd: '30px 30px',
  contentWrapperDividerMargin: '0px 0px 30px 0px',
};
