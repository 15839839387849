import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import { Divider, Typography } from '@material-ui/core';
import { get } from 'lodash';

import UserForm from './UserForm';
import layouts from '../../styles/layouts';
import Loading from '../common/Loading';
import Error from '../common/Error';
import { USERS_WITH_APARTMENTS_QUERY } from '../../data/queries';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
  });

interface IProps {
  classes: any;
}

class Users extends React.Component<
  IProps & RouteComponentProps<{ id: string }>
> {
  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Query query={USERS_WITH_APARTMENTS_QUERY} variables={{ id }}>
          {({ data, loading, error }) => (
            <React.Fragment>
              <Typography variant="h4" color="secondary" gutterBottom={true}>
                User
              </Typography>
              <Divider className={classes.contentWrapperDivider} />
              {error ? (
                <Error />
              ) : loading ? (
                <Loading />
              ) : (
                <UserForm user={get(data, 'users[0]', {})} />
              )}
            </React.Fragment>
          )}
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Users));
