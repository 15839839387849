import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#b9d66f',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: '#09AC94',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated to contrast with palette.secondary.main
    },
    // error: will use the default color
  },
  typography: {},
  overrides: {
    MuiListItemIcon: {
      root: {
        marginRight: '0px',
      },
    },
    MuiGrid: {
      container: {
        maxWidth: '100%',
      },
    },
  },
});

export default theme;
