import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { get } from 'lodash';

import Error from '../common/Error';
import Loading from '../common/Loading';
import SensorGraph from '../common/SensorGraph';
import WaterGraph from '../common/WaterGraph';
import { APARTMENTS_SENSORS_QUERY } from '../../data/queries';
import layouts from '../../styles/layouts';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    sensorName: {
      margin: '24px 0px',
    },
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
  });

class Dashboard extends React.Component<
  { classes: any } & RouteComponentProps<{ id: string }>
> {
  render() {
    // console.log('Dashboard.render()');
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Dashboard
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Query
          query={APARTMENTS_SENSORS_QUERY}
          variables={{ id }}
          pollInterval={60000}
        >
          {({ data, error, loading }) => (
            <React.Fragment>
              {error ? (
                <Error />
              ) : loading ? (
                <Loading />
              ) : (
                <React.Fragment>
                  <div>
                    {get(data, 'apartments[0].sensors', []).length > 0 &&
                      data.apartments[0].sensors
                        // The sort is kind of magical thing,
                        // but basically by type: ERS_LITE -> ERS -> ERS_CO2 -> ELT_LITE -> null
                        .sort((a, b) => {
                          // Sensors with no types are sorted down but not moved in relation to each other
                          if (a.type === null) {
                            return 0;
                          }
                          // Sort ERS up
                          if (a.type === 'ERS') {
                            return -1;
                          }
                          // Last, but not least, if none of the above match:
                          return a.type < b.type ? 1 : -1;
                        })
                        .map(sensor => (
                          <section key={sensor.devEUI}>
                            <Typography
                              variant="h5"
                              gutterBottom={true}
                              className={classes.sensorName}
                            >
                              Sensor location: {sensor.location || 'Unknown'}
                            </Typography>
                            <SensorGraph sensor={sensor} />
                          </section>
                        ))}
                    <br />
                    <Divider />
                    {/* TODO: Rework water data section */}
                    {data.apartments && data.apartments[0].water.length > 0 && (
                      <section>
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          className={classes.sensorName}
                        >
                          Sensor location: Heat Distribution Room
                        </Typography>
                        <WaterGraph data={data.apartments[0].water} />
                      </section>
                    )}
                    {data.apartments &&
                      data.apartments[0].sensors.length === 0 &&
                      data.apartments[0].water.length === 0 && (
                        <Typography variant="body1">
                          Apartment has no sensors.
                        </Typography>
                      )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Dashboard));
