import React from 'react';
import { Route } from 'react-router-dom';
import { Query } from 'react-apollo';

import { AUTHENTICATED_QUERY } from '../data/queries';
import NotFound from '../components/common/NotFound';

interface IProps {
  component: any;
  exact?: boolean;
  path: string;
}

class PrivateRoute extends React.Component<IProps> {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Query query={AUTHENTICATED_QUERY}>
        {({ data: { authenticated } }) => (
          <Route
            {...rest}
            render={props =>
              authenticated ? <Component {...props} /> : <NotFound {...props} />
            }
          />
        )}
      </Query>
    );
  }
}

export default PrivateRoute;
