import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Button,
  TextField,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import layouts from '../../styles/layouts';
import { CREATE_APARTMENT_MUTATION } from '../../data/mutations';
import variables from '../../styles/variables';
import Loading from '../common/Loading';
import { get } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    field: {
      maxWidth: variables.formWidth,
    },
  });

interface IProps {
  classes: any;
}

interface IAddApartmentState {
  apartmentNumber: string;
  apartmentNumberValid: boolean;
}

type AllProps = IProps & RouteComponentProps;

class AddApartment extends React.Component<AllProps, IAddApartmentState> {
  constructor(props: AllProps) {
    super(props);
    this.state = { apartmentNumber: '', apartmentNumberValid: true };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  public render() {
    const { classes } = this.props;
    const { apartmentNumber, apartmentNumberValid } = this.state;

    return (
      <Mutation mutation={CREATE_APARTMENT_MUTATION}>
        {(createApartment, { loading, error }) => (
          <section className={classes.contentWrapper}>
            <Typography variant="h4" color="secondary">
              Add apartment
            </Typography>
            <form
              autoComplete="off"
              onSubmit={async e => {
                e.preventDefault();
                const { history } = this.props;
                this.setState({
                  apartmentNumberValid: apartmentNumber.length > 0,
                });
                if (apartmentNumber.length > 0) {
                  const result = await createApartment({
                    variables: { number: apartmentNumber },
                  });
                  const { id } = get(
                    result,
                    'data.createApartment.apartment',
                    '',
                  );
                  history.push(`/apartments/${id}/edit`);
                }
              }}
            >
              <TextField
                id="apartmentNumber"
                label="Apartment number"
                value={apartmentNumber}
                onChange={this.handleChange('apartmentNumber')}
                margin="normal"
                variant="outlined"
                classes={{ root: classes.field }}
                error={!apartmentNumberValid}
                helperText={apartmentNumberValid ? ' ' : 'Invalid number'}
                disabled={loading}
              />
              <div>
                {loading && <Loading />}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  classes={{ root: classes.field }}
                >
                  Create
                </Button>
              </div>
              {error && (
                <Typography display="inline" color="error">
                  {error.message}
                </Typography>
              )}
            </form>
          </section>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(withRouter(AddApartment));
