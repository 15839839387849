import React from 'react';

export const OothContext = React.createContext(undefined);

interface IProps {
  oothClient: any;
}

export class OothProvider extends React.Component<IProps> {
  render() {
    return (
      <OothContext.Provider value={this.props.oothClient}>
        {this.props.children}
      </OothContext.Provider>
    );
  }
}

export const OothConsumer = OothContext.Consumer;

export const withOoth = Component => {
  return function WrapperComponent(props) {
    return (
      <OothConsumer>
        {client => <Component {...props} oothClient={client} />}
      </OothConsumer>
    );
  };
};
