import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  formatDistanceToNow,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';

const useStyles = makeStyles({
  yellow: {
    color: 'yellow',
  },
  red: {
    color: 'red',
  },
});

const LastValues = ({ sensorData }) => {
  const classes = useStyles();

  const latestData = sensorData ? sensorData[0] : null;

  if (!latestData || !latestData.time) return <div>---</div>;

  const initialTime = new Date(sensorData[sensorData.length - 1].time);
  const latestTime = new Date(latestData.time);
  const averageTimeBetween =
    differenceInMinutes(latestTime, initialTime) / sensorData.length;

  const distanceToNow = formatDistanceToNow(latestTime, {
    addSuffix: true,
  });

  let timeColor = 'black';
  const dataDelay = differenceInDays(new Date(), latestTime);
  if (dataDelay > 30) {
    timeColor = 'red';
  } else if (dataDelay > 1) {
    timeColor = 'yellow';
  }

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <div className={classes[timeColor]} title={latestData.time}>
        {distanceToNow}
      </div>
      {latestData.voltage != null && <div>{latestData.voltage}V</div>}
      {latestData.temperature != null && (
        <span>{latestData.temperature}C </span>
      )}
      {latestData.humidity != null && <span>{latestData.humidity}% </span>}
      {latestData.co2 != null && <span>{latestData.co2}ppm</span>}
      {latestData.power != null && <div>{latestData.power}W</div>}
      <div>Average time between values: {averageTimeBetween} minutes</div>
    </div>
  );
};

export default LastValues;
