import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface IProps {
  visible: boolean;
  toggleVisibility: any;
}

class ShowPasswordToggle extends React.Component<IProps> {
  render() {
    const { toggleVisibility, visible } = this.props;
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={toggleVisibility}
        >
          {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    );
  }
}

export default ShowPasswordToggle;
