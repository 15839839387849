import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import { API_HOST, API_PORT } from '../env';
import { defaults } from './defaults';

const cache = new InMemoryCache();

cache.writeData({
  data: {
    ...defaults,
  },
});

const httpLink = new HttpLink({
  uri: `${API_HOST}${API_PORT ? `:${API_PORT}` : ''}/graphql`,
  credentials: 'include',
});

export const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([httpLink]),
  // "new query will be mapped to the same promise as identical query in flight"
  // -> queries fail after logout and reset store
  queryDeduplication: false,
  resolvers: {},
});
