import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import BuildingsSection from './BuildingsSection';

class DrawerContent extends React.Component<RouteComponentProps> {
  render() {
    // console.log('PublicSection.render()');
    const { history } = this.props;

    return (
      <div>
        <List>
          <ListSubheader disableSticky={true}>Monitor</ListSubheader>
          <BuildingsSection initiallyOpen={true} />
          <Divider />
          <ListSubheader disableSticky={true}>Application</ListSubheader>
          <ListItem button={true} onClick={() => history.push('/about')}>
            <ListItemIcon>
              <InfoIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withRouter(DrawerContent);
