import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, WithStyles, Theme } from '@material-ui/core';
import {
  subWeeks,
  isSameISOWeek,
  subMonths,
  isAfter,
  differenceInSeconds,
} from 'date-fns';
import { round } from 'lodash';

const styles: any = (theme: Theme) =>
  createStyles({
    subheading: {
      fontSize: '1.125rem',
      marginTop: '16px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    button: {
      margin: theme.spacing(),
    },
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
  });

interface IPower {
  readonly time: Date;
  readonly power: number;
}

interface IProps extends WithStyles<typeof styles> {
  readonly data: ReadonlyArray<IPower>;
}

const powerToEnergy = (power: ReadonlyArray<IPower>) => {
  let cumulativeEnergy = 0;
  power.forEach((dataPoint, index) => {
    if (index < power.length - 1) {
      const timePeriod = Math.abs(
        differenceInSeconds(dataPoint.time, power[index + 1].time) / 3600,
      );
      if (dataPoint.power > 0) {
        cumulativeEnergy += (dataPoint.power / 1000) * timePeriod;
      }
    }
  });
  return cumulativeEnergy;
};

const last30DaysEnergy = (power: ReadonlyArray<IPower>) => {
  const last30Days = power.filter(dataPoint =>
    isAfter(dataPoint.time, subMonths(new Date(), 1)),
  );

  return powerToEnergy(last30Days);
};

const lastWeekEnergy = (power: ReadonlyArray<IPower>) => {
  const lastWeek = power.filter(dataPoint =>
    isSameISOWeek(dataPoint.time, subWeeks(new Date(), 1)),
  );
  return powerToEnergy(lastWeek);
};

const thisWeekEnergy = (power: ReadonlyArray<IPower>) => {
  const thisWeek = power.filter(dataPoint =>
    isSameISOWeek(dataPoint.time, new Date()),
  );
  return powerToEnergy(thisWeek);
};

class EnergyUsage extends React.Component<IProps> {
  handleStartDateChange = (date: Date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date: Date) => {
    this.setState({ endDate: date });
  };

  render() {
    const { classes, data } = this.props;
    // console.log('Usage.render()');
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={6}>
          <Typography
            classes={{ root: classes.subheading }}
            gutterBottom={true}
          >
            Usage
          </Typography>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Typography>{`Last 30 days: ${round(
                last30DaysEnergy(data),
                1,
              )} kWh`}</Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography>{`Last week: ${round(
                lastWeekEnergy(data),
                1,
              )} kWh`}</Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography>{`Current week: ${round(
                thisWeekEnergy(data),
                1,
              )} kWh`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(EnergyUsage);
