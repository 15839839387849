import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import { Button, Divider, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MUIDataTable from 'mui-datatables';
import { get } from 'lodash';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

import layouts from '../../styles/layouts';
import Loading from '../common/Loading';
import Error from '../common/Error';
import {
  USERS_WITH_APARTMENTS_QUERY,
  CURRENT_USER_QUERY,
} from '../../data/queries';
import { UserLevel, convert } from '../../utils/userLevel';
import { DELETE_USER_MUTATION } from '../../data/mutations';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    ...layouts.tableStyles,
    tableWrapper: {
      maxWidth: '1200px',
    },
    actionButton: {
      [breakpoints.down('sm')]: {
        padding: '2px',
        '& svg': {
          height: '0.9rem',
          width: '0.9rem',
        },
      },
    },
    tableRoot: {
      '& thead': {
        '& th': {
          fontSize: '0.8125rem',
        },
      },
    },
    addButton: {
      marginBottom: '24px',
    },
  });

const showAction = (userLevelName, currentUserLevel: number) => {
  const userLevel = convert(userLevelName);
  if (userLevel === UserLevel.Staff || userLevel === UserLevel.Admin) {
    return currentUserLevel === UserLevel.Admin;
  }
  return true;
};

const userLevelString = (userLevel: string) => {
  if (userLevel && userLevel.length > 1) {
    return userLevel.charAt(0).toUpperCase() + userLevel.slice(1).toLowerCase();
  }
  return '';
};

interface IProps extends RouteComponentProps {
  classes: any;
}

interface IState {
  confirmationDialog: boolean;
  targetUser: string;
  targetId: string;
}

class Users extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { confirmationDialog: false, targetId: '', targetUser: '' };
  }

  handleDeleteUser = (deleteUser, targetId) => {
    deleteUser({ variables: { id: targetId } }).then(() => {
      this.setState({ confirmationDialog: false });
    });
  };

  render() {
    const { classes, history } = this.props;
    const { confirmationDialog, targetUser, targetId } = this.state;

    return (
      <section className={classes.contentWrapper}>
        <Mutation
          mutation={DELETE_USER_MUTATION}
          refetchQueries={[{ query: USERS_WITH_APARTMENTS_QUERY }]}
        >
          {(deleteUser, { loading, error }) =>
            error ? (
              <Error />
            ) : loading ? (
              <Loading />
            ) : (
              <ConfirmationDialog
                open={confirmationDialog}
                title={`Do you want to delete ${targetUser}?`}
                targetName={targetUser}
                targetId={targetId}
                subject={'user'}
                onCancel={() =>
                  this.setState({
                    confirmationDialog: !confirmationDialog,
                    targetId: '',
                    targetUser: '',
                  })
                }
                onCommit={() => this.handleDeleteUser(deleteUser, targetId)}
              />
            )
          }
        </Mutation>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Users
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Button
          variant="outlined"
          color="secondary"
          classes={{ root: classes.addButton }}
          onClick={() => history.push('/users/add')}
        >
          <PersonAddIcon />
          &nbsp; Add user
        </Button>
        <Query query={CURRENT_USER_QUERY}>
          {({ data: { currentUser } }) => (
            <Query query={USERS_WITH_APARTMENTS_QUERY}>
              {({ data, loading, error }) =>
                error ? (
                  <Error />
                ) : loading ? (
                  <Loading />
                ) : (
                  <div className={classes.tableWrapper}>
                    <MUIDataTable
                      classes={{ tableRoot: classes.tableRoot }}
                      title="Users"
                      columns={[
                        {
                          name: 'Firstname',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Lastname',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Email',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Apartments',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Role',
                          options: {
                            sort: true,
                            filter: true,
                            display: false,
                          },
                        },
                        {
                          name: 'Edit',
                          options: {
                            sort: false,
                            filter: false,
                          },
                        },
                        {
                          name: 'Delete',
                          options: {
                            sort: false,
                            filter: false,
                          },
                        },
                      ]}
                      // TODO: Move custom rendering from data to customBodyRender,
                      // as done in Buildings.tsx and Apartments.tsx
                      // This is deprecated in the latest mui-datatables
                      data={get(data, 'users', []).map(user => [
                        get(user, 'firstName', ''),
                        get(user, 'lastName', ''),
                        user.email,
                        get(user, 'apartments', [])
                          .map(
                            apartment =>
                              `${get(apartment, 'building.address', '')} ${
                                apartment.number
                              }`,
                          )
                          .join('; '),
                        userLevelString(user.userLevel),
                        <React.Fragment key={user.id}>
                          {showAction(
                            user.userLevel,
                            currentUser.userLevel,
                          ) && (
                            <IconButton
                              classes={{ root: classes.actionButton }}
                              aria-label="Edit"
                              color="secondary"
                              onClick={() => history.push(`/users/${user.id}`)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </React.Fragment>,
                        <React.Fragment key={user.id}>
                          {showAction(
                            user.userLevel,
                            currentUser.userLevel,
                          ) && (
                            <IconButton
                              classes={{ root: classes.actionButton }}
                              aria-label="Delete"
                              color="secondary"
                              onClick={() =>
                                this.setState({
                                  confirmationDialog: true,
                                  targetUser: `${user.firstName} ${
                                    user.lastName
                                  }`,
                                  targetId: user.id,
                                })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </React.Fragment>,
                      ])}
                      options={{
                        print: false,
                        download: false,
                        selectableRows: 'none',
                        elevation: 1,
                        rowsPerPageOptions: [10, 25, 50, 100],
                      }}
                    />
                  </div>
                )
              }
            </Query>
          )}
        </Query>
      </section>
    );
  }
}

export default withStyles(styles)(Users);
