import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import WifiIcon from '@material-ui/icons/Wifi';
import BuildingsSection from './BuildingsSection';

class StaffSection extends React.Component<{} & RouteComponentProps> {
  render() {
    // console.log('StaffSection.render()');
    const { history } = this.props;

    return (
      <React.Fragment>
        <ListSubheader disableSticky={true}>Manage</ListSubheader>
        <ListItem button={true} onClick={() => history.push('/buildings/edit')}>
          <ListItemIcon>
            <LocationCityIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Buildings" />
        </ListItem>
        <ListItem button={true} onClick={() => history.push('/apartments')}>
          <ListItemIcon>
            <HomeIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Apartments" />
        </ListItem>
        <ListItem button={true} onClick={() => history.push('/sensors')}>
          <ListItemIcon>
            <WifiIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Sensors" />
        </ListItem>
        <ListItem button={true} onClick={() => history.push('/users')}>
          <ListItemIcon>
            <PeopleIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <Divider />
        <ListSubheader disableSticky={true}>Monitor</ListSubheader>
        <BuildingsSection initiallyOpen={false} />
        <Divider />
      </React.Fragment>
    );
  }
}

export default withRouter(StaffSection);
