export enum UserLevel {
  Tenant,
  Staff,
  Admin,
}

export enum LevelName {
  Tenant = 'TENANT',
  Staff = 'STAFF',
  Admin = 'ADMIN',
}

const names = [LevelName.Tenant, LevelName.Staff, LevelName.Admin];

export const convert = (level: LevelName) => {
  const index = names.indexOf(level);
  return index === -1 ? 0 : index;
};
