import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/HomeSharp';
import WifiIcon from '@material-ui/icons/Wifi';
import { get } from 'lodash';

import Error from '../common/Error';
import Loading from '../common/Loading';
import { BUILDING_EDIT_QUERY, APARTMENTS_QUERY } from '../../data/queries';
import layouts from '../../styles/layouts';
import variables from '../../styles/variables';
import { IBuilding } from '../sensors/types';
import {
  UPDATE_BUILDING_MUTATION,
  DELETE_BUILDING_MUTATION,
  ADD_SENSOR_TO_BUILDING_MUTATION,
  REMOVE_SENSOR_FROM_BUILDING_MUTATION,
  ADD_APARTMENT_TO_BUILDING_MUTATION,
  REMOVE_APARTMENT_FROM_BUILDING_MUTATION,
} from '../../data/mutations';
import SensorSelector from '../common/SensorSelector';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import ApartmentAdder from '../common/ApartmentAdder';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    paper: {
      margin: '30px 0px',
      padding: variables.paperPadding,
    },
    chip: {
      fontSize: '1rem',
    },
    tenantButton: {
      textTransform: 'none',
      padding: '0px',
    },
    divider: {
      margin: '24px 0px',
    },
    field: {
      maxWidth: variables.formWidth,
    },
  });

const tenantName = tenant => {
  const name = `${get(tenant, 'firstName', '')} ${get(tenant, 'lastName', '')}`;
  return name.length > 1 ? name : tenant.email;
};

interface IProps {
  building: IBuilding;
  classes: any;
}

interface IState {
  address: string;
  editEnabled: boolean;
  addressValid: boolean;
  showSensorSelector: boolean;
  showApartmentSelector: boolean;
  showConfirmation: boolean;
  targetId: string;
}

type AllProps = IProps & RouteComponentProps;

class BuildingForm extends React.Component<AllProps, IState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      address: this.props.building.address,
      editEnabled: false,
      addressValid: true,
      showSensorSelector: false,
      showApartmentSelector: false,
      showConfirmation: false,
      targetId: '',
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  handleCommitValues = commitValues => {
    const { id } = this.props.building;
    const { address } = this.state;
    const addressValid = address.length > 0;
    this.setState({
      addressValid,
    });
    if (addressValid) {
      commitValues({
        variables: { id, address },
      }).then(() => {
        this.setState({ editEnabled: false });
      });
    }
  };

  handleCancel = () => {
    const { building } = this.props;
    this.setState(state => ({
      ...state,
      building,
    }));
    this.toggleEditMode();
  };

  handleDeleteBuilding = (deleteBuilding, targetId) => {
    deleteBuilding({ variables: { id: targetId } })
      .then(() => {
        this.setState({ showConfirmation: false });
      })
      .then(() => {
        this.props.history.push(`/buildings/edit`);
      });
  };

  toggleEditMode = () => {
    const { editEnabled } = this.state;
    this.setState({ editEnabled: !editEnabled });
  };

  addSensor = (addSensorToBuilding, buildingId: string) => (devEUI: string) => {
    addSensorToBuilding({
      variables: {
        buildingId,
        devEUI,
      },
    }).then(() => {
      this.toggleSensorSelector(false);
    });
  };

  removeSensor = (removeSensorFromBuilding, devEUI: string) => {
    const { id } = this.props.building;
    removeSensorFromBuilding({
      variables: {
        devEUI,
        buildingId: id,
      },
    });
  };

  toggleSensorSelector = (visible: boolean) => {
    this.setState({ showSensorSelector: visible });
  };

  addApartment = (addApartmentToBuilding, buildingId: string) => (
    apartmentId: string,
  ) => {
    addApartmentToBuilding({
      variables: {
        buildingId,
        apartmentId,
      },
    }).then(() => {
      this.toggleApartmentSelector(false);
    });
  };

  removeApartment = (removeApartmentFromBuilding, apartmentId: string) => {
    const { id } = this.props.building;
    removeApartmentFromBuilding({
      variables: {
        apartmentId,
        buildingId: id,
      },
    });
  };

  toggleApartmentSelector = (visible: boolean) => {
    this.setState({ showApartmentSelector: visible });
  };

  render() {
    const { classes, history } = this.props;
    const {
      editEnabled,
      addressValid,
      showSensorSelector,
      showApartmentSelector,
      showConfirmation,
      targetId,
    } = this.state;
    const { address } = this.state;
    const { id, sensors, apartments } = this.props.building;
    return (
      <Paper elevation={1} square={true} classes={{ root: classes.paper }}>
        <form autoComplete="off">
          <Grid container={true} spacing={2}>
            <Grid
              item={true}
              xs={12}
              md={6}
              classes={{ item: classes.firstItem }}
            >
              <Grid container={true} direction="column">
                <Typography variant="h6" gutterBottom={true}>
                  Building details
                </Typography>
                <TextField
                  disabled={!editEnabled}
                  error={!addressValid}
                  label="Address"
                  id="address"
                  variant="outlined"
                  value={address}
                  onChange={this.handleChange('address')}
                  margin="normal"
                  classes={{ root: classes.field }}
                  helperText={addressValid ? ' ' : 'Invalid address'}
                />
              </Grid>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Mutation
                mutation={ADD_SENSOR_TO_BUILDING_MUTATION}
                refetchQueries={[{ query: BUILDING_EDIT_QUERY }]}
              >
                {(addSensorToBuilding, { loading }) => (
                  <Grid
                    container={true}
                    direction="column"
                    classes={{ container: classes.field }}
                  >
                    <Typography variant="h6" gutterBottom={true}>
                      Sensors
                    </Typography>
                    {showSensorSelector && !loading && (
                      <SensorSelector
                        save={this.addSensor(addSensorToBuilding, id)}
                        cancel={() => this.toggleSensorSelector(false)}
                      />
                    )}
                    {editEnabled && !showSensorSelector && !loading && (
                      <>
                        <Button
                          variant="outlined"
                          classes={{ root: classes.addButton }}
                          onClick={() => this.toggleSensorSelector(true)}
                        >
                          <AddIcon />
                          &nbsp; Add sensor
                        </Button>
                      </>
                    )}
                    {editEnabled && loading && <Loading />}
                  </Grid>
                )}
              </Mutation>
              <Mutation
                mutation={REMOVE_SENSOR_FROM_BUILDING_MUTATION}
                refetchQueries={[{ query: BUILDING_EDIT_QUERY }]}
              >
                {(removeSensorFromBuilding, { loading }) => (
                  <>
                    {sensors.map(sensor => (
                      <div className={classes.chipWrapper} key={sensor.devEUI}>
                        {!editEnabled && (
                          <Chip
                            classes={{ root: classes.chip }}
                            key={sensor.devEUI}
                            label={`${sensor.devEUI}`}
                            variant="outlined"
                            color="secondary"
                            icon={<WifiIcon />}
                            onClick={() =>
                              history.push(`/sensors/${sensor.devEUI}`)
                            }
                          />
                        )}
                        {editEnabled && !loading && (
                          <Chip
                            classes={{ root: classes.chip }}
                            key={sensor.devEUI}
                            label={`${sensor.devEUI}`}
                            variant="outlined"
                            icon={<WifiIcon />}
                            color="primary"
                            onDelete={() =>
                              this.removeSensor(
                                removeSensorFromBuilding,
                                sensor.devEUI,
                              )
                            }
                          />
                        )}
                      </div>
                    ))}
                    {editEnabled && loading && <Loading />}
                  </>
                )}
              </Mutation>
            </Grid>
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} xs={12}>
              <Mutation
                mutation={ADD_APARTMENT_TO_BUILDING_MUTATION}
                refetchQueries={[
                  { query: BUILDING_EDIT_QUERY },
                  { query: APARTMENTS_QUERY },
                ]}
              >
                {(addApartmentToBuilding, { loading }) => (
                  <Grid
                    container={true}
                    direction="column"
                    classes={{ container: classes.field }}
                  >
                    <>
                      {showApartmentSelector && !loading && (
                        <ApartmentAdder
                          save={this.addApartment(addApartmentToBuilding, id)}
                          cancel={() => this.toggleApartmentSelector(false)}
                        />
                      )}
                      {editEnabled && !showApartmentSelector && !loading && (
                        <>
                          <Button
                            variant="outlined"
                            classes={{ root: classes.addButton }}
                            onClick={() => this.toggleApartmentSelector(true)}
                          >
                            <AddIcon />
                            &nbsp; Add apartment
                          </Button>
                        </>
                      )}
                      {editEnabled && loading && <Loading />}
                    </>
                  </Grid>
                )}
              </Mutation>
              <Mutation
                mutation={REMOVE_APARTMENT_FROM_BUILDING_MUTATION}
                refetchQueries={[{ query: BUILDING_EDIT_QUERY }]}
              >
                {(removeApartmentFromBuilding, { loading }) => (
                  <>
                    <Typography variant="h6" gutterBottom={true}>
                      Apartments
                    </Typography>
                    {apartments.length === 0 && (
                      <Typography variant="body1" gutterBottom={true}>
                        No apartments
                      </Typography>
                    )}
                    <Grid container={true} spacing={2}>
                      {apartments.map(apartment => (
                        <Grid
                          item={true}
                          xs={12}
                          md={6}
                          lg={4}
                          key={apartment.id}
                        >
                          <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                              {!editEnabled && (
                                <Chip
                                  classes={{ root: classes.chip }}
                                  label={apartment.number}
                                  variant="outlined"
                                  color="secondary"
                                  icon={<HomeIcon />}
                                  onClick={() =>
                                    history.push(
                                      `/apartments/${apartment.id}/edit`,
                                    )
                                  }
                                />
                              )}
                              {editEnabled && !loading && (
                                <Chip
                                  classes={{ root: classes.chip }}
                                  label={apartment.number}
                                  variant="outlined"
                                  color="secondary"
                                  icon={<HomeIcon />}
                                  onDelete={() =>
                                    this.removeApartment(
                                      removeApartmentFromBuilding,
                                      apartment.id,
                                    )
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item={true} xs={12}>
                              <Typography variant="body1">Tenants</Typography>
                              {apartment.tenants.map(tenant => (
                                <div key={tenant.id}>
                                  <Button
                                    classes={{
                                      root: classes.tenantButton,
                                    }}
                                    color="secondary"
                                    onClick={() =>
                                      history.push(`/users/${tenant.id}`)
                                    }
                                  >
                                    {tenantName(tenant)}
                                  </Button>
                                </div>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </Mutation>
            </Grid>
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              {!editEnabled && (
                <Grid container={true} direction="column">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.toggleEditMode}
                    classes={{ root: classes.field }}
                  >
                    Edit building
                  </Button>
                </Grid>
              )}
              {editEnabled && (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <Mutation
                      mutation={UPDATE_BUILDING_MUTATION}
                      refetchQueries={[{ query: BUILDING_EDIT_QUERY }]}
                    >
                      {(updateBuilding, { loading, error }) =>
                        error ? (
                          <Error />
                        ) : loading ? (
                          <Loading />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={() =>
                              this.handleCommitValues(updateBuilding)
                            }
                          >
                            Save
                          </Button>
                        )
                      }
                    </Mutation>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Button
                      onClick={this.handleCancel}
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Mutation
                      mutation={DELETE_BUILDING_MUTATION}
                      refetchQueries={[{ query: BUILDING_EDIT_QUERY }]}
                    >
                      {(deleteBuilding, { loading, error }) =>
                        error ? (
                          <Error />
                        ) : loading ? (
                          <Loading />
                        ) : (
                          <>
                            <Button
                              onClick={() =>
                                this.setState({
                                  showConfirmation: true,
                                  targetId: id,
                                })
                              }
                              variant="outlined"
                              color="secondary"
                              fullWidth={true}
                            >
                              Delete
                            </Button>
                            <ConfirmationDialog
                              open={showConfirmation}
                              title={`Do you want to delete ${address}?`}
                              targetName={address}
                              targetId={targetId}
                              subject={'building'}
                              onCancel={() =>
                                this.setState({
                                  showConfirmation: !showConfirmation,
                                  targetId: '',
                                })
                              }
                              onCommit={() =>
                                this.handleDeleteBuilding(
                                  deleteBuilding,
                                  targetId,
                                )
                              }
                            />
                          </>
                        )
                      }
                    </Mutation>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(BuildingForm));
