import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import layouts from '../../styles/layouts';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
  });

class PermissionDenied extends React.Component<{ classes: any }> {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Permission denied
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Typography variant="body1">
          You do not have a permission to access this content.
        </Typography>
      </section>
    );
  }
}

export default withStyles(styles)(PermissionDenied);
