import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from '../env';

const initGA = () => {
  if (GA_TRACKING_ID) {
    ReactGA.initialize(GA_TRACKING_ID, {
      debug: process.env.NODE_ENV !== 'production' ? true : false,
    });
  } else {
    // throw Error('No Google Analytics ID provided');
    // console.log('No GA_TRACKING_ID provided, Google Analytics not running.');
  }
};

export default initGA;
