import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

import InfoPanel from './InfoPanel';
import { option } from '../../charts/temperature';
import { temperatureText } from '../../data/texts';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
    subheading: {
      fontSize: '1.125rem',
      marginTop: '16px',
    },
  });

interface ITemp {
  readonly time: Date;
  readonly temperature: number;
}

interface IProps {
  readonly classes: any;
  readonly data: ReadonlyArray<ITemp>;
  readonly infopanel?: boolean;
  readonly averages?: boolean;
}

class Temperature extends React.Component<IProps> {
  render() {
    const { classes, data, infopanel, averages } = this.props;
    // console.log('Temperature.render()');
    // console.log('data', data);

    // For some reason, negative temperatures are reported by sensors
    // as having ~6553,5 added to them, to be on the safe side that
    // the absolute zero hasn't been readjusted, deduct this number
    // from all temperatures over 6k
    // If this ever gets fixed, this is adjustment is no longer needed
    const temperatureData = data.map((obj: ITemp) =>
      obj.temperature > 6000
        ? Number.parseFloat((obj.temperature - 6553.5).toPrecision(2))
        : obj.temperature,
    );

    return (
      <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
        {infopanel ? (
          <InfoPanel
            title="Temperature"
            content={<Typography>{temperatureText}</Typography>}
          />
        ) : (
          <Typography variant="h6" gutterBottom={true}>
            Temperature
          </Typography>
        )}
        <ReactEcharts
          option={option(
            data.map(obj => obj.time),
            temperatureData.map(obj => obj),
          )}
        />
        {averages && (
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={6}>
              <Typography
                classes={{ root: classes.subheading }}
                gutterBottom={true}
              >
                Averages
              </Typography>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <Typography>Today: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>Yesterday: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>This week: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>Last week: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>This month: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>Last month: -</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Typography
                classes={{ root: classes.subheading }}
                gutterBottom={true}
              >
                Range
              </Typography>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <Typography>Today: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>Yesterday: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>This week: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>Last week: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>This month: -</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography>Last month: -</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(Temperature);
