import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import FeedbackIcon from '@material-ui/icons/Feedback';

import AdminSection from './AdminSection';
import StaffSection from './StaffSection';
import TenantSection from './TenantSection';
import ErrorDialog from '../dialogs/ErrorDialog';
import { CURRENT_USER_QUERY } from '../../data';
import { UserLevel } from '../../utils/userLevel';

interface IState {
  errorDialog: boolean;
}

class DrawerContent extends React.Component<RouteComponentProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      errorDialog: false,
    };
  }

  render() {
    // console.log('PrivateSection.render()');
    const { history } = this.props;
    const { errorDialog } = this.state;

    return (
      <div>
        <List>
          <Query query={CURRENT_USER_QUERY}>
            {({
              data: {
                currentUser: { userLevel },
              },
            }) => (
              <React.Fragment>
                {userLevel === UserLevel.Tenant && <TenantSection />}
                {userLevel === UserLevel.Admin && <AdminSection />}
                {userLevel > UserLevel.Tenant && <StaffSection />}
              </React.Fragment>
            )}
          </Query>
          <ListSubheader disableSticky={true}>App</ListSubheader>
          <ListItem button={true} onClick={() => history.push('/about')}>
            <ListItemIcon>
              <InfoIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
          <ListItem button={true} onClick={() => history.push('/feedback')}>
            <ListItemIcon>
              <FeedbackIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItem>
          <Divider />
        </List>
        <ErrorDialog
          open={errorDialog}
          title="Error logging out"
          message="An error occurred logging out. Please try again later."
          onClose={() =>
            this.setState(state => ({ ...state, errorDialog: false }))
          }
        />
      </div>
    );
  }
}

export default withRouter(DrawerContent);
