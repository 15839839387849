import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Divider, ListSubheader } from '@material-ui/core';
import { get } from 'lodash';

import Error from '../common/Error';
import Loading from '../common/Loading';
import BuildingsSection from './BuildingsSection';
import ApartmentSection from './ApartmentSection';
import { CURRENT_USER_QUERY, USERS_WITH_APARTMENTS_QUERY } from '../../data';

const styles = {};

interface IState {
  dashboardsOpen: boolean;
}

class TenantSection extends React.Component<
  { classes: any } & RouteComponentProps,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      dashboardsOpen: true,
    };
  }

  handleApartmentClick = () => {
    this.setState(state => ({
      ...state,
      dashboardsOpen: !state.dashboardsOpen,
    }));
  };

  render() {
    // console.log('TenantSection.render()');
    const { history } = this.props;

    return (
      <React.Fragment>
        <ListSubheader disableSticky={true}>Monitor</ListSubheader>
        <Query query={CURRENT_USER_QUERY}>
          {({ data: { currentUser } }) => (
            <Query
              query={USERS_WITH_APARTMENTS_QUERY}
              variables={{ id: get(currentUser, 'id') }}
            >
              {({ data, error, loading }) =>
                error ? (
                  <Error message="Error loading apartments" />
                ) : loading ? (
                  <Loading />
                ) : (
                  get(data, 'users[0].apartments', []).map(apartment => (
                    <ApartmentSection
                      key={apartment.id}
                      apartment={apartment}
                      history={history}
                    />
                  ))
                )
              }
            </Query>
          )}
        </Query>
        <BuildingsSection initiallyOpen={false} />
        <Divider />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(TenantSection));
