import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    panel: {
      boxShadow: 'none',
      backgroundColor: 'inherit',
    },
    summary: {
      padding: '0px',
    },
    details: {
      padding: '0px',
    },
    icon: {
      right: '0px',
    },
    divider: {
      margin: variables.contentWrapperDividerMargin,
    },
  });

interface IProps {
  classes: any;
  title: string;
  content: any;
}

interface IState {
  expanded: boolean;
}

class InfoPanel extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { classes, title, content } = this.props;
    const { expanded } = this.state;

    return (
      <React.Fragment>
        <ExpansionPanel
          classes={{ root: classes.panel }}
          expanded={expanded}
          onChange={() =>
            this.setState(state => ({ ...state, expanded: !state.expanded }))
          }
        >
          <ExpansionPanelSummary
            classes={{ root: classes.summary, expandIcon: classes.icon }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="body1">{title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.details }}>
            {content}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Divider className={classes.divider} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InfoPanel);
