import gql from 'graphql-tag';

export const sensorInfoFragment = gql`
  fragment sensorInfo on Sensor {
    devEUI
    type
    location
    notes
    apartment {
      number
      building {
        address
      }
    }
  }
`;
