import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import {
  Button,
  Divider,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

import ShowPasswordToggle from './common/ShowPasswordToggle';
import { withOoth } from '../auth/OothContext';
import { mapUser } from '../utils/currentUser';
import variables from '../styles/variables';
import layouts from '../styles/layouts';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    loginForm: {
      maxWidth: variables.formWidth,
      display: 'flex',
      flexDirection: 'column',
    },
    loginButton: {
      marginTop: '10px',
    },
    loading: {
      maxWidth: variables.loadingBarWidth,
      margin: '40px 0px',
    },
    error: {
      margin: '30px 0px',
    },
  });

interface IProps {
  classes: any;
  oothClient: any;
}

interface IState {
  username: string;
  password: string;
  usernameValid: boolean;
  passwordValid: boolean;
  showPassword: boolean;
  loginLoading: boolean;
  loginError: string | null;
}

class Login extends React.Component<IProps & RouteComponentProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      usernameValid: true,
      passwordValid: true,
      showPassword: false,
      loginLoading: false,
      loginError: null,
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  toggleShowPassword = () => {
    this.setState(state => ({ ...state, showPassword: !state.showPassword }));
  };

  handleLogin = async (event, cache) => {
    event.preventDefault();
    const { oothClient, history } = this.props;
    const { username, password } = this.state;

    const usernameValid = username.length > 0;
    const passwordValid = password.length > 0;
    this.setState(state => ({ ...state, usernameValid, passwordValid }));
    if (usernameValid && passwordValid) {
      try {
        this.setState(state => ({ ...state, loginLoading: true }));
        this.setState(state => ({ ...state, loginError: null }));
        const user = await oothClient.authenticate('local', 'login', {
          username,
          password,
        });
        const currentUser = mapUser(user);
        this.setState(state => ({ ...state, loginLoading: false }));
        cache.writeData({ data: { currentUser, authenticated: true } });
        history.push('/');
      } catch (e) {
        this.setState(state => ({ ...state, loginLoading: false }));
        if (e.message.includes('User is already logged in')) {
          cache.writeData({ data: { authenticated: true } });
          // console.log('Already logged in');
          history.push('/');
        } else if (e.message.includes('Incorrect email or username')) {
          this.setState(state => ({ ...state, usernameValid: false }));
        } else if (e.message.includes('Incorrect password')) {
          this.setState(state => ({ ...state, passwordValid: false }));
        } else {
          this.setState(state => ({
            ...state,
            loginError: 'An error occurred. Please try again later.',
          }));
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      username,
      password,
      usernameValid,
      passwordValid,
      showPassword,
      loginLoading,
      loginError,
    } = this.state;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Login
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <ApolloConsumer>
          {cache => (
            <form
              autoComplete="off"
              className={classes.loginForm}
              onSubmit={event => this.handleLogin(event, cache)}
            >
              <TextField
                error={!usernameValid}
                disabled={loginLoading}
                id="username"
                label="Username"
                type="email"
                value={username}
                onChange={this.handleChange('username')}
                margin="dense"
                variant="outlined"
                helperText={usernameValid ? ' ' : 'Invalid username'}
              />
              <TextField
                error={!passwordValid}
                disabled={loginLoading}
                id="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={this.handleChange('password')}
                margin="dense"
                variant="outlined"
                helperText={passwordValid ? ' ' : 'Invalid password'}
                InputProps={{
                  endAdornment: (
                    <ShowPasswordToggle
                      visible={showPassword}
                      toggleVisibility={this.toggleShowPassword}
                    />
                  ),
                }}
              />
              <Button
                className={classes.loginButton}
                variant="contained"
                color="primary"
                disabled={loginLoading}
                type="submit"
              >
                Login
              </Button>
            </form>
          )}
        </ApolloConsumer>
        {loginError && (
          <Typography className={classes.error} color="error">
            {loginError}
          </Typography>
        )}
        {loginLoading && <LinearProgress className={classes.loading} />}
      </section>
    );
  }
}

export default withStyles(styles)(withOoth(withRouter(Login)));
