import React from 'react';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { get } from 'lodash';

import Loading from '../common/Loading';
import Error from '../common/Error';
import { USERS_QUERY } from '../../data';
import { IUser } from '../sensors/types';

const styles = () =>
  createStyles({
    button: {
      marginTop: '24px',
    },
    field: {
      width: '100%',
    },
  });

interface IProps {
  classes: any;
  save: (tenantId: string) => void;
  cancel: any;
}

interface IState {
  selectedTenant: string | IUser;
}

class TenantSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedTenant: '',
    };
  }

  handleSave = () => {
    const { selectedTenant } = this.state;
    if (typeof selectedTenant === 'string') {
      this.props.save(selectedTenant);
    }
  };

  handleCancel = () => {
    this.props.cancel();
  };

  handleSensorChange = event => {
    this.setState({ selectedTenant: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { selectedTenant } = this.state;

    return (
      <Query query={USERS_QUERY}>
        {({ loading, error, data }) =>
          loading ? (
            <Loading />
          ) : error ? (
            <Error message="Couldn't load users." />
          ) : (
            <React.Fragment>
              <FormControl
                variant="outlined"
                margin="normal"
                classes={{ root: classes.field }}
              >
                <InputLabel>Tenant</InputLabel>
                <Select
                  value={selectedTenant}
                  onChange={this.handleSensorChange}
                  input={<OutlinedInput name="tenant" labelWidth={80} />}
                >
                  {get(data, 'users', []).map((tenant: IUser) => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.email}{' '}
                      {tenant.firstName &&
                        tenant.lastName &&
                        ` | Name: ${tenant.firstName} ${tenant.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                    disabled={selectedTenant === ''}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleCancel}
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default withStyles(styles)(TenantSelector);
