import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import variables from '../../styles/variables';

const styles = theme =>
  createStyles({
    dialog: {
      maxWidth: variables.dialogWidth,
    },
    rightIcon: {
      marginLeft: theme.spacing(),
    },
    dialogButton: {
      margin: theme.spacing() * 2,
    },
  });

interface IProps {
  title: string;
  targetName: string;
  targetId: string;
  open: boolean;
  onCommit: any;
  classes: any;
  subject: string;
  onCancel: any;
  isLoading?: boolean;
}

interface IState {
  confirmationText: string;
}

class ConfirmDeleteDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { confirmationText: '' };
  }

  render() {
    const {
      classes,
      targetName,
      onCommit,
      isLoading,
      open,
      title,
      subject,
      onCancel,
    } = this.props;
    const { confirmationText } = this.state;

    return (
      <Dialog
        aria-labelledby="dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{`Please type in the name of the ${subject} to confirm.`}</Typography>
          <TextField
            autoComplete={'off'}
            autoFocus={true}
            margin="dense"
            id="targetname"
            type="text"
            fullWidth={true}
            value={confirmationText}
            onChange={event =>
              this.setState({ confirmationText: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={onCancel}
            color="primary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.dialogButton}
            color="primary"
            disabled={
              confirmationText.toLowerCase() === targetName.toLowerCase()
                ? false
                : true
            }
            onClick={onCommit}
          >
            Delete
            <DeleteIcon className={classes.rightIcon} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfirmDeleteDialog);
