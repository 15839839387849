import React from 'react';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { get } from 'lodash';

import Loading from '../common/Loading';
import Error from '../common/Error';
import { SENSORS_WITH_APARTMENT_QUERY } from '../../data';
import { ISensor } from '../sensors/types';

const styles = () =>
  createStyles({
    button: {
      marginTop: '24px',
    },
    field: {
      width: '100%',
    },
  });

interface IProps {
  classes: any;
  save: (devEUI: string) => void;
  cancel: any;
}

interface IState {
  selectedSensor: string | ISensor;
}

class SensorSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedSensor: '',
    };
  }

  handleSave = () => {
    const { selectedSensor } = this.state;
    if (typeof selectedSensor === 'string') {
      this.props.save(selectedSensor);
    }
  };

  handleCancel = () => {
    this.props.cancel();
  };

  handleSensorChange = event => {
    this.setState({ selectedSensor: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { selectedSensor } = this.state;

    return (
      <Query query={SENSORS_WITH_APARTMENT_QUERY}>
        {({ loading, error, data }) =>
          loading ? (
            <Loading />
          ) : error ? (
            <Error message="Couldn't load sensors." />
          ) : (
            <React.Fragment>
              <FormControl
                variant="outlined"
                margin="normal"
                classes={{ root: classes.field }}
              >
                <InputLabel>Sensor</InputLabel>
                <Select
                  value={selectedSensor}
                  onChange={this.handleSensorChange}
                  input={<OutlinedInput name="sensor" labelWidth={80} />}
                >
                  {get(data, 'sensors', []).map((sensor: ISensor) => (
                    <MenuItem key={sensor.devEUI} value={sensor.devEUI}>
                      {sensor.devEUI}
                      {sensor.type && ` | Type: ${sensor.type}`}
                      {sensor.location && ` | Location: ${sensor.location}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                    disabled={selectedSensor === ''}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleCancel}
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default withStyles(styles)(SensorSelector);
