import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

import InfoPanel from './InfoPanel';
import { waterText } from '../../data/texts';
import variables from '../../styles/variables';
import { waterOption, IWater } from '../../charts/water';
import WaterUsage from './WaterUsage';
// import WaterUsage from './WaterUsage';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
    subheading: {
      fontSize: '1.125rem',
      marginTop: '16px',
    },
  }),
);

interface IProps {
  readonly data: ReadonlyArray<IWater>;
  readonly infopanel?: boolean;
  readonly usage?: boolean;
}

const Water = ({ data, infopanel, usage }) => {
  const classes = useStyles();
  // console.log('Water.render()');
  return (
    <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
      {infopanel ? (
        <InfoPanel
          title="Water"
          content={<Typography>{waterText}</Typography>}
        />
      ) : (
        <Typography variant="h6" gutterBottom={true}>
          Water
        </Typography>
      )}
      <ReactEcharts option={waterOption(data)} />
      {usage && <WaterUsage data={data} />}
    </Paper>
  );
};

export default Water;
