import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Error from '../common/Error';
import Loading from '../common/Loading';
import { APARTMENTS_QUERY } from '../../data/queries';
import layouts from '../../styles/layouts';
import variables from '../../styles/variables';
import ApartmentForm from './ApartmentForm';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    paper: {
      padding: variables.paperPadding,
    },
    chip: {
      fontSize: '1rem',
    },
    buildingButton: {
      textTransform: 'none',
      padding: '0px',
      margin: '10px 0px',
      fontSize: '1.125rem',
    },
  });

interface IProps extends RouteComponentProps<{ id: string }> {
  classes: any;
}

class ApartmentEdit extends React.Component<IProps> {
  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Query query={APARTMENTS_QUERY} variables={{ id }}>
          {({ data, error, loading }) =>
            error ? (
              <Error message={error.message} />
            ) : loading ? (
              <Loading />
            ) : (
              <ApartmentForm apartment={data.apartments[0]} loading={loading} />
            )
          }
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(ApartmentEdit));
