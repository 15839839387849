import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import { Divider, IconButton, Typography, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import MUIDataTable from 'mui-datatables';
import { get } from 'lodash';

import layouts from '../../styles/layouts';
import Loading from '../common/Loading';
import Error from '../common/Error';
import { APARTMENTS_TABLE_QUERY } from '../../data/queries';
import variables from '../../styles/variables';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    tableWrapper: {
      maxWidth: variables.tableMaxWidth,
    },
    editButton: {
      [breakpoints.down('sm')]: {
        padding: '2px',
        '& svg': {
          height: '0.9rem',
          width: '0.9rem',
        },
      },
    },
    addButton: {
      marginBottom: '24px',
    },
    tableRoot: {
      '& thead': {
        '& th': {
          fontSize: '0.8125rem',
        },
      },
    },
  });

interface IProps {
  classes: any;
}

class Apartments extends React.Component<IProps & RouteComponentProps> {
  render() {
    const { classes, history } = this.props;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Apartments
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Button
          variant="outlined"
          color="secondary"
          classes={{ root: classes.addButton }}
          onClick={() => history.push('/apartments/add')}
        >
          <HomeIcon color="secondary" />
          &nbsp; Add apartment
        </Button>
        <Query query={APARTMENTS_TABLE_QUERY}>
          {({ data, loading, error }) =>
            error ? (
              <Error />
            ) : loading ? (
              <Loading />
            ) : (
              <div className={classes.tableWrapper}>
                <MUIDataTable
                  classes={{ tableRoot: classes.tableRoot }}
                  title="Apartments"
                  columns={[
                    {
                      name: 'Building',
                      options: {
                        sort: true,
                        filter: true,
                      },
                    },
                    {
                      name: 'Number',
                      options: {
                        sort: true,
                        filter: true,
                      },
                    },
                    {
                      name: 'Sensors',
                      options: {
                        sort: true,
                        filter: true,
                      },
                    },
                    {
                      name: 'Tenants',
                      options: {
                        sort: true,
                        filter: true,
                      },
                    },
                    {
                      name: 'Edit',
                      options: {
                        sort: false,
                        filter: false,
                        customBodyRender: value => (
                          <React.Fragment key={value}>
                            <IconButton
                              classes={{ root: classes.editButton }}
                              aria-label="Edit"
                              color="secondary"
                              onClick={() =>
                                history.push(`/apartments/${value}/edit`)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </React.Fragment>
                        ),
                      },
                    },
                  ]}
                  data={get(data, 'apartments', []).map(apartment => [
                    get(apartment, 'building.address', ''),
                    get(apartment, 'number', ''),
                    get(apartment, 'sensors.length', ''),
                    get(apartment, 'tenants.length', ''),
                    get(apartment, 'id', ''),
                  ])}
                  options={{
                    print: false,
                    download: false,
                    selectableRows: 'none',
                    elevation: 1,
                    rowsPerPageOptions: [10, 25, 50, 100],
                  }}
                />
              </div>
            )
          }
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Apartments));
