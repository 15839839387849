import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { get } from 'lodash';

import Error from '../common/Error';
import Loading from '../common/Loading';
import SensorGraph from '../common/SensorGraph';
import WaterGraph from '../common/WaterGraph';
import { BUILDINGS_SENSORS_QUERY } from '../../data/queries';
import layouts from '../../styles/layouts';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    sensorName: {
      margin: '24px 0px',
    },
  });

class Building extends React.Component<
  { classes: any } & RouteComponentProps<{ id: string }>
> {
  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Query
          query={BUILDINGS_SENSORS_QUERY}
          variables={{ id, dataLimit: 5000 }}
          pollInterval={60000}
        >
          {({ data, error, loading }) => (
            <React.Fragment>
              <Typography variant="h4" color="secondary" gutterBottom={true}>
                {loading ? '' : get(data, 'buildings[0].address', 'Building')}
              </Typography>
              <Divider className={classes.contentWrapperDivider} />
              {error ? (
                <Error />
              ) : loading ? (
                <Loading />
              ) : (
                <React.Fragment>
                  {data.buildings[0].sensors.length > 0 ||
                  data.buildings[0].water.length > 0 ? (
                    <div>
                      {data.buildings[0].sensors.length > 0 &&
                        data.buildings[0].sensors.map(sensor => (
                          <section key={sensor.devEUI}>
                            <Typography
                              variant="h5"
                              gutterBottom={true}
                              className={classes.sensorName}
                            >
                              Sensor location: {sensor.location}
                            </Typography>
                            <SensorGraph sensor={sensor} usage={true} />
                          </section>
                        ))}
                      {data.buildings[0].water.length > 0 && (
                        <section>
                          <Typography
                            variant="h5"
                            gutterBottom={true}
                            className={classes.sensorName}
                          >
                            Sensor location: Heat Distribution Room
                          </Typography>
                          <WaterGraph data={data.buildings[0].water} />
                        </section>
                      )}
                    </div>
                  ) : (
                    <Typography>Building has no sensors.</Typography>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Building));
