import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import Error from '../common/Error';
import Loading from '../common/Loading';
import layouts from '../../styles/layouts';
import variables from '../../styles/variables';
import DetailsTitle from '../common/DetailsTitle';
import { IApartment } from '../sensors/types';
import SensorsSection from './SensorsSection';
import TenantsSection from './TenantsSection';
import { Mutation } from 'react-apollo';
import { APARTMENTS_QUERY } from '../../data';
import {
  UPDATE_APARTMENT_MUTATION,
  DELETE_APARTMENT_MUTATION,
} from '../../data/mutations';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    paper: {
      padding: variables.paperPadding,
    },
    buildingButton: {
      textTransform: 'none',
      padding: '0px',
      margin: '10px 0px',
      fontSize: '1.125rem',
    },
    section: {
      margin: '30px 0px',
      padding: variables.paperPadding,
    },
  });

interface IProps extends RouteComponentProps {
  classes: any;
  apartment: IApartment;
  loading: boolean;
}

interface IState {
  aptNumber: string;
  editEnabled: boolean;
  numberValid: boolean;
  showConfirmation: boolean;
  targetId: string;
}

class ApartmentForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      aptNumber: this.props.apartment.number,
      editEnabled: false,
      numberValid: true,
      showConfirmation: false,
      targetId: '',
    };
  }
  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  handleCommitValues = commitValues => {
    const { id } = this.props.apartment;
    const { aptNumber } = this.state;
    const numberValid = aptNumber.length > 0;
    this.setState({
      numberValid,
    });
    if (numberValid) {
      commitValues({
        variables: { id, number: aptNumber },
      }).then(() => {
        this.setState({ editEnabled: false });
      });
    }
  };

  handleDeleteApartment = (deleteApartment, targetId) => {
    deleteApartment({ variables: { id: targetId } })
      .then(() => {
        this.setState({ showConfirmation: false });
      })
      .then(() => {
        this.props.history.push(`/apartments`);
      });
  };

  toggleEditMode = () => {
    const { editEnabled } = this.state;
    this.setState({ editEnabled: !editEnabled });
  };

  render() {
    const { classes, history, apartment } = this.props;
    const {
      aptNumber,
      editEnabled,
      numberValid,
      showConfirmation,
      targetId,
    } = this.state;
    const { id } = this.props.apartment;

    return (
      <React.Fragment>
        <Mutation
          mutation={UPDATE_APARTMENT_MUTATION}
          refetchQueries={[{ query: APARTMENTS_QUERY }]}
        >
          {(updateApartment, { loading }) => (
            <DetailsTitle
              title={apartment.number}
              loading={loading}
              editMode={!editEnabled}
              onEditClicked={() => this.toggleEditMode()}
              onDeleteClicked={() =>
                this.setState({ showConfirmation: true, targetId: id })
              }
              onSaveClicked={() => {
                this.handleCommitValues(updateApartment);
              }}
            />
          )}
        </Mutation>
        <Mutation
          mutation={DELETE_APARTMENT_MUTATION}
          refetchQueries={[{ query: APARTMENTS_QUERY }]}
        >
          {(deleteBuilding, { loading, error }) =>
            error ? (
              <Error />
            ) : loading ? (
              <Loading />
            ) : (
              <ConfirmationDialog
                open={showConfirmation}
                title={`Do you want to delete ${aptNumber}?`}
                targetName={aptNumber}
                targetId={targetId}
                subject={'building'}
                onCancel={() =>
                  this.setState({
                    showConfirmation: !showConfirmation,
                    targetId: '',
                  })
                }
                onCommit={() =>
                  this.handleDeleteApartment(deleteBuilding, targetId)
                }
              />
            )
          }
        </Mutation>
        <Divider className={classes.contentWrapperDivider} />
        <React.Fragment>
          <Paper elevation={1} square={true} classes={{ root: classes.paper }}>
            <Typography variant="h6" gutterBottom={true}>
              Apartment number
            </Typography>
            <TextField
              disabled={!editEnabled}
              error={!numberValid}
              label="Number"
              id="number"
              variant="outlined"
              value={aptNumber}
              onChange={this.handleChange('aptNumber')}
              margin="normal"
              classes={{ root: classes.field }}
              helperText={numberValid ? ' ' : 'Invalid number'}
            />
          </Paper>
          <Paper elevation={1} square={true} classes={{ root: classes.paper }}>
            <Typography variant="h6" gutterBottom={true}>
              Building
            </Typography>
            {apartment.building ? (
              <Button
                classes={{ root: classes.buildingButton }}
                color="secondary"
                onClick={() =>
                  history.push(`/buildings/${apartment.building.id}/edit`)
                }
              >
                <DomainIcon />
                &nbsp;
                {apartment.building.address}
              </Button>
            ) : (
              <Typography variant="body1" gutterBottom={true}>
                No building assigned.
              </Typography>
            )}
          </Paper>
          <Paper
            elevation={1}
            square={true}
            classes={{ root: classes.section }}
          >
            <SensorsSection apartment={apartment} editEnabled={editEnabled} />
          </Paper>
          <Paper
            elevation={1}
            square={true}
            classes={{ root: classes.section }}
          >
            <TenantsSection apartment={apartment} editEnabled={editEnabled} />
          </Paper>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ApartmentForm));
