import React from 'react';
import { ApolloProvider } from 'react-apollo';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ooth } from '../auth/ooth';
import { OothProvider } from '../auth/OothContext';
import { apolloClient } from '../data';
import Main from './Main';
import theme from '../styles/theme';

import { Workbox } from 'workbox-window';

class App extends React.Component {
  componentDidMount() {
    const wb = new Workbox('/service-worker.js');
    if ('serviceWorker' in window.navigator) {
      wb.addEventListener('waiting', () => {
        // console.log('WAITING');
        wb.addEventListener('controlling', () => {
          // console.log('RELOAD');
          window.location.reload();
        });
        wb.messageSW({ type: 'SKIP_WAITING' });
        // console.log('SKIP_WAITING');
      });
    }
  }

  render() {
    return (
      <OothProvider oothClient={ooth}>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <Main />
          </ApolloProvider>
        </MuiThemeProvider>
      </OothProvider>
    );
  }
}

export default App;
