import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';

import Temperature from './Temperature';
import Humidity from './Humidity';
import CO2 from './CO2';
import Electricity from './Electricity';
import { ISensor } from '../sensors/types';

interface ISensorGraphProps {
  sensor: ISensor;
  usage?: boolean;
  infopanel?: boolean;
  averages?: boolean;
  wideGraphs?: boolean;
}

const SensorGraph: React.SFC<ISensorGraphProps> = ({
  sensor,
  usage,
  infopanel,
  averages,
  wideGraphs,
}) => {
  // console.log('SensorGraph.render()');

  const graphSizes: any = {
    xs: 12,
    lg: wideGraphs ? 12 : 6,
    xl: wideGraphs ? 12 : 4,
  };

  // This function is meant to prevent unrealistically low or high values from messing up the graphs
  const unrealValueFilter = item => {
    // Power values over 1 megawatt are probably false
    if (item.power > 1e6) return false;

    return true;
  };

  const data: ReadonlyArray<any> =
    sensor
      .data!.slice()
      .reverse()
      .filter(unrealValueFilter) || [];
  // console.log('data', data);

  let hasTemperature = false;
  let hasHumidity = false;
  let hasCO2 = false;
  let hasPower = false;

  if (sensor.type) {
    switch (sensor.type) {
      case 'ERS':
      case 'ERS_LITE':
        hasTemperature = true;
        hasHumidity = true;
        break;
      case 'ERS_CO2':
        hasTemperature = true;
        hasHumidity = true;
        hasCO2 = true;
        break;
      case 'ELT_LITE':
        hasPower = true;
        break;
      default:
        break;
    }
  } else {
    hasTemperature = data.find(item => item.temperature != null);
    hasHumidity = data.find(item => item.humidity != null);
    hasCO2 = data.find(item => item.co2 != null);
    hasPower = data.find(item => item.power != null);
  }

  return (
    <>
      {data.length > 0 ? (
        <Grid container={true} spacing={2}>
          {hasTemperature && (
            <Grid item={true} {...graphSizes}>
              {get(sensor, 'data') && (
                <Temperature
                  data={data.map(obj => ({
                    time: new Date(obj.time),
                    temperature: obj.temperature,
                  }))}
                  infopanel={infopanel}
                  averages={averages}
                />
              )}
            </Grid>
          )}
          {hasHumidity && (
            <Grid item={true} {...graphSizes}>
              {get(sensor, 'data') && (
                <Humidity
                  data={data.map(obj => ({
                    time: new Date(obj.time),
                    humidity: obj.humidity,
                  }))}
                  infopanel={infopanel}
                />
              )}
            </Grid>
          )}
          {hasCO2 && (
            <Grid item={true} {...graphSizes}>
              {get(sensor, 'data') && (
                <CO2
                  data={data.map(obj => ({
                    time: new Date(obj.time),
                    co2: obj.co2,
                  }))}
                  infopanel={infopanel}
                />
              )}
            </Grid>
          )}
          {hasPower && (
            <Grid item={true} {...graphSizes}>
              {get(sensor, 'data') && (
                <Electricity
                  data={data.map(obj => ({
                    time: new Date(obj.time),
                    power: obj.power,
                  }))}
                  infopanel={infopanel}
                  usage={usage}
                />
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography variant="body1" gutterBottom={true}>
          No data received from the sensor.
        </Typography>
      )}
    </>
  );
};

export default SensorGraph;
