import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { get } from 'lodash';

import {
  CURRENT_USER_QUERY,
  USERS_QUERY,
  USERS_WITH_APARTMENTS_QUERY,
} from '../../data/queries';
import variables from '../../styles/variables';
import layouts from '../../styles/layouts';
import {
  UPDATE_USER_MUTATION,
  DELETE_USER_MUTATION,
} from '../../data/mutations';
import Loading from '../common/Loading';
import Error from '../common/Error';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import UserApartmentsSection from './UserApartmentsSection';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    firstItem: {
      [breakpoints.up('md')]: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    field: {
      maxWidth: variables.formWidth,
    },
    paper: {
      padding: variables.paperPadding,
    },
    addButton: {
      marginTop: '17px',
    },
    chipWrapper: {
      margin: '24px 0',
    },
    chip: {
      fontSize: '1rem',
    },
    radioGroup: {
      flexDirection: 'row',
    },
  });

interface IBuilding {
  address: string;
}

interface IApartment {
  id: string;
  number: string;
  building: IBuilding;
}

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userLevel: string;
  apartments: IApartment[];
}

interface IState {
  original: IUser;
  editMode: boolean;
  firstName: string;
  lastName: string;
  email: string;
  firstNameValid: boolean;
  lastNameValid: boolean;
  emailValid: boolean;
  userLevel: string;
  showApartmentSelector: boolean;
  apartments: IApartment[];
  confirmationDialog: boolean;
  targetUser: string;
  targetId: string;
}

interface IProps {
  classes: any;
  user: IUser;
}

class UserForm extends React.Component<IProps & RouteComponentProps, IState> {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      original: JSON.parse(JSON.stringify(user)),
      editMode: false,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      firstNameValid: true,
      lastNameValid: true,
      emailValid: true,
      userLevel: user.userLevel,
      showApartmentSelector: false,
      apartments: user.apartments || [],
      confirmationDialog: false,
      targetId: '',
      targetUser: '',
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  handleUpdateUser = updateUser => {
    const { id } = this.props.user;
    const { firstName, lastName, email, userLevel } = this.state;
    const firstNameValid = firstName.length > 0;
    const lastNameValid = lastName.length > 0;
    const emailValid = email.length > 3 && email.includes('@');
    this.setState(state => ({
      ...state,
      firstNameValid,
      lastNameValid,
      emailValid,
    }));
    if (firstNameValid && lastNameValid && emailValid) {
      updateUser({
        variables: { id, email, firstName, lastName, userLevel },
      }).then(() => {
        this.setState({ editMode: false });
      });
    }
  };

  handleCancel = () => {
    const { original } = this.state;
    this.setState(state => ({
      ...state,
      firstName: original.firstName,
      lastName: original.lastName,
      email: original.email,
      userLevel: original.userLevel,
      apartments: JSON.parse(JSON.stringify(original.apartments)),
    }));
    this.toggleEditMode();
  };

  toggleEditMode = () => {
    this.setState(state => ({ ...state, editMode: !state.editMode }));
  };

  toggleApartmentSelector = (visibility: boolean) => {
    this.setState(state => ({ ...state, showApartmentSelector: visibility }));
  };

  handleDeleteUser = (deleteUser, targetId) => {
    deleteUser({ variables: { id: targetId } })
      .then(() => {
        this.setState({ confirmationDialog: false });
      })
      .then(() => {
        this.props.history.push(`/users/`);
      });
  };

  render() {
    const { classes, user } = this.props;
    const {
      editMode,
      firstName,
      lastName,
      email,
      userLevel,
      firstNameValid,
      lastNameValid,
      emailValid,
      showApartmentSelector,
      confirmationDialog,
      targetId,
      targetUser,
    } = this.state;

    return (
      <Paper elevation={1} square={true} classes={{ root: classes.paper }}>
        <form className={classes.userForm} autoComplete="off">
          <Grid container={true} spacing={2}>
            <Grid
              item={true}
              xs={12}
              md={6}
              classes={{ item: classes.firstItem }}
            >
              <Grid container={true} direction="column">
                <Typography variant="h6" gutterBottom={true}>
                  User details
                </Typography>
                <TextField
                  disabled={!editMode}
                  error={!firstNameValid}
                  label="Firstname"
                  id="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={this.handleChange('firstName')}
                  margin="normal"
                  classes={{ root: classes.field }}
                  helperText={firstNameValid ? ' ' : 'Invalid firstname'}
                />
                <TextField
                  disabled={!editMode}
                  error={!lastNameValid}
                  label="Lastname"
                  id="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={this.handleChange('lastName')}
                  margin="normal"
                  classes={{ root: classes.field }}
                  helperText={lastNameValid ? ' ' : 'Invalid lastname'}
                />
                <TextField
                  disabled={!editMode}
                  error={!emailValid}
                  label="Email"
                  id="email"
                  value={email}
                  variant="outlined"
                  type="email"
                  onChange={this.handleChange('email')}
                  margin="normal"
                  classes={{ root: classes.field }}
                  helperText={emailValid ? ' ' : 'Invalid email'}
                />
                <Query query={CURRENT_USER_QUERY}>
                  {({ data }) =>
                    get(data, 'currentUser.userLevel', 0) === 2 &&
                    userLevel !== 'ADMIN' ? (
                      <React.Fragment>
                        <FormLabel>User level</FormLabel>
                        <Grid container={true}>
                          <RadioGroup
                            classes={{ root: classes.radioGroup }}
                            aria-label="User level"
                            name="userLevel"
                            className={classes.group}
                            value={userLevel}
                            onChange={this.handleChange('userLevel')}
                          >
                            <FormControlLabel
                              disabled={!editMode}
                              value="TENANT"
                              control={<Radio color="primary" />}
                              label="Tenant"
                            />
                            <FormControlLabel
                              disabled={!editMode}
                              value="STAFF"
                              control={<Radio color="primary" />}
                              label="Staff"
                            />
                          </RadioGroup>
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )
                  }
                </Query>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <UserApartmentsSection
                editMode={editMode}
                showApartmentSelector={showApartmentSelector}
                user={user}
                toggleApartmentSelector={this.toggleApartmentSelector}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              {!editMode && (
                <Grid container={true} direction="column">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.toggleEditMode}
                    classes={{ root: classes.field }}
                  >
                    Edit user
                  </Button>
                </Grid>
              )}
              {editMode && (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <Mutation
                      mutation={UPDATE_USER_MUTATION}
                      refetchQueries={[{ query: USERS_QUERY }]}
                    >
                      {(updateUser, { loading, error }) =>
                        error ? (
                          <Error />
                        ) : loading ? (
                          <Loading />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={() => this.handleUpdateUser(updateUser)}
                          >
                            Save
                          </Button>
                        )
                      }
                    </Mutation>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Button
                      onClick={this.handleCancel}
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Mutation
                      mutation={DELETE_USER_MUTATION}
                      refetchQueries={[{ query: USERS_WITH_APARTMENTS_QUERY }]}
                    >
                      {(deleteUser, { loading, error }) =>
                        error ? (
                          <Error />
                        ) : loading ? (
                          <Loading />
                        ) : (
                          <>
                            <Button
                              onClick={() =>
                                this.setState({
                                  confirmationDialog: true,
                                  targetUser: `${firstName} ${lastName}`,
                                  targetId: this.props.user.id,
                                })
                              }
                              variant="outlined"
                              color="secondary"
                              fullWidth={true}
                            >
                              Delete
                            </Button>
                            <ConfirmationDialog
                              open={confirmationDialog}
                              title={`Do you want to delete ${targetUser}?`}
                              targetName={targetUser}
                              targetId={targetId}
                              subject={'user'}
                              onCancel={() =>
                                this.setState({
                                  confirmationDialog: !confirmationDialog,
                                  targetId: '',
                                  targetUser: '',
                                })
                              }
                              onCommit={() =>
                                this.handleDeleteUser(deleteUser, targetId)
                              }
                            />
                          </>
                        )
                      }
                    </Mutation>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default withStyles(styles)(withRouter(UserForm));
