export const defaults = {
  desktopDrawerOpen: true,
  mobileDrawerOpen: false,
  authenticated: false,
  currentUser: {
    __typename: 'CurrentUser',
    firstName: null,
    lastName: null,
    email: null,
    userLevel: null,
    id: null,
  },
};
