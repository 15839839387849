import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Error from '../common/Error';
import Loading from '../common/Loading';
import { BUILDING_EDIT_QUERY } from '../../data/queries';
import layouts from '../../styles/layouts';
import variables from '../../styles/variables';
import BuildingForm from './BuildingForm';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    paper: {
      margin: '30px 0px',
      padding: variables.paperPadding,
    },
    chip: {
      fontSize: '1rem',
    },
    tenantButton: {
      textTransform: 'none',
      padding: '0px',
    },
    divider: {
      margin: '24px 0px',
    },
    field: {
      maxWidth: variables.formWidth,
    },
  });

interface IProps {
  classes: any;
}

type AllProps = IProps & RouteComponentProps<{ id: string }>;

class BuildingEdit extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.state = { editEnabled: false };
  }

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Query query={BUILDING_EDIT_QUERY} variables={{ id }}>
          {({ data, error, loading }) =>
            error ? (
              <Error message={error.message} />
            ) : loading ? (
              <Loading />
            ) : (
              <>
                <Typography variant="h4" color="secondary" gutterBottom={true}>
                  {data.buildings[0].address}
                </Typography>
                <BuildingForm building={data.buildings[0]} />
              </>
            )
          }
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(BuildingEdit));
