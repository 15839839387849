import gql from 'graphql-tag';
import { sensorInfoFragment } from './fragments';

export const AUTHENTICATED_QUERY = gql`
  query Authenticated {
    authenticated @client
  }
`;

export const DRAWER_QUERY = gql`
  query Drawer {
    desktopDrawerOpen @client
    mobileDrawerOpen @client
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser @client {
      firstName
      lastName
      email
      userLevel
      id
    }
  }
`;

export const USERS_QUERY = gql`
  query Users($id: ID) {
    users(id: $id) {
      id
      firstName
      lastName
      email
      userLevel
    }
  }
`;

export const USERS_WITH_APARTMENTS_QUERY = gql`
  query UsersWithApartments($id: ID) {
    users(id: $id) {
      id
      firstName
      lastName
      email
      userLevel
      apartments {
        id
        number
        building {
          id
          address
        }
      }
    }
  }
`;

export const APARTMENTS_TABLE_QUERY = gql`
  query Apartments($id: ID) {
    apartments(id: $id) {
      id
      number
      sensors {
        devEUI
      }
      building {
        id
        address
      }
      tenants {
        id
      }
    }
  }
`;

export const APARTMENTS_QUERY = gql`
  query Apartments($id: ID) {
    apartments(id: $id) {
      id
      number
      sensors {
        devEUI
        location
        notes
      }
      building {
        id
        address
      }
      tenants {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const APARTMENTS_SENSORS_QUERY = gql`
  query ApartmentsSensors(
    $id: ID
    $dataLimit: Int
    $dataFrom: String
    $dataTo: String
  ) {
    apartments(id: $id) {
      id
      number
      water(dataLimit: $dataLimit, dataFrom: $dataFrom, dataTo: $dataTo) {
        time
        hot
        cold
      }
      sensors {
        devEUI
        type
        location
        notes
        data(dataLimit: $dataLimit, dataFrom: $dataFrom, dataTo: $dataTo) {
          time
          temperature
          humidity
          co2
          power
        }
      }
    }
  }
`;

export const BUILDINGS_QUERY = gql`
  query Buildings($id: ID) {
    buildings(id: $id, orderBy: address_ASC) {
      id
      address
    }
  }
`;

export const BUILDINGS_TABLE_QUERY = gql`
  query BuildingsTable($id: ID) {
    buildings(id: $id) {
      id
      address
      apartments {
        id
      }
      sensors {
        devEUI
      }
    }
  }
`;

export const BUILDING_EDIT_QUERY = gql`
  query BuildingEdit($id: ID) {
    buildings(id: $id) {
      id
      address
      apartments {
        id
        number
        tenants {
          id
          email
          firstName
          lastName
        }
      }
      sensors {
        devEUI
      }
    }
  }
`;

export const BUILDINGS_SENSORS_QUERY = gql`
  query BuildingsSensors($id: ID, $dataLimit: Int) {
    buildings(id: $id) {
      id
      address
      sensors {
        devEUI
        location
        notes
        type
        data(dataLimit: $dataLimit) {
          time
          temperature
          humidity
          co2
          power
        }
      }
      water {
        time
        hot
        cold
      }
    }
  }
`;

export const BUILDINGS_APARTMENTS_QUERY = gql`
  query BuildingsApartments($id: ID) {
    buildings(id: $id) {
      id
      address
      apartments {
        id
        number
        building {
          id
          address
        }
      }
    }
    apartments(notInBuilding: true) {
      id
      number
    }
  }
`;

export const BUILDINGS_TEMPERATURE_QUERY = gql`
  query BuildingsTemperature($id: ID, $dataLimit: Int) {
    buildings(id: $id) {
      id
      address
      sensors {
        devEUI
        location
        notes
        data(dataLimit: $dataLimit) {
          time
          temperature
        }
      }
    }
  }
`;

export const BUILDINGS_HUMIDITY_QUERY = gql`
  query BuildingsHumidity($id: ID, $dataLimit: Int) {
    buildings(id: $id) {
      id
      address
      sensors {
        devEUI
        location
        notes
        data(dataLimit: $dataLimit) {
          time
          humidity
        }
      }
    }
  }
`;

export const BUILDINGS_CO2_QUERY = gql`
  query BuildingsCO2($id: ID, $dataLimit: Int) {
    buildings(id: $id) {
      id
      address
      sensors {
        devEUI
        location
        notes
        data(dataLimit: $dataLimit) {
          time
          co2
        }
      }
    }
  }
`;

export const BUILDINGS_ELECTRICITY_QUERY = gql`
  query BuildingsElectricity($id: ID, $dataLimit: Int) {
    buildings(id: $id) {
      id
      address
      sensors {
        devEUI
        location
        notes
        data(dataLimit: $dataLimit) {
          time
          power
        }
      }
    }
  }
`;

export const TEST_BUILDINGS_QUERY = gql`
  query TestBuildings($id: ID) {
    buildings(id: $id) {
      id
      address
      apartments {
        number
      }
    }
  }
`;

export const SENSORS_WITH_APARTMENT_QUERY = gql`
  query Sensors($devEUI: ID) {
    sensors(devEUI: $devEUI) {
      ...sensorInfo
      data(dataLimit: 100) {
        time
        temperature
        humidity
        co2
        power
        voltage
      }
    }
  }
  ${sensorInfoFragment}
`;

export const POWER_FOR_DATE_RANGE_QUERY = gql`
  query Sensors(
    $devEUI: ID
    $dataLimit: Int
    $dataFrom: String
    $dataTo: String
  ) {
    sensors(devEUI: $devEUI) {
      devEUI
      location
      notes
      properties
      data(dataLimit: $dataLimit, dataFrom: $dataFrom, dataTo: $dataTo) {
        time
        power
      }
    }
  }
`;
