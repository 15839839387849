import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';

import { AUTHENTICATED_QUERY } from '../data/queries';

interface IProps {
  component: any;
  exact?: boolean;
  path: string;
}

class PrivateRoute extends React.Component<IProps> {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Query query={AUTHENTICATED_QUERY}>
        {({ data }) => (
          <Route
            {...rest}
            render={props =>
              typeof data !== 'undefined' && !data.authenticated ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        )}
      </Query>
    );
  }
}

export default PrivateRoute;
