import React from 'react';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import { get } from 'lodash';

import layouts from '../../styles/layouts';
import Loading from '../common/Loading';
import Error from '../common/Error';
import HumiditySimple from './HumiditySimple';
import OverviewTabs from './OverviewTabs';
import { BUILDINGS_QUERY } from '../../data/queries';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
  });

class OverviewHumidity extends React.Component<{ classes: any }> {
  render() {
    // console.log('OverviewHumidity.render');
    const { classes } = this.props;
    const tab = 2;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Overview
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <OverviewTabs tab={tab} />
        <Query query={BUILDINGS_QUERY}>
          {({ data, error, loading }) =>
            loading ? (
              <Loading />
            ) : error ? (
              <Error />
            ) : (
              <Grid container={true} spacing={2}>
                {get(data, 'buildings', []).map(building => (
                  <Grid item={true} key={building.id} xs={12} md={6}>
                    <HumiditySimple building={building} />
                  </Grid>
                ))}
              </Grid>
            )
          }
        </Query>
      </section>
    );
  }
}

export default withStyles(styles)(OverviewHumidity);
