import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mutation, Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/Home';
import { get } from 'lodash';

import ApartmentSelector from '../common/ApartmentSelector';
import Loading from '../common/Loading';
import variables from '../../styles/variables';
import layouts from '../../styles/layouts';
import { CREATE_USER_MUTATION } from '../../data/mutations';
import { CURRENT_USER_QUERY } from '../../data/queries';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    firstItem: {
      [breakpoints.up('md')]: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    field: {
      maxWidth: variables.formWidth,
    },
    paper: {
      padding: variables.paperPadding,
    },
    addButton: {
      marginTop: '17px',
    },
    chipWrapper: {
      margin: '24px 0',
    },
    chip: {
      fontSize: 'rem',
    },
    radioGroup: {
      flexDirection: 'row',
    },
  });

interface IBuilding {
  address: string;
}

interface IApartment {
  id: string;
  number: string;
  building: IBuilding;
}

interface IState {
  firstName: string;
  lastName: string;
  email: string;
  userLevel: string;
  firstNameValid: boolean;
  lastNameValid: boolean;
  emailValid: boolean;
  showApartmentSelector: boolean;
  apartments: IApartment[];
}

interface IProps {
  classes: any;
}

class AddUser extends React.Component<IProps & RouteComponentProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      userLevel: 'TENANT',
      firstNameValid: true,
      lastNameValid: true,
      emailValid: true,
      showApartmentSelector: false,
      apartments: [],
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  handleAddUser = createUser => {
    const { firstName, lastName, email } = this.state;
    const firstNameValid = firstName.length > 0;
    const lastNameValid = lastName.length > 0;
    const emailValid = email.length > 3 && email.includes('@');
    this.setState(state => ({
      ...state,
      firstNameValid,
      lastNameValid,
      emailValid,
    }));
    if (firstNameValid && lastNameValid && emailValid) {
      createUser({ variables: { email, firstName, lastName } }).then(data => {
        this.props.history.push(
          `/users/${get(data, 'data.createUser.user.id')}`,
        );
      });
    }
  };

  toggleApartmentSelector = (visibility: boolean) => {
    this.setState(state => ({ ...state, showApartmentSelector: visibility }));
  };

  addApartment = (apartment: IApartment) => {
    const { apartments } = this.state;
    const index = apartments.findIndex(obj => obj.id === apartment.id);
    if (index !== -1) return; // Don't add same apartment twice
    apartments.push(apartment);
    this.setState(state => ({
      ...state,
      apartments,
      showApartmentSelector: false,
    }));
  };

  removeApartment = (apartment: IApartment) => {
    const { apartments } = this.state;
    const index = apartments.findIndex(obj => obj.id === apartment.id);
    if (index > -1) {
      apartments.splice(index, 1);
    }
    this.setState(state => ({ ...state, apartments }));
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      userLevel,
      firstNameValid,
      lastNameValid,
      emailValid,
      showApartmentSelector,
      apartments,
    } = this.state;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Add user
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Paper elevation={1} square={true} classes={{ root: classes.paper }}>
          <Mutation mutation={CREATE_USER_MUTATION}>
            {(createUser, { loading, error }) => (
              <form className={classes.userForm} autoComplete="off">
                <Grid container={true} spacing={2}>
                  <Grid
                    item={true}
                    xs={12}
                    md={6}
                    classes={{ item: classes.firstItem }}
                  >
                    <Grid container={true} direction="column">
                      <Typography variant="h6" gutterBottom={true}>
                        User details
                      </Typography>
                      <TextField
                        error={!firstNameValid}
                        label="Firstname"
                        id="firstName"
                        variant="outlined"
                        value={firstName}
                        onChange={this.handleChange('firstName')}
                        margin="normal"
                        classes={{ root: classes.field }}
                        helperText={firstNameValid ? ' ' : 'Invalid firstname'}
                      />
                      <TextField
                        error={!lastNameValid}
                        label="Lastname"
                        id="lastName"
                        variant="outlined"
                        value={lastName}
                        onChange={this.handleChange('lastName')}
                        margin="normal"
                        classes={{ root: classes.field }}
                        helperText={lastNameValid ? ' ' : 'Invalid lastname'}
                      />
                      <TextField
                        error={!emailValid || error ? true : false}
                        label="Email"
                        id="email"
                        value={email}
                        variant="outlined"
                        type="email"
                        onChange={this.handleChange('email')}
                        margin="normal"
                        classes={{ root: classes.field }}
                        helperText={
                          !emailValid
                            ? 'Invalid email'
                            : error && error.message.includes('email')
                            ? 'This email is already registered.'
                            : ' '
                        }
                      />
                      <Query query={CURRENT_USER_QUERY}>
                        {({ data }) =>
                          get(data, 'currentUser.userLevel', 0) === 2 ? (
                            <React.Fragment>
                              <FormLabel>User level</FormLabel>
                              <Grid container={true}>
                                <RadioGroup
                                  classes={{ root: classes.radioGroup }}
                                  aria-label="User level"
                                  name="userLevel"
                                  className={classes.group}
                                  value={userLevel}
                                  onChange={this.handleChange('userLevel')}
                                >
                                  <FormControlLabel
                                    value="TENANT"
                                    control={<Radio color="primary" />}
                                    label="Tenant"
                                  />
                                  <FormControlLabel
                                    value="STAFF"
                                    control={<Radio color="primary" />}
                                    label="Staff"
                                  />
                                </RadioGroup>
                              </Grid>
                            </React.Fragment>
                          ) : (
                            <React.Fragment />
                          )
                        }
                      </Query>
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <Grid
                      container={true}
                      direction="column"
                      classes={{ container: classes.field }}
                    >
                      <Typography variant="h6" gutterBottom={true}>
                        User's apartments
                      </Typography>
                      {showApartmentSelector && (
                        <ApartmentSelector
                          save={this.addApartment}
                          cancel={() => this.toggleApartmentSelector(false)}
                        />
                      )}
                      {!showApartmentSelector && (
                        <Button
                          variant="outlined"
                          classes={{ root: classes.addButton }}
                          onClick={() => this.toggleApartmentSelector(true)}
                        >
                          <AddIcon />
                          &nbsp; Add apartment
                        </Button>
                      )}
                    </Grid>
                    {apartments.map(apartment => (
                      <div className={classes.chipWrapper} key={apartment.id}>
                        <Chip
                          classes={{ root: classes.chip }}
                          key={apartment.id}
                          label={`${apartment.building.address} ${
                            apartment.number
                          }`}
                          variant="outlined"
                          icon={<HomeIcon />}
                          color="primary"
                          onDelete={() => this.removeApartment(apartment)}
                        />
                      </div>
                    ))}
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <Grid container={true} direction="column">
                      {loading ? <Loading /> : null}
                      <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleAddUser(createUser)}
                        classes={{ root: classes.field }}
                      >
                        Create user
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Mutation>
        </Paper>
      </section>
    );
  }
}

export default withStyles(styles)(withRouter(AddUser));
