import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import ReactEcharts from 'echarts-for-react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button, Paper, Typography } from '@material-ui/core';
import { get } from 'lodash';

import Loading from '../common/Loading';
import Error from '../common/Error';
import { BUILDINGS_TEMPERATURE_QUERY } from '../../data/queries';
import { option } from '../../charts/temperatureSimple';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    heading: {
      width: '100%',
      textTransform: 'none',
      borderRadius: '0px',
      justifyContent: 'initial',
      padding: '0px',
    },
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
  });

interface IBuilding {
  id: string;
  address: string;
}

interface IProps {
  classes: any;
  building: IBuilding;
}

class TemperatureSimple extends React.Component<IProps & RouteComponentProps> {
  render() {
    const { id, address } = this.props.building;
    const { classes, history } = this.props;

    return (
      <Query
        query={BUILDINGS_TEMPERATURE_QUERY}
        variables={{ id, dataLimit: 5000 }}
        pollInterval={60000}
      >
        {({ data, error, loading }) => (
          <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
            {error ? (
              <Error />
            ) : loading ? (
              <Loading />
            ) : (
              <React.Fragment>
                <Button
                  classes={{ root: classes.heading }}
                  onClick={() => history.push(`/buildings/${id}`)}
                >
                  <Typography
                    variant="h5"
                    color="secondary"
                    className={classes.building}
                  >
                    {address}
                  </Typography>
                </Button>
                {get(data, 'buildings[0].sensors', []).length > 0 ? (
                  data.buildings[0].sensors.map(sensor => {
                    const sensorData: ReadonlyArray<
                      any
                    > = sensor.data.slice().reverse();
                    const timeData = sensorData.map(obj => obj.time);
                    let temperatureData = sensorData
                      .map(obj => obj.temperature)
                      .filter(Number);

                    // For some reason, negative temperatures are reported by sensors
                    // as having ~6553,5 added to them, to be on the safe side that
                    // the absolute zero hasn't been readjusted, deduct this number
                    // from all temperatures over 6k
                    // If this ever gets fixed, this is adjustment is no longer needed
                    temperatureData = temperatureData.map((temp: number) =>
                      temp > 6000
                        ? Number.parseFloat((temp - 6553.5).toPrecision(2))
                        : temp,
                    );

                    if (temperatureData.length === 0) {
                      return <section key={sensor.devEUI}>-</section>;
                    }

                    return (
                      <section key={sensor.devEUI}>
                        <Typography variant="h6">{sensor.location}</Typography>
                        <ReactEcharts
                          option={option(timeData, temperatureData)}
                        />
                      </section>
                    );
                  })
                ) : (
                  <Typography variant="body1" gutterBottom={true}>
                    Building has no sensors.
                  </Typography>
                )}
              </React.Fragment>
            )}
          </Paper>
        )}
      </Query>
    );
  }
}

export default withRouter(withStyles(styles)(TemperatureSimple));
