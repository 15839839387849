import gql from 'graphql-tag';
import { sensorInfoFragment } from './fragments';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: String!, $firstName: String, $lastName: String) {
    createUser(
      input: { email: $email, firstName: $firstName, lastName: $lastName }
    ) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $userLevel: UserLevel
  ) {
    updateUser(
      input: {
        userId: $id
        email: $email
        firstName: $firstName
        lastName: $lastName
        userLevel: $userLevel
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(input: { userId: $id }) {
      user {
        id
      }
    }
  }
`;

export const CREATE_APARTMENT_MUTATION = gql`
  mutation CreateApartment($number: String!) {
    createApartment(input: { number: $number }) {
      apartment {
        id
        number
      }
    }
  }
`;

export const CREATE_SENSOR_MUTATION = gql`
  mutation CreateSensor(
    $devEUI: ID!
    $type: SensorType
    $location: String
    $notes: String
  ) {
    createSensor(
      input: {
        devEUI: $devEUI
        type: $type
        location: $location
        notes: $notes
      }
    ) {
      sensor {
        devEUI
      }
    }
  }
`;

export const UPDATE_SENSOR_MUTATION = gql`
  mutation UpdateSensor(
    $devEUI: ID!
    $type: SensorType
    $location: String
    $notes: String
  ) {
    updateSensor(
      input: {
        devEUI: $devEUI
        type: $type
        location: $location
        notes: $notes
      }
    ) {
      sensor {
        ...sensorInfo
      }
    }
  }
  ${sensorInfoFragment}
`;

export const DELETE_SENSOR_MUTATION = gql`
  mutation DeleteSensor($devEUI: ID!) {
    deleteSensor(input: { devEUI: $devEUI }) {
      sensor {
        devEUI
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateProfile(
    $email: String
    $firstName: String
    $lastName: String
    $currentPassword: String!
  ) {
    updateProfile(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        currentPassword: $currentPassword
      }
    ) {
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UpdateProfile($currentPassword: String!, $newPassword: String!) {
    updateProfile(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      user {
        id
      }
    }
  }
`;

export const CREATE_BUILDING_MUTATION = gql`
  mutation CreateBuilding($address: String) {
    createBuilding(input: { address: $address }) {
      building {
        id
        address
      }
    }
  }
`;

export const UPDATE_BUILDING_MUTATION = gql`
  mutation UpdateBuilding($id: ID!, $address: String) {
    updateBuilding(input: { id: $id, address: $address }) {
      building {
        id
        address
      }
    }
  }
`;

export const DELETE_BUILDING_MUTATION = gql`
  mutation DeleteBuilding($id: ID!) {
    deleteBuilding(input: { id: $id }) {
      building {
        id
      }
    }
  }
`;

export const ADD_SENSOR_TO_BUILDING_MUTATION = gql`
  mutation AddSensorToBuilding($buildingId: ID!, $devEUI: ID!) {
    addSensorToBuilding(input: { buildingId: $buildingId, devEUI: $devEUI }) {
      building {
        id
      }
    }
  }
`;

export const REMOVE_SENSOR_FROM_BUILDING_MUTATION = gql`
  mutation RemoveSensorFromBuilding($buildingId: ID!, $devEUI: ID!) {
    removeSensorFromBuilding(
      input: { buildingId: $buildingId, devEUI: $devEUI }
    ) {
      building {
        id
      }
    }
  }
`;

export const ADD_APARTMENT_TO_BUILDING_MUTATION = gql`
  mutation AddApartmentToBuilding($buildingId: ID!, $apartmentId: ID!) {
    addApartmentToBuilding(
      input: { buildingId: $buildingId, apartmentId: $apartmentId }
    ) {
      building {
        id
      }
    }
  }
`;

export const REMOVE_APARTMENT_FROM_BUILDING_MUTATION = gql`
  mutation RemoveApartmentFromBuilding($buildingId: ID!, $apartmentId: ID!) {
    removeApartmentFromBuilding(
      input: { buildingId: $buildingId, apartmentId: $apartmentId }
    ) {
      building {
        id
      }
    }
  }
`;

export const UPDATE_APARTMENT_MUTATION = gql`
  mutation UpdateApartment($id: ID!, $number: String) {
    updateApartment(input: { id: $id, number: $number }) {
      apartment {
        id
        number
      }
    }
  }
`;

export const DELETE_APARTMENT_MUTATION = gql`
  mutation DeleteApartment($id: ID!) {
    deleteApartment(input: { id: $id }) {
      apartment {
        id
      }
    }
  }
`;

export const ADD_SENSOR_TO_APARTMENT_MUTATION = gql`
  mutation AddSensorToApartment($apartmentId: ID!, $devEUI: ID!) {
    addSensorToApartment(
      input: { apartmentId: $apartmentId, devEUI: $devEUI }
    ) {
      apartment {
        id
      }
    }
  }
`;

export const REMOVE_SENSOR_FROM_APARTMENT_MUTATION = gql`
  mutation RemoveSensorFromApartment($apartmentId: ID!, $devEUI: ID!) {
    removeSensorFromApartment(
      input: { apartmentId: $apartmentId, devEUI: $devEUI }
    ) {
      apartment {
        id
      }
    }
  }
`;

export const ADD_TENANT_TO_APARTMENT_MUTATION = gql`
  mutation AddTenantToApartment($apartmentId: ID!, $tenantId: ID!) {
    addTenantToApartment(
      input: { apartmentId: $apartmentId, tenantId: $tenantId }
    ) {
      apartment {
        id
      }
    }
  }
`;

export const REMOVE_TENANT_FROM_APARTMENT_MUTATION = gql`
  mutation RemoveTenantFromApartment($apartmentId: ID!, $tenantId: ID!) {
    removeTenantFromApartment(
      input: { apartmentId: $apartmentId, tenantId: $tenantId }
    ) {
      apartment {
        id
      }
    }
  }
`;

export const SEND_FEEDBACK_EMAIL_MUTATION = gql`
  mutation SendFeedbackEmail($message: String!) {
    sendFeedbackEmail(input: { message: $message }) {
      success
    }
  }
`;
