import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Button,
  TextField,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import layouts from '../../styles/layouts';
import { CREATE_BUILDING_MUTATION } from '../../data/mutations';
import variables from '../../styles/variables';
import Loading from '../common/Loading';
import { get } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    field: {
      maxWidth: variables.formWidth,
    },
  });

interface IProps {
  classes: any;
}

interface IAddBuildingState {
  buildingAddress: string;
  buildingAddressValid: boolean;
}

type AllProps = IProps & RouteComponentProps;

class AddBuilding extends React.Component<AllProps, IAddBuildingState> {
  constructor(props: AllProps) {
    super(props);
    this.state = { buildingAddress: '', buildingAddressValid: true };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  public render() {
    const { classes } = this.props;
    const { buildingAddress, buildingAddressValid } = this.state;

    return (
      <Mutation mutation={CREATE_BUILDING_MUTATION}>
        {(createBuilding, { loading, error }) => (
          <section className={classes.contentWrapper}>
            <Typography variant="h4" color="secondary">
              Add building
            </Typography>
            <form
              autoComplete="off"
              onSubmit={async e => {
                e.preventDefault();
                const { history } = this.props;
                this.setState({
                  buildingAddressValid: buildingAddress.length > 0,
                });
                if (buildingAddress.length > 0) {
                  const result = await createBuilding({
                    variables: { address: buildingAddress },
                  });
                  const { id } = get(
                    result,
                    'data.createBuilding.building',
                    '',
                  );
                  history.push(`/buildings/${id}/edit`);
                }
              }}
            >
              <TextField
                id="buildingAddress"
                label="Building address"
                value={buildingAddress}
                onChange={this.handleChange('buildingAddress')}
                margin="normal"
                variant="outlined"
                classes={{ root: classes.field }}
                error={!buildingAddressValid}
                helperText={buildingAddressValid ? ' ' : 'Invalid address'}
                disabled={loading}
              />
              <div>
                {loading && <Loading />}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  classes={{ root: classes.field }}
                >
                  Create
                </Button>
              </div>
              {error && (
                <Typography display="inline" color="error">
                  {error.message}
                </Typography>
              )}
            </form>
          </section>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(withRouter(AddBuilding));
