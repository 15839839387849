import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Chip,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentSelector from '../common/ApartmentSelector';
import layouts from '../../styles/layouts';
import variables from '../../styles/variables';
import { USERS_WITH_APARTMENTS_QUERY } from '../../data';
import { Mutation } from 'react-apollo';
import {
  REMOVE_TENANT_FROM_APARTMENT_MUTATION,
  ADD_TENANT_TO_APARTMENT_MUTATION,
} from '../../data/mutations';
import Loading from '../common/Loading';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    field: {
      maxWidth: variables.formWidth,
    },
    addButton: {
      marginTop: '17px',
    },
    chipWrapper: {
      margin: '24px 0',
    },
    chip: {
      fontSize: '1rem',
    },
  });

interface IBuilding {
  address: string;
}

interface IApartment {
  id: string;
  number: string;
  building: IBuilding;
}

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userLevel: string;
  apartments: IApartment[];
}

interface IProps extends WithStyles<typeof styles> {
  editMode: boolean;
  showApartmentSelector: boolean;
  user: IUser;
  toggleApartmentSelector: (state: boolean) => void;
}

type AllProps = IProps & RouteComponentProps;

class UserApartmentsSection extends React.Component<AllProps> {
  render() {
    const {
      classes,
      history,
      editMode,
      showApartmentSelector,
      user,
      toggleApartmentSelector,
    } = this.props;
    const { apartments } = user;
    return (
      <>
        <Grid
          container={true}
          direction="column"
          classes={{ container: classes.field }}
        >
          <Typography variant="h6" gutterBottom={true}>
            User's apartments
          </Typography>
          {showApartmentSelector && (
            <Mutation
              mutation={ADD_TENANT_TO_APARTMENT_MUTATION}
              refetchQueries={[{ query: USERS_WITH_APARTMENTS_QUERY }]}
            >
              {(addTenantToApartment, { loading }) => (
                <>
                  <ApartmentSelector
                    disabled={loading}
                    save={(apartment: IApartment) =>
                      addTenantToApartment({
                        variables: {
                          tenantId: this.props.user.id,
                          apartmentId: apartment.id,
                        },
                      })
                    }
                    cancel={() => toggleApartmentSelector(false)}
                  />
                  {loading && <Loading />}
                </>
              )}
            </Mutation>
          )}
          {editMode && !showApartmentSelector && (
            <Button
              variant="outlined"
              classes={{ root: classes.addButton }}
              onClick={() => toggleApartmentSelector(true)}
            >
              <AddIcon />
              &nbsp; Add apartment
            </Button>
          )}
        </Grid>
        {apartments.length > 0 &&
          apartments.map(apartment => (
            <div className={classes.chipWrapper} key={apartment.id}>
              {!editMode && (
                <Chip
                  classes={{ root: classes.chip }}
                  key={apartment.id}
                  label={`${
                    apartment.building ? apartment.building.address : ''
                  } ${apartment.number}`}
                  variant="outlined"
                  color="secondary"
                  icon={<HomeIcon />}
                  onClick={() =>
                    history.push(`/apartments/${apartment.id}/edit`)
                  }
                />
              )}
              {editMode && (
                <Mutation
                  mutation={REMOVE_TENANT_FROM_APARTMENT_MUTATION}
                  refetchQueries={[{ query: USERS_WITH_APARTMENTS_QUERY }]}
                >
                  {(removeTenantFromApartment, { loading }) => (
                    <>
                      <Chip
                        classes={{ root: classes.chip }}
                        key={apartment.id}
                        label={`${
                          apartment.building ? apartment.building.address : ''
                        } ${apartment.number}`}
                        variant="outlined"
                        icon={<HomeIcon />}
                        color="primary"
                        clickable={!loading}
                        onDelete={() =>
                          removeTenantFromApartment({
                            variables: {
                              tenantId: this.props.user.id,
                              apartmentId: apartment.id,
                            },
                          })
                        }
                      />
                      {loading && <Loading />}
                    </>
                  )}
                </Mutation>
              )}
            </div>
          ))}
      </>
    );
  }
}

export default withStyles(styles)(withRouter(UserApartmentsSection));
