import React from 'react';
import {
  Typography,
  createStyles,
  withStyles,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';

const styles = () =>
  createStyles({
    menuText: {},
  });

interface IProps {
  classes: any;
  title: string;
  loading: boolean;
  editMode: boolean;
  onEditClicked: any;
  onDeleteClicked: any;
  onSaveClicked: any;
}

interface IAddApartmentState {
  anchorEl: any;
}

class DetailsTitle extends React.Component<IProps, IAddApartmentState> {
  constructor(props: IProps) {
    super(props);
    this.state = { anchorEl: null };
  }

  openMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  onEditClicked = () => {
    this.setState({ anchorEl: null });
    this.props.onEditClicked();
  };

  onDeleteClicked = () => {
    this.setState({ anchorEl: null });
    this.props.onDeleteClicked();
  };

  onSaveClicked = () => {
    this.setState({ anchorEl: null });
    this.props.onSaveClicked();
  };

  public render() {
    const { classes, title, loading, editMode } = this.props;
    const { anchorEl } = this.state;
    const menuOpen = Boolean(anchorEl);

    return (
      <Grid
        container={true}
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item={true}>
          <Typography variant="h4" color="secondary" gutterBottom={true}>
            {loading ? '' : title}
          </Typography>
        </Grid>
        <Grid item={true}>
          {editMode ? (
            <>
              <IconButton
                aria-label="More"
                aria-owns={menuOpen ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.openMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={this.closeMenu}
              >
                <MenuItem onClick={this.onEditClicked}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  &nbsp; Edit
                </MenuItem>
                <MenuItem onClick={this.onDeleteClicked}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  &nbsp; Delete
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={this.onSaveClicked}
            >
              Save
              <DoneIcon className={classes.rightIcon} />
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DetailsTitle);
