export const NODE_ENV = process.env.NODE_ENV;
export let API_HOST =
  NODE_ENV !== 'development'
    ? process.env.REACT_APP_API_HOST
    : 'http://localhost';
export let API_PORT = NODE_ENV !== 'development' ? null : 4000;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

if (NODE_ENV !== 'development') {
  API_HOST = process.env.REACT_APP_API_HOST;
  API_PORT = null;
}

export const WEBAPP_VERSION = process.env.REACT_APP_VERSION || 'dev';

/*
console.log(`
Environmental variables ready
NODE_ENV: ${NODE_ENV}
API_HOST: ${API_HOST}
API_PORT: ${API_PORT}
WEBAPP_VERSION: ${WEBAPP_VERSION}
`);
*/
