import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { IApartment } from '../sensors/types';
import { Mutation } from 'react-apollo';
import {
  ADD_TENANT_TO_APARTMENT_MUTATION,
  REMOVE_TENANT_FROM_APARTMENT_MUTATION,
} from '../../data/mutations';
import { APARTMENTS_QUERY } from '../../data';
import Loading from '../common/Loading';
import TenantChip from './TenantChip';
import TenantSelector from './TenantSelector';
import AddIcon from '@material-ui/icons/Add';

const styles = () =>
  createStyles({
    addButton: {
      marginTop: '17px',
    },
    noContent: {
      padding: '12px',
    },
  });

interface IProps extends RouteComponentProps {
  classes: any;
  apartment: IApartment;
  editEnabled: boolean;
}

interface IState {
  showSelector: boolean;
}

class TenantsSection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { showSelector: false };
  }

  toggleSelector = (show: boolean) => this.setState({ showSelector: show });

  render() {
    const { classes, editEnabled } = this.props;
    const { id, tenants } = this.props.apartment;
    const { showSelector } = this.state;

    return (
      <>
        <Typography variant="h6" gutterBottom={true}>
          Tenants
        </Typography>
        <Mutation
          mutation={REMOVE_TENANT_FROM_APARTMENT_MUTATION}
          refetchQueries={[{ query: APARTMENTS_QUERY }]}
        >
          {(removeTenantFromApartment, { loading }) => (
            <Grid container={true} spacing={2}>
              {tenants.map(tenant => (
                <TenantChip
                  tenant={tenant}
                  deletable={editEnabled && !loading}
                  onDelete={tenantId =>
                    removeTenantFromApartment({
                      variables: { tenantId, apartmentId: id },
                    })
                  }
                  key={tenant.id}
                />
              ))}
              {editEnabled && loading && <Loading />}
              {tenants.length === 0 && (
                <Typography
                  variant="body1"
                  gutterBottom={true}
                  classes={{ root: classes.noContent }}
                >
                  No tenants assigned.
                </Typography>
              )}
            </Grid>
          )}
        </Mutation>
        <Mutation
          mutation={ADD_TENANT_TO_APARTMENT_MUTATION}
          refetchQueries={[{ query: APARTMENTS_QUERY }]}
        >
          {(addTenantToApartment, { loading }) => (
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={6}>
                {showSelector && !loading && (
                  <TenantSelector
                    save={tenantId =>
                      addTenantToApartment({
                        variables: { tenantId, apartmentId: id },
                      })
                    }
                    cancel={() => this.toggleSelector(false)}
                  />
                )}
                {editEnabled && !showSelector && !loading && (
                  <>
                    <Button
                      variant="outlined"
                      classes={{ root: classes.addButton }}
                      onClick={() => this.toggleSelector(true)}
                    >
                      <AddIcon />
                      &nbsp; Add tenant
                    </Button>
                  </>
                )}
                {editEnabled && loading && <Loading />}
              </Grid>
            </Grid>
          )}
        </Mutation>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(TenantsSection));
