import React from 'react';
import { Query } from 'react-apollo';

import PublicSection from './PublicSection';
import PrivateSection from './PrivateSection';
import { AUTHENTICATED_QUERY } from '../../data';

class DrawerContent extends React.Component {
  render() {
    return (
      <div>
        <Query query={AUTHENTICATED_QUERY}>
          {({ data: { authenticated } }) =>
            authenticated ? <PrivateSection /> : <PublicSection />
          }
        </Query>
      </div>
    );
  }
}

export default DrawerContent;
