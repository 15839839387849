import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography, WithStyles } from '@material-ui/core';
import layouts from '../../styles/layouts';
import { Query } from 'react-apollo';
import { APARTMENTS_SENSORS_QUERY } from '../../data';
import Error from '../common/Error';
import Loading from '../common/Loading';
import { get } from 'lodash';
import SensorGraph from '../common/SensorGraph';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ISensor } from '../sensors/types';
import { isConsumptionSensor } from './helpers';
import Water from '../common/Water';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    sensorName: {
      margin: '24px 0px',
    },
  });

interface IProps
  extends RouteComponentProps<{ id: string }>,
    WithStyles<typeof styles> {}

class Consumption extends React.Component<IProps> {
  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Consumption
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Query
          query={APARTMENTS_SENSORS_QUERY}
          variables={{
            id,
            dataLimit: 10000,
            dataFrom: 'now-1M/d',
            dataTo: 'now/d',
          }}
          pollInterval={60000}
        >
          {({ data, error, loading }) => (
            <React.Fragment>
              {error ? (
                <Error />
              ) : loading ? (
                <Loading />
              ) : (
                <React.Fragment>
                  {get(data, 'apartments[0].sensors', []).length > 0 &&
                    data.apartments[0].sensors.map((sensor: ISensor) =>
                      sensor && sensor.data && isConsumptionSensor(sensor) ? (
                        <section key={sensor.devEUI}>
                          <Typography
                            variant="h5"
                            gutterBottom={true}
                            className={classes.sensorName}
                          >
                            Sensor location: {sensor.location || 'Unknown'}
                          </Typography>
                          <SensorGraph
                            sensor={sensor}
                            infopanel={false}
                            usage={true}
                            wideGraphs={true}
                          />
                        </section>
                      ) : null,
                    )}
                  {get(data, 'apartments[0].water', []).length > 0 && (
                    <section>
                      <Typography
                        variant="h5"
                        gutterBottom={true}
                        className={classes.sensorName}
                      >
                        Sensor location: Heat Distrbution Room
                      </Typography>
                      <Water
                        data={data.apartments[0].water.map(obj => ({
                          time: new Date(obj.time),
                          hot: obj.hot,
                          cold: obj.cold,
                        }))}
                        infopanel={false}
                        usage={true}
                      />
                    </section>
                  )}
                  {!data.apartments ||
                    ((data.apartments[0].sensors.length === 0 ||
                      !data.apartments[0].sensors.find(sensor =>
                        isConsumptionSensor(sensor),
                      )) &&
                      data.apartments[0].water.length === 0 && (
                        <Typography variant="body1">
                          Apartment has no sensors.
                        </Typography>
                      ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Consumption));
