import React from 'react';
import { Query, withApollo } from 'react-apollo';
import classNames from 'classnames';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

import { withOoth } from '../auth/OothContext';
import { mapUser } from '../utils/currentUser';
import {
  AUTHENTICATED_QUERY,
  DRAWER_QUERY,
  CURRENT_USER_QUERY,
} from '../data/queries';
import Loading from './common/Loading';
import Navbar from './navigation/Navbar';
import DrawerMenu from './navigation/DrawerMenu';
import Routes from '../routes/Routes';
import variables from '../styles/variables';
import Area21Footer from './common/Area21Footer';

const styles = ({ breakpoints, transitions }: Theme) =>
  createStyles({
    loading: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    main: {
      paddingTop: variables.appBarHeight,
      transition: transitions.create('margin', {
        easing: transitions.easing.easeIn,
        duration: transitions.duration.leavingScreen,
      }),
    },
    mainShift: {
      [breakpoints.up('sm')]: {
        marginLeft: variables.drawerWidth,
        transition: transitions.create('margin', {
          easing: transitions.easing.easeOut,
          duration: transitions.duration.enteringScreen,
        }),
      },
    },
  });

interface IProps {
  classes: any;
  client: any;
  oothClient: any;
}

class Main extends React.Component<IProps> {
  async componentDidMount() {
    const { client, oothClient } = this.props;
    try {
      client.writeData({ data: { authenticated: null } });
      const user = await oothClient.start();
      if (user) {
        const currentUser = mapUser(user);
        const query = CURRENT_USER_QUERY;
        client.writeQuery({ query, data: { currentUser } });
        client.writeData({ data: { authenticated: true } });
      } else {
        client.writeData({ data: { authenticated: false } });
      }
    } catch (e) {
      // console.log(e);
      client.writeData({ data: { authenticated: false } });
    }
  }

  render() {
    const { classes } = this.props;

    const mainClasses = desktopDrawerOpen =>
      classNames({
        [classes.main]: true,
        [classes.mainShift]: desktopDrawerOpen,
      });

    return (
      <Query query={AUTHENTICATED_QUERY}>
        {({ data }) => (
          <React.Fragment>
            {(typeof data === 'undefined' || data.authenticated === null) && (
              // Loading authentication
              <div className={classes.loading}>
                <Loading />
              </div>
            )}
            {typeof data !== 'undefined' && data.authenticated !== null && (
              // Authentication determined
              <React.Fragment>
                <Navbar />
                <DrawerMenu />
                <Query query={DRAWER_QUERY}>
                  {({ data: { desktopDrawerOpen } }) => (
                    <main className={mainClasses(desktopDrawerOpen)}>
                      <Routes />
                    </main>
                  )}
                </Query>
                <Area21Footer />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Query>
    );
  }
}

export default withStyles(styles)(withApollo(withOoth(Main)));
