import { green, amber, grey, red } from '@material-ui/core/colors';
import { format } from 'date-fns';
import theme from '../styles/theme';
import _ from 'lodash';

interface IPower {
  readonly time: Date;
  readonly power: number;
}

export const electricityOption = (data: ReadonlyArray<IPower>) => ({
  dataset: {
    dimensions: ['time', 'power'],
    source: data,
  },
  textStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  tooltip: {
    trigger: 'axis',
    formatter: params =>
      `
      ${format(new Date(params[0].value.time), 'd.M.yyyy')}
      <br />
      ${format(new Date(params[0].value.time), 'HH:mm:ss')}
      <br />
      ${params[0].marker} ${_.round(params[0].value.power, 0)} W
    `,
  },
  xAxis: {
    type: 'time',
    axisLabel: {
      formatter: value => format(new Date(value), 'd.M. HH:mm'),
      margin: 14,
    },
    boundaryGap: false,
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dotted',
      },
    },
    name: 'W',
    min: 0,
    max: _.ceil(Math.max(..._.map(data, 'power')), -3),
  },
  grid: {
    top: 33,
    left: 42,
    right: 85,
    bottom: 70,
  },
  series: [
    {
      type: 'line',
      symbol: 'rect',
      silent: true,
      sampling: 'avg',
      name: 'Power',
    },
  ],
  dataZoom: [
    {
      type: 'inside',
      start: 95,
    },
    {
      type: 'slider',
      start: 95,
      labelFormatter: value => format(new Date(value), 'd.M. HH:mm'),
    },
  ],
  visualMap: {
    textStyle: {
      fontSize: 12,
    },
    top: 'top',
    right: 0,
    pieces: [
      {
        lt: 5000,
        color: green[500],
        symbol: 'rect',
      },
      {
        gte: 5000,
        lte: 10000,
        color: amber[500],
        symbol: 'rect',
      },
      {
        gt: 10000,
        color: red[500],
        symbol: 'rect',
      },
    ],
    outOfRange: {
      color: grey[500],
      symbol: 'rect',
    },
  },
});
