import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DomainIcon from '@material-ui/icons/Domain';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get } from 'lodash';

import { BUILDINGS_QUERY } from '../../data/queries';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    collapsable: {
      paddingLeft: variables.subMenuItemPadding,
    },
    building: {
      overflowWrap: 'break-word',
    },
  });

interface IProps {
  initiallyOpen: boolean;
  classes: any;
}

interface IState {
  buildingsOpen: boolean;
  client: any;
}

class DrawerContent extends React.Component<
  IProps & RouteComponentProps,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      buildingsOpen: props.initiallyOpen,
      client: null,
    };
  }

  handleBuildingsClick = () => {
    this.setState(state => ({
      ...state,
      buildingsOpen: !state.buildingsOpen,
    }));
  };

  render() {
    // console.log('BuildingsSection.render');
    const { classes, history } = this.props;
    const { buildingsOpen } = this.state;

    return (
      <React.Fragment>
        <ListItem button={true} onClick={this.handleBuildingsClick}>
          <ListItemIcon>
            <LocationCityIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Buildings" />
          {buildingsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Query query={BUILDINGS_QUERY}>
          {({ data }) => (
            <Collapse in={buildingsOpen} timeout="auto" unmountOnExit={true}>
              <List disablePadding={true} className="drawer-menu__sublist">
                <ListItem
                  button={true}
                  classes={{ root: classes.collapsable }}
                  onClick={() => history.push('/buildings')}
                >
                  <ListItemIcon>
                    <ViewComfyIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                </ListItem>
                {get(data, 'buildings', []).map(building => (
                  <ListItem
                    key={building.id}
                    button={true}
                    classes={{ root: classes.collapsable }}
                    onClick={() => history.push(`/buildings/${building.id}`)}
                  >
                    <ListItemIcon>
                      <DomainIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.building }}
                      primary={building.address}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </Query>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(DrawerContent));
