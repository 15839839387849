import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Chip, Grid, Typography } from '@material-ui/core';
import WifiIcon from '@material-ui/icons/Wifi';
import { ISensor } from '../sensors/types';

const styles = () =>
  createStyles({
    chip: {
      fontSize: '1rem',
    },
  });

interface IProps extends RouteComponentProps {
  classes: any;
  sensor: ISensor;
  deletable: boolean;
  onDelete: (devEUI: string) => void;
}

class SensorChip extends React.Component<IProps> {
  render() {
    const { classes, history, deletable, onDelete } = this.props;
    const { devEUI, location, notes } = this.props.sensor;

    return (
      <Grid item={true} key={devEUI} xs={12} md={6}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            {deletable ? (
              <Chip
                classes={{ root: classes.chip }}
                label={devEUI}
                variant="outlined"
                color="secondary"
                icon={<WifiIcon />}
                onClick={() => history.push(`/sensors/${devEUI}`)}
                onDelete={() => onDelete(devEUI)}
              />
            ) : (
              <Chip
                classes={{ root: classes.chip }}
                label={devEUI}
                variant="outlined"
                color="secondary"
                icon={<WifiIcon />}
                onClick={() => history.push(`/sensors/${devEUI}`)}
              />
            )}
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Typography variant="body1">Location</Typography>
            <Typography>{location}</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Typography variant="body1">Notes</Typography>
            <Typography>{notes}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(SensorChip));
