import React from 'react';
import { Route, Switch } from 'react-router-dom';

import About from '../components/common/About';
import AdminRoute from './AdminRoute';
import Dashboard from '../components/apartments/Dashboard';
import Conditions from '../components/apartments/Conditions';
import Consumption from '../components/apartments/Consumption';
import Apartments from '../components/apartments/Apartments';
import AddApartment from '../components/apartments/AddApartment';
import ApartmentEdit from '../components/apartments/ApartmentEdit';
import AddBuilding from '../components/buildings/AddBuilding';
import Buildings from '../components/buildings/Buildings';
import Building from '../components/buildings/Building';
import BuildingEdit from '../components/buildings/BuildingEdit';
import Debug from '../components/admin/Debug';
import HomeRoute from './HomeRoute';
import Login from '../components/Login';
import LoginRoute from './LoginRoute';
import NotFound from '../components/common/NotFound';
import Overview from '../components/buildings/Overview';
import OverviewElectricity from '../components/buildings/OverviewElectricity';
import OverviewTemperature from '../components/buildings/OverviewTemperature';
import OverviewHumidity from '../components/buildings/OverviewHumidity';
import OverviewCO2 from '../components/buildings/OverviewCO2';
import PrivateRoute from './PrivateRoute';
import Settings from '../components/common/Settings';
import StaffRoute from './StaffRoute';
import SystemLog from '../components/admin/SystemLog';
import Test from '../components/Test';
import Users from '../components/users/Users';
import User from '../components/users/User';
import AddUser from '../components/users/AddUser';
import Sensors from '../components/sensors/Sensors';
import Sensor from '../components/sensors/Sensor';
import AddSensor from '../components/sensors/AddSensor';
import Feedback from '../components/feedback/Feedback';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <HomeRoute path="/" exact={true} />
        <LoginRoute path="/login" exact={true} component={Login} />
        <Route path="/about" exact={true} component={About} />
        <Route path="/buildings" exact={true} component={Overview} />
        <Route
          path="/buildings/temperature"
          exact={true}
          component={OverviewTemperature}
        />
        <Route
          path="/buildings/humidity"
          exact={true}
          component={OverviewHumidity}
        />
        <Route path="/buildings/co2" exact={true} component={OverviewCO2} />
        <Route
          path="/buildings/electricity"
          exact={true}
          component={OverviewElectricity}
        />
        <StaffRoute
          path="/buildings/add"
          exact={true}
          component={AddBuilding}
        />
        <StaffRoute path="/buildings/edit" exact={true} component={Buildings} />
        <Route path="/buildings/:id" exact={true} component={Building} />
        <StaffRoute
          path="/buildings/:id/edit"
          exact={true}
          component={BuildingEdit}
        />
        <AdminRoute path="/debug" exact={true} component={Debug} />
        <AdminRoute path="/systemlog" exact={true} component={SystemLog} />
        <PrivateRoute path="/settings" exact={true} component={Settings} />
        <PrivateRoute path="/feedback" exact={true} component={Feedback} />
        <PrivateRoute path="/test" exact={true} component={Test} />
        <StaffRoute path="/apartments" exact={true} component={Apartments} />
        <StaffRoute
          path="/apartments/add"
          exact={true}
          component={AddApartment}
        />
        <PrivateRoute
          path="/apartments/:id"
          exact={true}
          component={Dashboard}
        />
        <PrivateRoute
          path="/apartments/:id/conditions"
          exact={true}
          component={Conditions}
        />
        <PrivateRoute
          path="/apartments/:id/consumption"
          exact={true}
          component={Consumption}
        />
        <StaffRoute
          path="/apartments/:id/edit"
          exact={true}
          component={ApartmentEdit}
        />
        <StaffRoute path="/users" exact={true} component={Users} />
        <StaffRoute path="/users/add" exact={true} component={AddUser} />
        <StaffRoute path="/users/:id" exact={true} component={User} />
        <StaffRoute path="/sensors" exact={true} component={Sensors} />
        <StaffRoute path="/sensors/add" exact={true} component={AddSensor} />
        <StaffRoute path="/sensors/:id" exact={true} component={Sensor} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
