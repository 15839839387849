import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import { get, round } from 'lodash';
import { format } from 'date-fns';

import layouts from '../../styles/layouts';
import Loading from '../common/Loading';
import Error from '../common/Error';
import OverviewTabs from './OverviewTabs';
import { BUILDINGS_SENSORS_QUERY } from '../../data/queries';
import {
  temperatureText,
  humidityText,
  co2Text,
  // electricityText,
} from '../../data/texts';
import variables from '../../styles/variables';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    ...layouts.tableStyles,
    paper: {
      maxWidth: variables.tableMaxWidth,
    },
    overviewInfo: {
      marginTop: '30px',
    },
    sensorText: {
      fontWeight: 500,
    },
    buildingAddress: {
      borderBottom: 'none',
      padding: '1em',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
    },
    borderless: {
      borderBottom: 'none',
    },
    borderlessTableCell: {
      borderBottom: 'none',
      ...layouts.tableStyles.tableCell,
    },
    timeValue: {
      fontSize: ' 0.8125rem',
      margin: '10px 0',
    },
    buildingLink: {
      padding: '0px',
      textTransform: 'none',
      justifyContent: 'initial',
      borderRadius: '0px',
      [breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
    },
    measurementsHeading: {
      padding: '24px 8px 0px 8px',
      [breakpoints.up('md')]: {
        padding: '24px 24px 0px 24px',
      },
    },
    padding: {
      padding: '12px 8px 24px 8px',
      [breakpoints.up('md')]: {
        padding: '12px 24px 24px 24px',
      },
    },
  });

interface IProps {
  classes: any;
}

class Overview extends React.Component<IProps & RouteComponentProps> {
  render() {
    const { classes, history } = this.props;
    const tab = 0;

    const borderless = (building, index) =>
      building.sensors.length - 1 !== index
        ? {
            root: classes.borderlessTableCell,
          }
        : {
            root: classes.tableCell,
          };

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Overview
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <OverviewTabs tab={tab} />
        <Paper elevation={1} classes={{ root: classes.paper }}>
          <Typography
            variant="h6"
            gutterBottom={true}
            className={classes.measurementsHeading}
          >
            Latest measurements
          </Typography>
          <Query query={BUILDINGS_SENSORS_QUERY}>
            {({ data, error, loading }) =>
              loading ? (
                <div className={classes.padding}>
                  <Loading />
                </div>
              ) : error ? (
                <div className={classes.padding}>
                  <Error />
                </div>
              ) : (
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <TableCell classes={{ root: classes.firstHeadingCell }}>
                        Building
                      </TableCell>
                      <Hidden smDown={true}>
                        <TableCell
                          align="right"
                          classes={{ root: classes.headingCell }}
                        >
                          Time
                        </TableCell>
                      </Hidden>
                      <TableCell
                        align="right"
                        classes={{ root: classes.headingCell }}
                      >
                        Temperature
                      </TableCell>
                      <TableCell
                        align="right"
                        classes={{ root: classes.headingCell }}
                      >
                        Humidity
                      </TableCell>
                      <TableCell
                        align="right"
                        classes={{ root: classes.headingCell }}
                      >
                        CO2
                      </TableCell>
                      <TableCell
                        align="right"
                        classes={{ root: classes.headingCell }}
                      >
                        Electricity
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {get(data, 'buildings', []).map(building => (
                      <React.Fragment key={building.id}>
                        {get(building, 'sensors', []).length > 0 && (
                          <TableRow>
                            <TableCell
                              classes={{ root: classes.buildingAddress }}
                            >
                              <Button
                                fullWidth={true}
                                color="secondary"
                                classes={{ root: classes.buildingLink }}
                                onClick={() =>
                                  history.push(`/buildings/${building.id}`)
                                }
                              >
                                <DomainIcon />
                                &nbsp;{building.address}
                              </Button>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        )}
                        {get(building, 'sensors', []).map((sensor, index) => (
                          <TableRow key={sensor.devEUI}>
                            <TableCell
                              component="th"
                              scope="row"
                              classes={borderless(building, index)}
                            >
                              <span className={classes.sensorText}>
                                {sensor.location}
                              </span>
                              <Hidden mdUp={true}>
                                <div className={classes.timeValue}>
                                  {sensor.data.length > 0 &&
                                    sensor.data[0].time &&
                                    format(
                                      new Date(sensor.data[0].time),
                                      'dd.M.yyyy HH:mm:ss',
                                    )}
                                </div>
                              </Hidden>
                            </TableCell>
                            <Hidden smDown={true}>
                              <TableCell
                                align="right"
                                classes={borderless(building, index)}
                              >
                                {' '}
                                {sensor.data.length > 0 && sensor.data[0].time
                                  ? format(
                                      new Date(sensor.data[0].time),
                                      'dd.M.yyyy HH:mm:ss',
                                    )
                                  : '-'}
                              </TableCell>
                            </Hidden>
                            <TableCell
                              align="right"
                              classes={borderless(building, index)}
                            >
                              {sensor.data.length > 0 &&
                              sensor.data[0].temperature
                                ? `${
                                    sensor.data[0].temperature > 6000
                                      ? Number.parseFloat(
                                          (
                                            sensor.data[0].temperature - 6553.5
                                          ).toPrecision(2),
                                        )
                                      : sensor.data[0].temperature
                                  }°C`
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={borderless(building, index)}
                            >
                              {sensor.data.length > 0 && sensor.data[0].humidity
                                ? `${sensor.data[0].humidity}%`
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={borderless(building, index)}
                            >
                              {sensor.data.length > 0 && sensor.data[0].co2
                                ? `${sensor.data[0].co2} ppm`
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={borderless(building, index)}
                            >
                              {sensor.data.length > 0 && sensor.data[0].power
                                ? `${round(sensor.data[0].power, 0)} W`
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              )
            }
          </Query>
        </Paper>
        <Grid container={true} spacing={2} className={classes.overviewInfo}>
          <Grid item={true} xs={12}>
            <Typography variant="h5">Measurement information</Typography>
          </Grid>
          <Grid item={true} xs={12} md={6} lg={4}>
            <Typography variant="body1" gutterBottom={true}>
              Temperature
            </Typography>
            <Typography>{temperatureText}</Typography>
          </Grid>
          <Grid item={true} xs={12} md={6} lg={4}>
            <Typography variant="body1" gutterBottom={true}>
              Humidity
            </Typography>
            <Typography>{humidityText}</Typography>
          </Grid>
          <Grid item={true} xs={12} md={6} lg={4}>
            <Typography variant="body1" gutterBottom={true}>
              CO2
            </Typography>
            <Typography>{co2Text}</Typography>
          </Grid>
          {/* <Grid item={true} xs={12} md={6} lg={4}>
            <Typography variant="body1" gutterBottom={true}>
              Electricity
            </Typography>
            <Typography>{electricityText}</Typography>
          </Grid> */}
        </Grid>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Overview));
