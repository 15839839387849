import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    dialog: {
      maxWidth: variables.dialogWidth,
    },
  });

interface IProps {
  title: string;
  message: string;
  open: boolean;
  onClose: any;
  classes: any;
}

class ErrorDialog extends React.Component<IProps> {
  render() {
    const { classes, message, onClose, open, title } = this.props;

    return (
      <Dialog
        aria-labelledby="dialog-title"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{message}</Typography>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ErrorDialog);
