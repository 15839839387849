import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Typography,
  Paper,
  TextField,
  Button,
  WithStyles,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import variables from '../../styles/variables';

import layouts from '../../styles/layouts';
import { Mutation } from 'react-apollo';
import { SEND_FEEDBACK_EMAIL_MUTATION } from '../../data/mutations';
import Loading from '../common/Loading';
import { get } from 'lodash';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    ...layouts.tableStyles,
    paper: {
      maxWidth: variables.tableMaxWidth,
    },
    feedbackHeading: {
      padding: '24px 8px 0px 8px',
      [breakpoints.up('md')]: {
        padding: '24px 24px 0px 24px',
      },
    },
    feedbackSuccess: {
      padding: '8px 8px 0px 8px',
      color: 'green',
    },
    feedbackFailure: {
      padding: '8px 8px 0px 8px',
      color: 'red',
    },
    textField: {
      marginLeft: 16,
      marginRight: 16,
      width: 'calc(100% - 30px)',
    },
    sendIcon: {
      marginRight: 8,
    },
  });

interface IProps extends WithStyles<typeof styles> {}
interface IState {
  feedbackMessage: string;
  feedbackSuccess: string;
  feedbackFailure: string;
}

class Feedback extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      feedbackMessage: '',
      feedbackSuccess: '',
      feedbackFailure: '',
    };
  }

  onFeedbackMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ feedbackMessage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { feedbackMessage, feedbackSuccess, feedbackFailure } = this.state;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Feedback
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Paper elevation={1} classes={{ root: classes.paper }}>
          <Mutation mutation={SEND_FEEDBACK_EMAIL_MUTATION}>
            {(sendFeedbackEmail, { loading }) => (
              <>
                <Typography
                  variant="h6"
                  gutterBottom={true}
                  className={classes.feedbackHeading}
                >
                  Feedback
                </Typography>
                {feedbackSuccess.length > 0 && (
                  <Typography
                    variant="body1"
                    className={classes.feedbackSuccess}
                  >
                    {feedbackSuccess}
                  </Typography>
                )}
                {feedbackFailure.length > 0 && (
                  <Typography
                    variant="body1"
                    className={classes.feedbackFailure}
                  >
                    {feedbackFailure}
                  </Typography>
                )}
                <form
                  autoComplete="off"
                  onSubmit={async e => {
                    e.preventDefault();
                    this.setState({ feedbackSuccess: '', feedbackFailure: '' });
                    // Arbitrary length
                    if (feedbackMessage.length > 5) {
                      const result = await sendFeedbackEmail({
                        variables: { message: feedbackMessage },
                      });
                      const { success } = get(
                        result,
                        'data.sendFeedbackEmail',
                        false,
                      );
                      if (success) {
                        // Thank user for feedback
                        this.setState({
                          feedbackSuccess: 'Thank you for your feedback!',
                          feedbackMessage: '',
                        });
                      } else {
                        // Inform user that there was a server side error
                        this.setState({
                          feedbackFailure:
                            'There was a problem sending your feedback - please try again later.',
                        });
                      }
                    }
                  }}
                >
                  <TextField
                    id="feedback-textfield"
                    label="Feedback"
                    multiline={true}
                    rows="6"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={this.onFeedbackMessageChanged}
                    value={feedbackMessage}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      paddingBottom: 16,
                      marginRight: 16,
                    }}
                  >
                    {loading && <Loading />}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading || feedbackMessage.length <= 5}
                      type="submit"
                    >
                      Send
                      <SendIcon className={classes.sendIcon} />
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Mutation>
        </Paper>
      </section>
    );
  }
}

export default withStyles(styles)(Feedback);
