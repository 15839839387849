import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { Query } from 'react-apollo';

import layouts from '../../styles/layouts';
import { CURRENT_USER_QUERY, USERS_QUERY } from '../../data/queries';
import UserDetailsForm from './UserDetailsForm';
import ChangePasswordForm from './ChangePasswordForm';
import Error from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    tabs: {
      margin: variables.tabsMargin,
    },
    paper: {
      padding: '24px',
      maxWidth: variables.paperFormWidth,
    },
  });

interface IProps {
  classes: any;
}

interface IState {
  tab: number;
}

class Settings extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
    };
  }

  handleTabChange = (_, value) => {
    this.setState(state => ({ ...state, tab: value }));
  };

  render() {
    const { classes } = this.props;
    const { tab } = this.state;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Settings
        </Typography>
        <Divider />
        <Tabs
          value={tab}
          onChange={this.handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          className={classes.tabs}
        >
          <Tab label="User details" />
          <Tab label="Change password" />
        </Tabs>
        <Query query={CURRENT_USER_QUERY}>
          {({
            data: {
              currentUser: { id },
            },
          }) => (
            <Paper
              elevation={1}
              square={true}
              classes={{ root: classes.paper }}
            >
              {tab === 0 && (
                <Query query={USERS_QUERY} variables={{ id }}>
                  {({ data, error, loading }) =>
                    loading ? (
                      <Loading />
                    ) : !error ? (
                      <UserDetailsForm
                        user={data.users.find(el => el.id === id)}
                      />
                    ) : (
                      <Error />
                    )
                  }
                </Query>
              )}
              {tab === 1 && <ChangePasswordForm />}
            </Paper>
          )}
        </Query>
      </section>
    );
  }
}

export default withStyles(styles)(Settings);
