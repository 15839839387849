import { OothClient } from 'ooth-client';

import { API_HOST, API_PORT } from '../env';

export const ooth = new OothClient({
  url: `${API_HOST}${API_PORT ? `:${API_PORT}` : ''}/auth`,
  secondaryAuthMode: 'session', // for cookie-based session, or 'jwt'
  /*
  api: { // Optional, use if you have an api with resources to make authenticated calls to
    url: 'http://localhost:4000',
    primaryAuthMode: 'jwt', // Optional, use *only if* you have to create a *new* cookie-based session
    secondaryAuthMode: 'session', // for cookie-based session
    // loginPath: `${API_HOST}/login`, // Optional, use only if primaryAuthMode is 'jwt'
    // logoutPath: `${API_HOST}/login`, // Optional, use only if primaryAuthMode is 'jwt'
  },
  */
  // ws: true, // Optional, subscribes to user changes with websocket, you need `ooth-ws` on client
});
