export const humidityText = `
  In the winter the standard relative humidity is between 20 - 40%.
  During cold winter temperatures the relative indoor humidity may
  fall below 20%. In the summer the relative indoor humidity varies
  between 50 - 70% depending on the outdoor humidity.
`;

export const temperatureText = `
  Sufficient temperature in the shared spaces is 17 - 18°C. A
  standard value for houseroom temperatures is about 20 - 22°C.
`;

export const co2Text = `
  Typical measurement in indoor spaces with good air exchange is
  between 350 - 1000 ppm.
`;

export const electricityText = `
  Something something kilowatthours.
`;

export const waterText = `
 On average a household uses hot and cold water.
`;
