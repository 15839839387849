import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import variables from '../../styles/variables';
import layouts from '../../styles/layouts';
import { SENSORS_WITH_APARTMENT_QUERY } from '../../data/queries';
import {
  CREATE_SENSOR_MUTATION,
  UPDATE_SENSOR_MUTATION,
  DELETE_SENSOR_MUTATION,
} from '../../data/mutations';
import Loading from '../common/Loading';
import Error from '../common/Error';
import { get } from 'lodash';
import { ISensor } from './types';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    firstItem: {
      [breakpoints.up('md')]: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    field: {
      xWidth: variables.formWidth,
    },
    paper: {
      padding: variables.paperPadding,
    },
    addButton: {
      marginTop: '17px',
    },
  });

interface IState {
  copy: ISensor;
  editEnabled: boolean;
  devEUIValid: boolean;
  locationValid: boolean;
  propertiesValid: boolean;
  notesValid: boolean;
  confirmationDialog: boolean;
  targetDevEUI: string;
}

interface IProps {
  classes: any;
  sensor: ISensor;
  addSensor: boolean;
}

type AllProps = IProps & RouteComponentProps;

class SensorForm extends React.Component<AllProps, IState> {
  constructor(props: AllProps) {
    super(props);
    const { sensor, addSensor } = this.props;
    this.state = {
      copy: sensor,
      editEnabled: addSensor,
      devEUIValid: true,
      locationValid: true,
      propertiesValid: true,
      notesValid: true,
      confirmationDialog: false,
      targetDevEUI: '',
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    const { copy } = this.state;
    this.setState({ copy: { ...copy, [name]: value } });
  };

  handleCommitValues = commitValues => {
    const { addSensor } = this.props;
    const {
      devEUI,
      type,
      location,
      notes,
      properties,
      apartment,
    } = this.state.copy;
    const devEUIValid = devEUI.length > 0;
    const locationValid = location.length > 0;
    this.setState({
      devEUIValid,
      locationValid,
    });
    if (devEUIValid && locationValid) {
      commitValues({
        variables: { devEUI, type, location, notes, properties, apartment },
      }).then(data => {
        if (!addSensor) {
          this.setState({ editEnabled: false });
        } else {
          this.props.history.push(
            `/sensors/${get(data, 'data.createSensor.sensor.devEUI')}`,
          );
        }
      });
    }
  };

  handleCancel = () => {
    const { sensor } = this.props;
    this.setState(state => ({
      ...state,
      copy: sensor,
    }));
    this.toggleEditMode();
  };

  toggleEditMode = () => {
    this.setState(state => ({ editEnabled: !state.editEnabled }));
  };

  handleDeleteSensor = (deleteSensor, targetId) => {
    deleteSensor({ variables: { devEUI: targetId } })
      .then(() => {
        this.setState({ confirmationDialog: false });
      })
      .then(() => {
        this.props.history.push(`/sensors/`);
      });
  };

  render() {
    const { classes, addSensor } = this.props;
    const {
      editEnabled,
      devEUIValid,
      locationValid,
      confirmationDialog,
      targetDevEUI,
    } = this.state;
    const { devEUI, type, location, notes } = this.state.copy;

    return (
      <Paper elevation={1} square={true} classes={{ root: classes.paper }}>
        <form className={classes.sensorForm} autoComplete="off">
          <Grid container={true} spacing={2}>
            <Grid
              item={true}
              xs={12}
              md={6}
              classes={{ item: classes.firstItem }}
            >
              <Grid container={true} direction="column">
                <Typography variant="h6" gutterBottom={true}>
                  Sensor details
                </Typography>
                <TextField
                  disabled={!editEnabled}
                  error={!devEUIValid}
                  label="devEUI"
                  id="devEUI"
                  variant="outlined"
                  value={devEUI}
                  onChange={this.handleChange('devEUI')}
                  margin="normal"
                  classes={{ root: classes.field }}
                  helperText={devEUIValid ? ' ' : 'Invalid devEUI'}
                />
                <TextField
                  disabled={!editEnabled}
                  label="Type"
                  id="type"
                  value={type || ''}
                  variant="outlined"
                  onChange={this.handleChange('type')}
                  margin="normal"
                  classes={{ root: classes.field }}
                />
                <TextField
                  disabled={!editEnabled}
                  error={!locationValid}
                  label="Location"
                  id="location"
                  variant="outlined"
                  value={location}
                  onChange={this.handleChange('location')}
                  margin="normal"
                  classes={{ root: classes.field }}
                  helperText={locationValid ? ' ' : 'Invalid location'}
                />
                <TextField
                  disabled={!editEnabled}
                  label="Notes"
                  id="notes"
                  value={notes}
                  variant="outlined"
                  onChange={this.handleChange('notes')}
                  margin="normal"
                  classes={{ root: classes.field }}
                />
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              {addSensor && (
                <Grid container={true} direction="column">
                  <Mutation
                    mutation={CREATE_SENSOR_MUTATION}
                    refetchQueries={[{ query: SENSORS_WITH_APARTMENT_QUERY }]}
                  >
                    {(createSensor, { loading, error }) =>
                      error ? (
                        <Error />
                      ) : loading ? (
                        <Loading />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          onClick={() => this.handleCommitValues(createSensor)}
                        >
                          Create sensor
                        </Button>
                      )
                    }
                  </Mutation>
                </Grid>
              )}
              {!editEnabled && !addSensor && (
                <Grid container={true} direction="column">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.toggleEditMode}
                    classes={{ root: classes.field }}
                  >
                    Edit sensor
                  </Button>
                </Grid>
              )}
              {editEnabled && !addSensor && (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <Mutation
                      mutation={UPDATE_SENSOR_MUTATION}
                      refetchQueries={[{ query: SENSORS_WITH_APARTMENT_QUERY }]}
                    >
                      {(updateSensor, { loading, error }) =>
                        error ? (
                          <Error />
                        ) : loading ? (
                          <Loading />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={() =>
                              this.handleCommitValues(updateSensor)
                            }
                          >
                            Save
                          </Button>
                        )
                      }
                    </Mutation>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Button
                      onClick={this.handleCancel}
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Mutation
                      mutation={DELETE_SENSOR_MUTATION}
                      refetchQueries={[{ query: SENSORS_WITH_APARTMENT_QUERY }]}
                    >
                      {(deleteSensor, { loading, error }) =>
                        error ? (
                          <Error />
                        ) : loading ? (
                          <Loading />
                        ) : (
                          <>
                            <Button
                              onClick={() =>
                                this.setState({
                                  confirmationDialog: true,
                                  targetDevEUI: devEUI,
                                })
                              }
                              variant="outlined"
                              color="secondary"
                              fullWidth={true}
                            >
                              Delete
                            </Button>
                            <ConfirmationDialog
                              open={confirmationDialog}
                              title={`Do you want to delete ${targetDevEUI}?`}
                              targetName={targetDevEUI}
                              targetId={targetDevEUI}
                              subject={'sensor'}
                              onCancel={() =>
                                this.setState({
                                  confirmationDialog: !confirmationDialog,
                                  targetDevEUI: '',
                                })
                              }
                              onCommit={() =>
                                this.handleDeleteSensor(
                                  deleteSensor,
                                  targetDevEUI,
                                )
                              }
                            />
                          </>
                        )
                      }
                    </Mutation>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export default withStyles(styles)(withRouter(SensorForm));
