import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

import InfoPanel from './InfoPanel';
import { option } from '../../charts/water2';
import { waterText } from '../../data/texts';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
  });

class WaterGraph extends React.Component<any> {
  render() {
    const { classes, data, infopanel } = this.props;
    // console.log('WaterGraph.render()');

    return (
      <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
        {infopanel ? (
          <InfoPanel
            title="Water"
            content={<Typography>{waterText}</Typography>}
          />
        ) : (
          <Typography variant="h6" gutterBottom={true}>
            Water Consumption
          </Typography>
        )}
        <ReactEcharts
          // option={option(data.map(obj => new Date(obj.time)), data.map(obj => obj.hot))}
          option={option(data)}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(WaterGraph);
