import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography, Theme } from '@material-ui/core';
import { subWeeks, isSameISOWeek, subMonths, isAfter } from 'date-fns';
import { round } from 'lodash';
import { IWater } from '../../charts/water';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subheading: {
      fontSize: '1.125rem',
      marginTop: '16px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    button: {
      margin: theme.spacing(),
    },
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
  }),
);

interface IProps {
  readonly data: ReadonlyArray<IWater>;
}

const totalWater = (water: ReadonlyArray<IWater>) => {
  let cumulativeWater = 0;
  water.forEach((dataPoint, index) => {
    if (index < water.length - 1) {
      cumulativeWater += dataPoint.hot || 0;
      cumulativeWater += dataPoint.cold || 0;
    }
  });
  return cumulativeWater;
};

const last30DaysWater = (water: ReadonlyArray<IWater>) => {
  const last30Days = water.filter(dataPoint =>
    isAfter(dataPoint.time, subMonths(new Date(), 1)),
  );
  // console.log(last30Days);
  return totalWater(last30Days);
};

const lastWeekWater = (water: ReadonlyArray<IWater>) => {
  const lastWeek = water.filter(dataPoint =>
    isSameISOWeek(dataPoint.time, subWeeks(new Date(), 1)),
  );
  // console.log(lastWeek);
  return totalWater(lastWeek);
};

const thisWeekWater = (water: ReadonlyArray<IWater>) => {
  const thisWeek = water.filter(dataPoint =>
    isSameISOWeek(dataPoint.time, new Date()),
  );
  // console.log(thisWeek);
  return totalWater(thisWeek);
};

const WaterUsage = ({ data }) => {
  const classes = useStyles();
  // console.log('WaterUsage.render()');
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={6}>
        <Typography classes={{ root: classes.subheading }} gutterBottom={true}>
          Total
        </Typography>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography>{`Last 30 days: ${round(
              last30DaysWater(data),
              1,
            )} liters`}</Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography>{`Last week: ${round(
              lastWeekWater(data),
              1,
            )} liters`}</Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography>{`Current week: ${round(
              thisWeekWater(data),
              1,
            )} liters`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WaterUsage;
