import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import DescriptionIcon from '@material-ui/icons/DescriptionSharp';

import layouts from '../../styles/layouts';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    paragraph: {
      margin: '16px 0',
    },
  });

class SystemLog extends React.Component<{ classes: any }> {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          System Log
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Typography variant="body1" gutterBottom={true}>
          Under construction.
        </Typography>
        <Typography classes={{ root: classes.paragraph }}>
          <DescriptionIcon /> <BuildIcon />
        </Typography>
      </section>
    );
  }
}

export default withStyles(styles)(SystemLog);
