import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button, TextField, Snackbar, IconButton } from '@material-ui/core';
import variables from '../../styles/variables';
import CloseIcon from '@material-ui/icons/Close';
import { Mutation } from 'react-apollo';
import { UPDATE_USER_PROFILE_MUTATION } from '../../data/mutations';
import Loading from './Loading';
import ShowPasswordToggle from './ShowPasswordToggle';

const styles = () =>
  createStyles({
    form: {
      width: variables.formWidth,
      display: 'flex',
      flexDirection: 'column',
    },
    saveButton: {
      marginTop: '10px',
    },
  });

interface IProps {
  user: any;
  classes: any;
}

interface IState {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  firstnameValid: boolean;
  lastnameValid: boolean;
  emailValid: boolean;
  passwordValid: boolean;
  showPassword: boolean;
  showSuccess: boolean;
  showError: boolean;
  errorMessage: string;
}

class UserDetailsForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      firstname: props.user.firstName || '',
      lastname: props.user.lastName || '',
      email: props.user.email,
      password: '',
      firstnameValid: true,
      lastnameValid: true,
      emailValid: true,
      passwordValid: true,
      showPassword: false,
      showSuccess: false,
      showError: false,
      errorMessage: '',
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  handleSave = updateProfile => {
    const { firstname, lastname, email, password } = this.state;
    const firstnameValid = firstname.length > 0;
    const lastnameValid = lastname.length > 0;
    const emailValid = email.length > 0 && email.includes('@');
    const passwordValid = password.length > 0;
    this.setState(state => ({
      ...state,
      firstnameValid,
      lastnameValid,
      emailValid,
      passwordValid,
    }));
    if (firstnameValid && lastnameValid && emailValid && passwordValid) {
      updateProfile({
        variables: {
          email,
          firstName: firstname,
          lastName: lastname,
          currentPassword: password,
        },
      }).then(() => {
        this.setState({ showSuccess: true, password: '' });
      });
    }
  };

  onError = error => {
    const { firstName, lastName, email } = this.props.user;
    this.setState({
      email,
      showError: true,
      errorMessage: error.message,
      firstname: firstName || '',
      lastname: lastName || '',
    });
  };

  onFeedbackClose = () => {
    this.setState({ showSuccess: false, showError: false, errorMessage: '' });
  };

  toggleVisibility = (name: string) => {
    this.setState(state => ({ ...state, [name]: !state[name] }));
  };

  render() {
    const { classes } = this.props;
    const {
      firstname,
      lastname,
      email,
      password,
      firstnameValid,
      lastnameValid,
      emailValid,
      passwordValid,
      showPassword,
      showSuccess,
      showError,
      errorMessage,
    } = this.state;

    return (
      <form className={classes.form} autoComplete="off">
        <TextField
          error={!firstnameValid}
          label="First name"
          id="firstname"
          value={firstname}
          variant="outlined"
          onChange={this.handleChange('firstname')}
          helperText={firstnameValid ? ' ' : 'Invalid username'}
          margin="dense"
        />
        <TextField
          error={!lastnameValid}
          label="Last name"
          id="lastname"
          value={lastname}
          variant="outlined"
          onChange={this.handleChange('lastname')}
          helperText={lastnameValid ? ' ' : 'Invalid username'}
          margin="dense"
        />
        <TextField
          error={!emailValid}
          label="Email"
          id="email"
          value={email}
          variant="outlined"
          onChange={this.handleChange('email')}
          helperText={emailValid ? ' ' : 'Invalid email'}
          margin="dense"
        />
        <TextField
          error={!passwordValid}
          label="Current password"
          id="password"
          value={password}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          onChange={this.handleChange('password')}
          helperText={passwordValid ? ' ' : 'Invalid password'}
          margin="dense"
          InputProps={{
            endAdornment: (
              <ShowPasswordToggle
                visible={showPassword}
                toggleVisibility={() => this.toggleVisibility('showPassword')}
              />
            ),
          }}
        />
        <Mutation
          mutation={UPDATE_USER_PROFILE_MUTATION}
          onError={this.onError}
        >
          {(updateProfile, { loading }) =>
            loading ? (
              <Loading />
            ) : (
              <>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={showSuccess}
                  autoHideDuration={4000}
                  onClose={this.onFeedbackClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">Profile updated</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.onFeedbackClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSave(updateProfile)}
                  disabled={loading}
                >
                  Save changes
                </Button>
              </>
            )
          }
        </Mutation>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showError}
          autoHideDuration={6000}
          onClose={this.onFeedbackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{`Error: ${errorMessage}`}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.onFeedbackClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </form>
    );
  }
}

export default withStyles(styles)(UserDetailsForm);
