import React from 'react';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Hidden } from '@material-ui/core';
import DrawerContent from './DrawerContent';
import variables from '../../styles/variables';
import { DRAWER_QUERY } from '../../data/queries';

const styles = {
  mobile: {
    width: variables.drawerWidth,
  },
  desktop: {
    marginTop: '64px',
    width: variables.drawerWidth,
  },
};

class DrawerMenu extends React.Component<{ classes: any }> {
  render() {
    const { classes } = this.props;

    return (
      <Query query={DRAWER_QUERY}>
        {({ data, client }) => (
          <React.Fragment>
            <Hidden smUp={true}>
              <Drawer
                variant="temporary"
                open={data.mobileDrawerOpen}
                onClose={() =>
                  client.writeData({
                    data: { mobileDrawerOpen: !data.mobileDrawerOpen },
                  })
                }
                classes={{ paper: classes.mobile }}
              >
                <DrawerContent />
              </Drawer>
            </Hidden>
            <Hidden xsDown={true}>
              <Drawer
                variant="persistent"
                open={data.desktopDrawerOpen}
                classes={{ paper: classes.desktop }}
              >
                <DrawerContent />
              </Drawer>
            </Hidden>
          </React.Fragment>
        )}
      </Query>
    );
  }
}

export default withStyles(styles)(DrawerMenu);
