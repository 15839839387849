import { format } from 'date-fns';
import theme from '../styles/theme';
import _ from 'lodash';

export interface IWater {
  readonly time: Date;
  readonly hot: number;
  readonly cold: number;
}

export const waterOption = (data: ReadonlyArray<IWater>) => ({
  dataset: {
    dimensions: ['time', 'hot', 'cold'],
    source: data,
  },
  textStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  tooltip: {
    trigger: 'axis',
    formatter: params => {
      return `
      ${format(new Date(params[0].value.time), 'd.M.yyyy')}
      <br />
      ${format(new Date(params[0].value.time), 'HH:mm:ss')}
      <br />
      ${params[0].marker} ${params[0].value.hot} l
      <br />
      ${params[1].marker} ${params[1].value.cold} l
    `;
    },
  },
  xAxis: {
    type: 'time',
    axisLabel: {
      formatter: value => format(new Date(value), 'd.M. HH:mm'),
      margin: 14,
    },
    boundaryGap: false,
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dotted',
      },
    },
    name: 'liters',
    min: 0,
    max:
      Math.max(
        Math.max(..._.map(data, 'cold')),
        Math.max(..._.map(data, 'hot')),
      ) + 1,
  },
  grid: {
    top: 33,
    left: 42,
    right: 85,
    bottom: 70,
  },
  legend: {
    icon: 'rect',
  },
  series: [
    {
      type: 'line',
      name: 'hot',
      encode: {
        x: 'time',
        y: 'hot',
      },
    },
    {
      type: 'line',
      name: 'cold',
      encode: {
        x: 'time',
        y: 'cold',
      },
    },
  ],
  dataZoom: [
    {
      type: 'inside',
      start: 95,
    },
    {
      type: 'slider',
      start: 95,
      labelFormatter: value => format(new Date(value), 'd.M. HH:mm'),
    },
  ],
  // visualMap: {
  //   textStyle: {
  //     fontSize: 12,
  //   },
  //   top: 'top',
  //   right: 0,
  //   // pieces: [
  //   //   {
  //   //     lt: 5000,
  //   //     color: green[500],
  //   //     symbol: 'rect',
  //   //   },
  //   //   {
  //   //     gte: 5000,
  //   //     lte: 10000,
  //   //     color: amber[500],
  //   //     symbol: 'rect',
  //   //   },
  //   //   {
  //   //     gt: 10000,
  //   //     color: red[500],
  //   //     symbol: 'rect',
  //   //   },
  //   // ],
  //   outOfRange: {
  //     color: grey[500],
  //     symbol: 'rect',
  //   },
  // },
});
