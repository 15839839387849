import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import Error from './Error';
import layouts from '../../styles/layouts';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
  });

class ErrorPage extends React.Component<{ classes: any }> {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Error occurred
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Error />
      </section>
    );
  }
}

export default withStyles(styles)(ErrorPage);
