import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import BuildIcon from '@material-ui/icons/Build';

import layouts from '../../styles/layouts';

const useStyles = makeStyles(() =>
  createStyles({
    ...layouts.contentWrapper,
    paragraph: {
      margin: '16px 0',
    },
  }),
);

class Debug extends React.Component<{}> {
  render() {
    const classes = useStyles();

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Debug
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Typography variant="body1" gutterBottom={true}>
          Under construction.
        </Typography>
        <Typography classes={{ root: classes.paragraph }}>
          <BugReportIcon /> <BuildIcon />
        </Typography>
      </section>
    );
  }
}

export default Debug;
