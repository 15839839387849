import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  AppBar,
  Avatar,
  Button,
  Hidden,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftSharpIcon from '@material-ui/icons/ChevronLeftSharp';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';

import LogoutMenuItem from './LogoutMenuItem';

import { WEBAPP_VERSION } from '../../env';

import { ReactComponent as Logo } from '../../newlogo.svg';
import {
  DRAWER_QUERY,
  CURRENT_USER_QUERY,
  AUTHENTICATED_QUERY,
} from '../../data/queries';
import { initials } from '../../utils/currentUser';

const styles = ({ breakpoints, palette, zIndex }: Theme) =>
  createStyles({
    appBar: {
      [breakpoints.up('sm')]: {
        zIndex: zIndex.drawer + 1,
      },
    },
    avatarButton: {
      position: 'absolute',
      right: '15px',
      padding: '0px',
    },
    avatar: {
      backgroundColor: palette.primary.light,
      '&:hover': {
        backgroundColor: fade(palette.primary.light, 0.5),
      },
    },
    settingsMenu: {
      color: '#fff',
    },
    menuButton: {
      position: 'absolute',
      left: '5px',
    },
    logoButton: {
      color: 'white',
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '2em',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    logoSvg: {
      marginTop: '2px',
      height: '50px',
      width: '50px',
    },
    logoText: {
      margin: '0 10px',
      // color: '#2A7E43',
    },
    versionText: {
      fontSize: '0.875rem',
    },
    versionMenuItem: {
      display: 'block',
      height: 'auto',
    },
  });

interface IProps {
  classes: any;
}

interface IState {
  anchorEl: any;
}

class Navbar extends React.Component<IProps & RouteComponentProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  toggleMenu = (event?: any) => {
    const open = Boolean(this.state.anchorEl);
    if (open) {
      this.setState(state => ({ ...state, anchorEl: null }));
    } else {
      const { currentTarget } = event;
      this.setState(state => ({ ...state, anchorEl: currentTarget }));
    }
  };

  handleMenuItemClick = (route: string) => {
    this.props.history.push(route);
    this.toggleMenu();
  };

  render() {
    const { classes, history } = this.props;
    const { anchorEl } = this.state;

    return (
      <nav>
        <AppBar position="fixed" className={classes.appBar}>
          <Query query={DRAWER_QUERY}>
            {({ data: { desktopDrawerOpen, mobileDrawerOpen }, client }) => (
              <Toolbar>
                <Grid container={true} alignItems="center">
                  {/* Mobile drawer toggle */}
                  <Hidden smUp={true}>
                    <IconButton
                      className={classes.menuButton}
                      aria-label="Navigation menu"
                      color="inherit"
                      onClick={() =>
                        client.writeData({
                          data: { mobileDrawerOpen: !mobileDrawerOpen },
                        })
                      }
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                  {/* Desktop drawer toggle */}
                  <Hidden xsDown={true}>
                    <IconButton
                      className={classes.menuButton}
                      aria-label="Toggle navigation menu"
                      color="inherit"
                      onClick={() =>
                        client.writeData({
                          data: { desktopDrawerOpen: !desktopDrawerOpen },
                        })
                      }
                    >
                      {desktopDrawerOpen ? (
                        <ChevronLeftSharpIcon />
                      ) : (
                        <ChevronRightSharpIcon />
                      )}
                    </IconButton>
                  </Hidden>
                  <Button
                    onClick={() => history.push('/')}
                    className={classes.logoButton}
                  >
                    <Logo className={classes.logoSvg} />
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.logoText}
                    >
                      Energy App
                    </Typography>
                  </Button>
                  <Query query={AUTHENTICATED_QUERY}>
                    {({ data: { authenticated } }) => (
                      <Query query={CURRENT_USER_QUERY}>
                        {({ data: { currentUser } }) => (
                          <React.Fragment>
                            <IconButton
                              aria-label="Settings menu"
                              classes={{ root: classes.avatarButton }}
                              onClick={this.toggleMenu}
                            >
                              {authenticated ? (
                                <Avatar className={classes.avatar}>
                                  {initials(currentUser) ? (
                                    initials(currentUser)
                                  ) : (
                                    <AccountCircleIcon />
                                  )}
                                </Avatar>
                              ) : (
                                <MoreVertIcon
                                  classes={{ root: classes.settingsMenu }}
                                />
                              )}
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              onClose={this.toggleMenu}
                              open={Boolean(anchorEl)}
                            >
                              {authenticated ? (
                                [
                                  <MenuItem
                                    key={1}
                                    onClick={() =>
                                      this.handleMenuItemClick('/settings')
                                    }
                                  >
                                    <AccountCircleIcon color="secondary" />
                                    &nbsp; Settings
                                  </MenuItem>,
                                  <LogoutMenuItem
                                    key={2}
                                    toggleMenu={this.toggleMenu}
                                  />,
                                ]
                              ) : (
                                <MenuItem
                                  onClick={() =>
                                    this.handleMenuItemClick('/login')
                                  }
                                >
                                  <ExitToAppSharpIcon color="secondary" />
                                  &nbsp; Login
                                </MenuItem>
                              )}
                              <MenuItem
                                button={false}
                                classes={{ root: classes.versionMenuItem }}
                              >
                                Version
                                <div className={classes.versionText}>
                                  {WEBAPP_VERSION}
                                </div>
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </Query>
                    )}
                  </Query>
                </Grid>
              </Toolbar>
            )}
          </Query>
        </AppBar>
      </nav>
    );
  }
}

export default withStyles(styles)(withRouter(Navbar));
