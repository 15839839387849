import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import { Button, Divider, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WifiIcon from '@material-ui/icons/Wifi';
import MUIDataTable from 'mui-datatables';
import { get } from 'lodash';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

import layouts from '../../styles/layouts';
import Loading from '../common/Loading';
import Error from '../common/Error';
import LastValues from './LastValues';
import {
  SENSORS_WITH_APARTMENT_QUERY,
  CURRENT_USER_QUERY,
} from '../../data/queries';
import { UserLevel } from '../../utils/userLevel';
import { DELETE_SENSOR_MUTATION } from '../../data/mutations';

const styles = ({ breakpoints }) =>
  createStyles({
    ...layouts.contentWrapper,
    ...layouts.tableStyles,
    tableWrapper: {
      maxWidth: '1200px',
    },
    editButton: {
      [breakpoints.down('sm')]: {
        padding: '2px',
        '& svg': {
          height: '0.9rem',
          width: '0.9rem',
        },
      },
    },
    deleteButton: {
      [breakpoints.down('sm')]: {
        padding: '2px',
        '& svg': {
          height: '0.9rem',
          width: '0.9rem',
        },
      },
    },
    tableRoot: {
      '& thead': {
        '& th': {
          fontSize: '0.8125rem',
        },
      },
    },
    addButton: {
      marginBottom: '24px',
    },
  });

const allowEdit = (currentUserLevel: number) => {
  const userLevel = currentUserLevel;
  if (userLevel === UserLevel.Staff || userLevel === UserLevel.Admin) {
    return true;
  }
  return false;
};

interface IProps extends RouteComponentProps {
  classes: any;
}

interface IState {
  confirmationDialog: boolean;
  targetDevEUI: string;
}

class Sensors extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { confirmationDialog: false, targetDevEUI: '' };
  }

  handleDeleteSensor = (deleteSensor, targetId) => {
    deleteSensor({ variables: { devEUI: targetId } }).then(() => {
      this.setState({ confirmationDialog: false });
    });
  };

  render() {
    const { classes, history } = this.props;
    const { confirmationDialog, targetDevEUI } = this.state;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Sensors
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Button
          variant="outlined"
          color="secondary"
          classes={{ root: classes.addButton }}
          onClick={() => history.push('/sensors/add')}
        >
          <WifiIcon />
          &nbsp; Add sensor
        </Button>
        <Mutation
          mutation={DELETE_SENSOR_MUTATION}
          refetchQueries={[{ query: SENSORS_WITH_APARTMENT_QUERY }]}
        >
          {(deleteSensor, { loading, error }) =>
            error ? (
              <Error />
            ) : loading ? (
              <Loading />
            ) : (
              <ConfirmationDialog
                open={confirmationDialog}
                title={`Do you want to delete ${targetDevEUI}?`}
                targetName={targetDevEUI}
                targetId={targetDevEUI}
                subject={'sensor'}
                onCancel={() =>
                  this.setState({
                    confirmationDialog: !confirmationDialog,
                    targetDevEUI: '',
                  })
                }
                onCommit={() =>
                  this.handleDeleteSensor(deleteSensor, targetDevEUI)
                }
              />
            )
          }
        </Mutation>
        <Query query={CURRENT_USER_QUERY}>
          {({ data: { currentUser } }) => (
            <Query query={SENSORS_WITH_APARTMENT_QUERY}>
              {({ data, loading, error }) =>
                error ? (
                  <Error />
                ) : loading ? (
                  <Loading />
                ) : (
                  <div className={classes.tableWrapper}>
                    <MUIDataTable
                      classes={{ tableRoot: classes.tableRoot }}
                      title="Sensors"
                      columns={[
                        {
                          name: 'devEUI',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Type',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Location',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Notes',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Address',
                          options: {
                            sort: true,
                            filter: true,
                          },
                        },
                        {
                          name: 'Edit',
                          options: {
                            sort: false,
                            filter: false,
                            customBodyRender: value => (
                              <React.Fragment key={value}>
                                <IconButton
                                  classes={{ root: classes.editButton }}
                                  aria-label="Edit"
                                  color="secondary"
                                  onClick={() =>
                                    history.push(`/sensors/${value}`)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </React.Fragment>
                            ),
                          },
                        },
                        {
                          name: 'Delete',
                          options: {
                            sort: false,
                            filter: false,
                            customBodyRender: value => (
                              <React.Fragment key={value}>
                                {allowEdit(currentUser.userLevel) && (
                                  <>
                                    <IconButton
                                      classes={{ root: classes.deleteButton }}
                                      aria-label="Delete"
                                      color="secondary"
                                      onClick={() =>
                                        this.setState({
                                          confirmationDialog: true,
                                          targetDevEUI: value,
                                        })
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </>
                                )}
                              </React.Fragment>
                            ),
                          },
                        },
                        {
                          name: 'Last values',
                          options: {
                            sort: false,
                            filter: false,
                            customBodyRender: value => (
                              <LastValues
                                key={value.devEUI}
                                sensorData={value.data}
                              />
                            ),
                          },
                        },
                      ]}
                      data={get(data, 'sensors', []).map(sensor => [
                        get(sensor, 'devEUI', ''),
                        get(sensor, 'type', ''),
                        get(sensor, 'location', ''),
                        typeof sensor.notes === 'string'
                          ? sensor.notes.slice(0, 20)
                          : '',
                        `${get(sensor, 'apartment.number', '')}, ${get(
                          sensor,
                          'apartment.building.address',
                          '',
                        )}`,
                        get(sensor, 'devEUI', ''),
                        get(sensor, 'devEUI', ''),
                        sensor,
                      ])}
                      options={{
                        print: false,
                        download: false,
                        selectableRows: 'none',
                        elevation: 1,
                        rowsPerPageOptions: [10, 25, 50, 100],
                      }}
                    />
                  </div>
                )
              }
            </Query>
          )}
        </Query>
      </section>
    );
  }
}

export default withStyles(styles)(Sensors);
