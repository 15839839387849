import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import initGA from './analytics/init';
import { withTracker } from './analytics/withTracker';
import { NODE_ENV } from './env';

initGA();

let component: any = App;
if (NODE_ENV !== 'development') {
  component = withTracker(App as any);
}

ReactDOM.render(
  <BrowserRouter>
    <Route component={component} />
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
