import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

import InfoPanel from './InfoPanel';
import { option } from '../../charts/humidity';
import { humidityText } from '../../data/texts';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
  });

interface IHumidity {
  readonly time: Date;
  readonly humidity: number;
}

interface IProps {
  readonly classes: any;
  readonly data: ReadonlyArray<IHumidity>;
  readonly infopanel?: boolean;
}

class Humidity extends React.Component<IProps> {
  render() {
    const { classes, data, infopanel } = this.props;

    return (
      <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
        {infopanel ? (
          <InfoPanel
            title="Humidity"
            content={<Typography>{humidityText}</Typography>}
          />
        ) : (
          <Typography variant="h6" gutterBottom={true}>
            Humidity
          </Typography>
        )}
        <ReactEcharts
          option={option(
            data.map(obj => obj.time),
            data.map(obj => obj.humidity),
          )}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(Humidity);
