import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import variables from '../../styles/variables';

const styles = {
  collapsable: {
    paddingLeft: variables.subMenuItemPadding,
  },
};

interface IApartment {
  id: string;
  number: string;
}

interface IState {
  open: boolean;
}

interface IProps {
  apartment: IApartment;
  classes: any;
  history: any;
}

class ApartmentSection extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  handleApartmentClick = () => {
    this.setState(state => ({
      ...state,
      open: !state.open,
    }));
  };

  render() {
    const { apartment, classes, history } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <ListItem button={true} onClick={this.handleApartmentClick}>
          <ListItemIcon>
            <HomeIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={apartment.number} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit={true}>
          <List disablePadding={true} className="drawer-menu__sublist">
            <ListItem
              button={true}
              classes={{ root: classes.collapsable }}
              onClick={() => history.push(`/apartments/${apartment.id}`)}
            >
              <ListItemIcon>
                <DashboardIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button={true}
              classes={{ root: classes.collapsable }}
              onClick={() =>
                history.push(`/apartments/${apartment.id}/conditions`)
              }
            >
              <ListItemIcon>
                <BarChartIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Conditions" />
            </ListItem>
            <ListItem
              button={true}
              classes={{ root: classes.collapsable }}
              onClick={() =>
                history.push(`/apartments/${apartment.id}/consumption`)
              }
            >
              <ListItemIcon>
                <ShowChartIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Consumption" />
            </ListItem>
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ApartmentSection);
