import { indigo } from '@material-ui/core/colors';
import { format } from 'date-fns';
import theme from '../styles/theme';

export const option = (dates: Date[], values: number[]) => ({
  textStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  tooltip: {
    trigger: 'axis',
    formatter: params =>
      `
      ${format(new Date(params[0].name), 'd.M.yyyy')}
      <br />
      ${format(new Date(params[0].name), 'HH:mm:ss')}
      <br />
      ${params[0].marker} ${params[0].value} ppm
    `,
  },
  xAxis: {
    type: 'category',
    data: dates,
    axisLabel: {
      formatter: value => format(new Date(value), 'd.M. HH:mm'),
      margin: 14,
    },
    boundaryGap: false,
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dotted',
      },
    },
    name: 'ppm',
    min: 200,
    max: 2000,
  },
  grid: {
    top: 33,
    left: 40,
    right: 20,
    bottom: 70,
  },
  dataZoom: [
    {
      type: 'inside',
      start: 75,
    },
    {
      type: 'slider',
      start: 75,
      labelFormatter: value => format(new Date(dates[value]), 'd.M. HH:mm'),
    },
  ],
  series: [
    {
      data: values,
      type: 'line',
      symbol: 'rect',
      silent: true,
    },
  ],
  legend: {
    icon: 'rect',
  },
  color: indigo[500],
});
