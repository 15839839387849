import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';

import SensorForm from './SensorForm';
import { ISensor } from './types';
import layouts from '../../styles/layouts';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
  });

interface IProps {
  classes: any;
}

class AddSensor extends React.Component<
  IProps & RouteComponentProps<{ devEUI: string }>
> {
  render() {
    const { classes } = this.props;
    const newSensor: ISensor = {
      devEUI: '',
      location: '',
      properties: [],
      notes: '',
      apartment: undefined,
    };

    return (
      <section className={classes.contentWrapper}>
        <SensorForm sensor={newSensor} addSensor={true} />
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(AddSensor));
