import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { get } from 'lodash';

import Error from '../common/Error';
import Loading from '../common/Loading';
import SensorGraph from '../common/SensorGraph';
import { APARTMENTS_SENSORS_QUERY } from '../../data/queries';
import layouts from '../../styles/layouts';
import { ISensor } from '../sensors/types';
import { isConsumptionSensor } from './helpers';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    sensorName: {
      margin: '24px 0px',
    },
  });

class Conditions extends React.Component<
  { classes: any } & RouteComponentProps<{ id: string }>
> {
  render() {
    // console.log('Conditions.render()');
    const { classes } = this.props;
    const { id } = this.props.match.params;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Conditions
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Query
          query={APARTMENTS_SENSORS_QUERY}
          variables={{ id }}
          pollInterval={60000}
        >
          {({ data, error, loading }) => (
            <React.Fragment>
              {error ? (
                <Error />
              ) : loading ? (
                <Loading />
              ) : (
                <React.Fragment>
                  {get(data, 'apartments[0].sensors', []).length > 0 ? (
                    data.apartments[0].sensors.map((sensor: ISensor) =>
                      sensor && sensor.data && !isConsumptionSensor(sensor) ? (
                        <section key={sensor.devEUI}>
                          <Typography
                            variant="h5"
                            gutterBottom={true}
                            className={classes.sensorName}
                          >
                            Sensor location: {sensor.location || 'Unknown'}
                          </Typography>
                          <SensorGraph
                            sensor={sensor}
                            averages={true}
                            wideGraphs={true}
                          />
                        </section>
                      ) : null,
                    )
                  ) : (
                    <Typography variant="body1">
                      Apartment has no sensors.
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Query>
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(Conditions));
