import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    loading: {
      maxWidth: variables.loadingBarWidth,
    },
  });

class Loading extends React.Component<{ classes: any }> {
  render() {
    const { classes } = this.props;

    return <LinearProgress className={classes.loading} />;
  }
}

export default withStyles(styles)(Loading);
