import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    tabs: {
      margin: variables.tabsMargin,
    },
    scrollButtons: {
      flex: '0 0',
    },
  });

interface IProps {
  tab: any;
  classes: any;
}

class Overview extends React.Component<IProps & RouteComponentProps> {
  handleTabChange = (_, value) => {
    const { history } = this.props;

    switch (value) {
      case 0:
        history.push('/buildings');
        break;
      case 1:
        history.push('/buildings/temperature');
        break;
      case 2:
        history.push('/buildings/humidity');
        break;
      case 3:
        history.push('/buildings/co2');
        break;
      case 4:
        history.push('/buildings/electricity');
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, tab } = this.props;

    return (
      <Tabs
        value={tab}
        onChange={this.handleTabChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="secondary"
        textColor="secondary"
        className={classes.tabs}
        classes={{ scrollButtons: classes.scrollButtons }}
      >
        <Tab label="Summary" />
        <Tab label="Temperature" />
        <Tab label="Humidity" />
        <Tab label="CO2" />
        <Tab label="Electricity" />
      </Tabs>
    );
  }
}

export default withRouter(withStyles(styles)(Overview));
