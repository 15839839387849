/***
  For testing purposes. -EP
***/
import React from 'react';
import { Query } from 'react-apollo';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TEST_BUILDINGS_QUERY } from '../data/queries';

class Test extends React.Component {
  render() {
    return (
      <Query query={TEST_BUILDINGS_QUERY}>
        {({ data, error, loading }) => (
          <React.Fragment>
            <Typography variant="h2" align="center" gutterBottom={true}>
              Test view
            </Typography>
            {!error ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Number of Apartments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading ? (
                    data.buildings.map(item => (
                      <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.address}</TableCell>
                        <TableCell>{item.apartments.length}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h4" align="center">
                          <CircularProgress /> Loading..
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="h4" color="error">
                {error.message}
              </Typography>
            )}
          </React.Fragment>
        )}
      </Query>
    );
  }
}

export default Test;
