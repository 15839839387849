import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import DescriptionIcon from '@material-ui/icons/DescriptionSharp';

class AdminSection extends React.Component<{} & RouteComponentProps> {
  render() {
    const { history } = this.props;

    return (
      <React.Fragment>
        <ListSubheader disableSticky={true}>Admin</ListSubheader>
        <ListItem button={true} onClick={() => history.push('/systemlog')}>
          <ListItemIcon>
            <DescriptionIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="System log" />
        </ListItem>
        <ListItem button={true} onClick={() => history.push('/debug')}>
          <ListItemIcon>
            <BugReportIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Debug" />
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  }
}

export default withRouter(AdminSection);
