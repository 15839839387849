import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { get } from 'lodash';

import layouts from '../../styles/layouts';
import {
  CURRENT_USER_QUERY,
  USERS_WITH_APARTMENTS_QUERY,
} from '../../data/queries';

const styles = () =>
  createStyles({
    ...layouts.contentWrapper,
    icon: {
      verticalAlign: 'text-bottom',
      height: '20px',
    },
    paragraph: {
      margin: '16px 0px',
    },
  });

class Home extends React.Component<{ classes: any } & RouteComponentProps> {
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.contentWrapper}>
        <Typography variant="h4" color="secondary" gutterBottom={true}>
          Welcome
        </Typography>
        <Divider className={classes.contentWrapperDivider} />
        <Query query={CURRENT_USER_QUERY}>
          {({ data: { currentUser } }) => (
            <Query
              query={USERS_WITH_APARTMENTS_QUERY}
              variables={{ id: get(currentUser, 'id') }}
            >
              {({ data, loading, error }) => (
                <React.Fragment>
                  {!loading &&
                    !error &&
                    get(data, 'users[0].apartments', []).length === 0 && (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.paragraph }}
                      >
                        There are no apartments linked to your user account.
                      </Typography>
                    )}
                  {!loading && get(data, 'users[0].apartments', []).length > 0 && (
                    <Typography
                      variant="body1"
                      classes={{ root: classes.paragraph }}
                    >
                      You can monitor your apartment from the
                      <DashboardIcon
                        color="secondary"
                        classes={{ root: classes.icon }}
                      />
                      Dashboard, as well as
                      <BarChartIcon
                        color="secondary"
                        classes={{ root: classes.icon }}
                      />
                      Conditions and
                      <ShowChartIcon
                        color="secondary"
                        classes={{ root: classes.icon }}
                      />
                      Consumption pages.
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    classes={{ root: classes.paragraph }}
                  >
                    Information about apartment buildings can be viewed from the
                    <LocationCityIcon
                      color="secondary"
                      classes={{ root: classes.icon }}
                    />
                    Buildings section.
                  </Typography>
                </React.Fragment>
              )}
            </Query>
          )}
        </Query>
      </section>
    );
  }
}

export default withStyles(styles)(withRouter(Home));
