import React from 'react';
import { createStyles, WithStyles, withStyles, Link } from '@material-ui/core';

import area21Logos from '../../images/AREA21_logot.png';
import tuniLogo from '../../images/tuni_logo_face.jpg';

const styles = createStyles({
  footer: {
    position: 'fixed',
    top: 'auto',
    left: 'auto',
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    zIndex: 1100,
    boxSizing: 'border-box',
    flexShrink: 0,
    flexDirection: 'row',
    backgroundColor: '#FFF',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  fillWidth: {
    flexGrow: 1,
  },
  logos: {
    maxWidth: '100vw',
    height: '60px',
  },
  tunilogo: {
    maxWidth: '100vw',
    height: '55px',
    paddingTop: '10px',
    marginRight: '1em',
  },
});

interface IProps extends WithStyles<typeof styles> {}

const Area21Footer: React.FC<IProps> = props => {
  const { classes } = props;
  return (
    <div className={classes.footer}>
      <div className={classes.fillWidth} />
      <Link href="https://area21-project.eu" target="_blank">
        <img
          src={area21Logos}
          className={classes.logos}
          alt="Interreg Baltic Sea Region, EU European Regional Development Fund"
          title="Interreg Baltic Sea Region, EU European Regional Development Fund"
        />
      </Link>
      <Link href="https://www.tuni.fi/en" target="_blank">
        <img
          src={tuniLogo}
          className={classes.tunilogo}
          alt="Tampere University of Applied Sciences"
          title="Tampere University of Applied Sciences"
        />
      </Link>
    </div>
  );
};

export default withStyles(styles)(Area21Footer);
