import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import { MenuItem } from '@material-ui/core';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';

import { withOoth } from '../../auth/OothContext';
import { defaults } from '../../data/defaults';

import ErrorDialog from '../dialogs/ErrorDialog';

interface IProps {
  oothClient: any;
  toggleMenu: any;
}

interface IState {
  loggingOut: boolean;
  errorDialog: boolean;
}

class LogoutMenuItem extends React.Component<
  IProps & RouteComponentProps,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      loggingOut: false,
      errorDialog: false,
    };
  }

  handleLogout = async apolloClient => {
    const { history, oothClient } = this.props;
    try {
      // console.log('Logging out...');

      this.setState(state => ({ ...state, loggingOut: true }));
      await oothClient.logout();
      apolloClient.onResetStore(() => {
        apolloClient.cache.writeData({
          data: {
            ...defaults,
          },
        });
        history.push('/login');
      });
      await apolloClient.resetStore();
    } catch (e) {
      this.setState(state => ({
        ...state,
        loggingOut: false,
        errorDialog: true,
      }));
      // console.log(e);
    } finally {
      this.props.toggleMenu();
    }
  };

  render() {
    const { loggingOut, errorDialog } = this.state;

    return (
      <ApolloConsumer>
        {apolloClient => (
          <React.Fragment>
            <MenuItem
              onClick={() => this.handleLogout(apolloClient)}
              disabled={loggingOut}
            >
              <ExitToAppSharpIcon color="secondary" />
              &nbsp; Logout
            </MenuItem>
            <ErrorDialog
              open={errorDialog}
              title="Error logging out"
              message="An error occurred logging out. Please try again later."
              onClose={() =>
                this.setState(state => ({ ...state, errorDialog: false }))
              }
            />
          </React.Fragment>
        )}
      </ApolloConsumer>
    );
  }
}

export default withRouter(withOoth(LogoutMenuItem));
