import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

import InfoPanel from './InfoPanel';
import { option } from '../../charts/co2';
import { co2Text } from '../../data/texts';
import variables from '../../styles/variables';

const styles = () =>
  createStyles({
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
  });

interface ICO2 {
  readonly time: Date;
  readonly co2: number;
}

interface IProps {
  readonly classes: any;
  readonly data: ReadonlyArray<ICO2>;
  readonly infopanel?: boolean;
}

class CO2 extends React.Component<IProps> {
  render() {
    const { classes, data, infopanel } = this.props;

    return (
      <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
        {infopanel ? (
          <InfoPanel title="CO2" content={<Typography>{co2Text}</Typography>} />
        ) : (
          <Typography variant="h6" gutterBottom={true}>
            CO2
          </Typography>
        )}
        <ReactEcharts
          option={option(data.map(obj => obj.time), data.map(obj => obj.co2))}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(CO2);
