import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button, TextField, Snackbar, IconButton } from '@material-ui/core';
import variables from '../../styles/variables';
import CloseIcon from '@material-ui/icons/Close';
import ShowPasswordToggle from './ShowPasswordToggle';
import { Mutation } from 'react-apollo';
import Loading from './Loading';
import { UPDATE_USER_PASSWORD_MUTATION } from '../../data/mutations';

const styles = () =>
  createStyles({
    form: {
      width: variables.formWidth,
      display: 'flex',
      flexDirection: 'column',
    },
    saveButton: {
      marginTop: '10px',
    },
  });

interface IProps {
  classes: any;
}

interface IState {
  currentPw: string;
  newPw: string;
  repeatPw: string;
  currentPwValid: boolean;
  newPwValid: boolean;
  repeatPwValid: boolean;
  showCurrent: boolean;
  showNew: boolean;
  showRepeat: boolean;
  showSuccess: boolean;
  showError: boolean;
  errorMessage: string;
}

class ChangePasswordForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      currentPw: '',
      newPw: '',
      repeatPw: '',
      currentPwValid: true,
      newPwValid: true,
      repeatPwValid: true,
      showCurrent: false,
      showNew: false,
      showRepeat: false,
      showSuccess: false,
      showError: false,
      errorMessage: '',
    };
  }

  handleChange = (name: string) => (event: any) => {
    const value = event.target.value;
    this.setState(state => ({ ...state, [name]: value }));
  };

  toggleVisibility = (name: string) => {
    this.setState(state => ({ ...state, [name]: !state[name] }));
  };

  handleSave = updatePassword => {
    // TODO: Password rules (length etc)
    const { currentPw, newPw, repeatPw } = this.state;
    const currentPwValid = currentPw.length > 5;
    const newPwValid = newPw.length > 5;
    const repeatPwValid = repeatPw.length > 5 && newPw === repeatPw;
    this.setState(state => ({
      ...state,
      currentPwValid,
      newPwValid,
      repeatPwValid,
    }));
    if (currentPwValid && newPwValid && repeatPwValid) {
      updatePassword({
        variables: { currentPassword: currentPw, newPassword: newPw },
      }).then(() => {
        this.setState({
          showSuccess: true,
          currentPw: '',
          newPw: '',
          repeatPw: '',
        });
      });
    }
  };

  onFeedbackClose = () => {
    this.setState({ showSuccess: false, showError: false, errorMessage: '' });
  };

  render() {
    const { classes } = this.props;
    const {
      currentPw,
      newPw,
      repeatPw,
      currentPwValid,
      newPwValid,
      repeatPwValid,
      showCurrent,
      showNew,
      showRepeat,
      showSuccess,
      showError,
      errorMessage,
    } = this.state;

    return (
      <form className={classes.form} autoComplete="off">
        <TextField
          error={!currentPwValid}
          label="Current password"
          id="currentPw"
          value={currentPw}
          variant="outlined"
          type={showCurrent ? 'text' : 'password'}
          onChange={this.handleChange('currentPw')}
          helperText={currentPwValid ? ' ' : 'Invalid password'}
          margin="dense"
          InputProps={{
            endAdornment: (
              <ShowPasswordToggle
                visible={showCurrent}
                toggleVisibility={() => this.toggleVisibility('showCurrent')}
              />
            ),
          }}
        />
        <TextField
          error={!newPwValid}
          label="New password"
          id="newPw"
          value={newPw}
          variant="outlined"
          type={showNew ? 'text' : 'password'}
          onChange={this.handleChange('newPw')}
          helperText={newPwValid ? ' ' : 'Invalid password'}
          margin="dense"
          InputProps={{
            endAdornment: (
              <ShowPasswordToggle
                visible={showNew}
                toggleVisibility={() => this.toggleVisibility('showNew')}
              />
            ),
          }}
        />
        <TextField
          error={!repeatPwValid}
          label="Repeat password"
          id="repeatPw"
          value={repeatPw}
          variant="outlined"
          type={showRepeat ? 'text' : 'password'}
          onChange={this.handleChange('repeatPw')}
          helperText={repeatPwValid ? ' ' : 'Invalid password'}
          margin="dense"
          InputProps={{
            endAdornment: (
              <ShowPasswordToggle
                visible={showRepeat}
                toggleVisibility={() => this.toggleVisibility('showRepeat')}
              />
            ),
          }}
        />
        <Mutation
          mutation={UPDATE_USER_PASSWORD_MUTATION}
          onError={error =>
            this.setState({ showError: true, errorMessage: error.message })
          }
        >
          {(updatePassword, { loading }) =>
            loading ? (
              <Loading />
            ) : (
              <>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={showSuccess}
                  autoHideDuration={4000}
                  onClose={this.onFeedbackClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">Password changed</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.onFeedbackClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSave(updatePassword)}
                  disabled={loading}
                >
                  Change password
                </Button>
              </>
            )
          }
        </Mutation>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showError}
          autoHideDuration={6000}
          onClose={this.onFeedbackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{`Error: ${errorMessage}`}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.onFeedbackClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </form>
    );
  }
}

export default withStyles(styles)(ChangePasswordForm);
