import React from 'react';
import { Route } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AUTHENTICATED_QUERY, CURRENT_USER_QUERY } from '../data/queries';
import { UserLevel } from '../utils/userLevel';
import Welcome from '../components/tenant/Welcome';
import Buildings from '../components/buildings/Buildings';
import ErrorPage from '../components/common/ErrorPage';
import SystemLog from '../components/admin/SystemLog';

interface IProps {
  exact: boolean;
  path: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      margin: '1em',
      padding: '5em',
    },
  }),
);

const HomeRoute: React.SFC<IProps> = props => {
  const classes = useStyles();

  return (
    <Query query={AUTHENTICATED_QUERY}>
      {({ data }) =>
        typeof data === 'undefined' || !data.authenticated ? (
          <Route
            {...props}
            render={() => (
              <Paper className={classes.paper}>
                <Typography variant="h4" gutterBottom={true}>
                  Welcome to the Energy App!
                </Typography>
                <br />
                <Typography paragraph={true}>
                  As a non-logged in user, you can only see the{' '}
                  <b>public building data</b>. The <b>login button</b> can be
                  found under the <b>3-dot menu</b> (upper right corner).
                  <br />
                  <br />
                  After logging in, you can view the{' '}
                  <b>data of your apartment</b>.
                  <br />
                  <br />
                  At the moment, the application will show you the basic data
                  from your apartment, visualized as graphs.
                  <br />
                  In the future, we are planning to add more features, to help
                  you save energy.
                  <br />
                  <br />
                  Have a nice day, and remember to save energy!
                </Typography>
              </Paper>
            )}
          />
        ) : (
          <Query query={CURRENT_USER_QUERY}>
            {({
              data: {
                currentUser: { userLevel },
              },
            }) => (
              <React.Fragment>
                {userLevel === UserLevel.Tenant && (
                  <Route
                    {...props}
                    render={routeProps => <Welcome {...routeProps} />}
                  />
                )}
                {userLevel === UserLevel.Staff && (
                  <Route {...props} render={() => <Buildings />} />
                )}
                {userLevel === UserLevel.Admin && (
                  <Route
                    {...props}
                    render={routeProps => <SystemLog {...routeProps} />}
                  />
                )}
                {userLevel === null && (
                  <Route
                    {...props}
                    render={routeProps => <ErrorPage {...routeProps} />}
                  />
                )}
              </React.Fragment>
            )}
          </Query>
        )
      }
    </Query>
  );
};

export default HomeRoute;
