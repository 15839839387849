import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

import InfoPanel from './InfoPanel';
import { electricityText } from '../../data/texts';
import variables from '../../styles/variables';
import { electricityOption } from '../../charts/electricity';
import Usage from './EnergyUsage';

const styles = () =>
  createStyles({
    paper: {
      padding: variables.paperPadding,
      height: '100%',
    },
    subheading: {
      fontSize: '1.125rem',
      marginTop: '16px',
    },
  });

interface IPower {
  readonly time: Date;
  readonly power: number;
}

interface IProps {
  readonly classes: any;
  readonly data: ReadonlyArray<IPower>;
  readonly infopanel?: boolean;
  readonly usage?: boolean;
}

class Electricity extends React.Component<IProps> {
  render() {
    const { classes, data, infopanel, usage } = this.props;
    // console.log('Electricity.render()');
    const chartData = data.filter(d => d.power != null);
    return (
      <Paper elevation={1} classes={{ root: classes.paper }} square={true}>
        {infopanel ? (
          <InfoPanel
            title="Electricity"
            content={<Typography>{electricityText}</Typography>}
          />
        ) : (
          <Typography variant="h6" gutterBottom={true}>
            Electricity
          </Typography>
        )}
        <ReactEcharts option={electricityOption(chartData)} />
        {usage && <Usage data={data} />}
      </Paper>
    );
  }
}

export default withStyles(styles)(Electricity);
