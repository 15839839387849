import { convert, LevelName } from './userLevel';

interface IOothUser {
  _id: string;
  local: {
    email: string;
  };
  profile: {
    firstName: string;
    lastName: string;
    userLevel: LevelName;
  };
}

interface IUser {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  userLevel: LevelName;
}

export const mapUser = (user: IOothUser) => {
  return {
    __typename: 'CurrentUser',
    id: user._id,
    email: user.local.email,
    firstName: user.profile.firstName ? user.profile.firstName : '',
    lastName: user.profile.lastName ? user.profile.lastName : '',
    userLevel: convert(user.profile.userLevel),
  };
};

export const initials = (user: IUser) => {
  const { firstName, lastName } = user;
  const first = firstName ? firstName.substring(0, 1).toUpperCase() : null;
  const last = lastName ? lastName.substring(0, 1).toUpperCase() : null;
  return first && last ? `${first}${last}` : null;
};
