import React from 'react';
import { Typography } from '@material-ui/core';

interface IProps {
  message?: string;
  variant?: any;
  color?: any;
}

class Error extends React.Component<IProps> {
  render() {
    const { message, variant, ...rest } = this.props;

    return (
      <Typography variant={variant || 'body1'} {...rest}>
        {message ? message : 'An error occurred. Please try again later.'}
      </Typography>
    );
  }
}

export default Error;
