import React from 'react';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';

import Loading from '../common/Loading';
import Error from '../common/Error';
import { APARTMENTS_QUERY } from '../../data';
import { get } from 'lodash';

const styles = () =>
  createStyles({
    button: {
      marginTop: '24px',
    },
  });

interface IApartment {
  id: string;
  number: string;
}

interface IProps {
  classes: any;
  save: any;
  cancel: any;
}

interface IState {
  selectedApartment: string | IApartment;
}

class ApartmentAdder extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedApartment: '',
    };
  }

  handleSave = () => {
    const { selectedApartment } = this.state;
    if (typeof selectedApartment !== 'string') {
      this.props.save(selectedApartment.id);
    }
  };

  handleCancel = () => {
    this.props.cancel();
  };

  handleApartmentChange = event => {
    this.setState(state => ({
      ...state,
      selectedApartment: event.target.value,
    }));
  };

  render() {
    const { classes } = this.props;
    const { selectedApartment } = this.state;

    return (
      <Query query={APARTMENTS_QUERY}>
        {({ loading, error, data }) =>
          loading ? (
            <Loading />
          ) : error ? (
            <Error message="Couldn't load apartments." />
          ) : (
            <React.Fragment>
              <FormControl variant="outlined" margin="normal">
                <InputLabel>Apartment</InputLabel>
                <Select
                  value={selectedApartment}
                  onChange={this.handleApartmentChange}
                  input={<OutlinedInput name="apartment" labelWidth={80} />}
                >
                  {get(data, 'apartments', []).map(apartment => (
                    <MenuItem key={apartment.id} value={apartment}>
                      {apartment.number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleCancel}
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default withStyles(styles)(ApartmentAdder);
