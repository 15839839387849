import { format } from 'date-fns';
import theme from '../styles/theme';

export const option = (data: any) => ({
  dataset: {
    dimensions: ['time', 'cold', 'hot', 'total'],
    source: [
      [...data.map(item => item.time)],
      [...data.map(item => item.cold)],
      [...data.map(item => item.hot)],
      [...data.map(item => item.cold + item.hot)],
    ],
  },
  series: [
    {
      name: 'cold',
      type: 'line',
      encode: {
        x: 'time',
        y: 'cold',
      },
      symbol: 'rect',
      silent: true,
      // sampling: 'avg',
      seriesLayoutBy: 'row',
      itemStyle: {
        color: 'blue',
      },
    },
    {
      name: 'hot',
      type: 'line',
      encode: {
        x: 'time',
        y: 'hot',
      },
      symbol: 'rect',
      silent: true,
      // sampling: 'avg',
      seriesLayoutBy: 'row',
      itemStyle: {
        color: 'red',
      },
    },
    {
      name: 'total',
      type: 'line',
      encode: {
        x: 'time',
        y: 'total',
      },
      symbol: 'rect',
      silent: true,
      // sampling: 'avg',
      seriesLayoutBy: 'row',
      itemStyle: {
        color: 'black',
      },
    },
  ],
  xAxis: {
    type: 'time',
    axisLabel: {
      formatter: value => format(new Date(value), 'd.M. HH:mm'),
      margin: 14,
    },
    boundaryGap: false,
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dotted',
      },
    },
    name: 'liters',
    min: 0,
  },
  textStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  tooltip: {
    trigger: 'axis',
    formatter: ([
      {
        data: [time, cold, hot, total],
      },
    ]) =>
      `
      ${format(new Date(time), 'd.M.yyyy')}
      <br />
      ${format(new Date(time), 'HH:mm:ss')}
      <br />
      ${total} l (total)
      <br />
      ${cold} l (cold)
      <br />
      ${hot} l (hot)
    `,
  },
  grid: {
    top: 33,
    left: 40,
    bottom: 70,
  },
  dataZoom: [
    {
      type: 'inside',
      start: 75,
    },
    {
      type: 'slider',
      start: 75,
      labelFormatter: value => format(new Date(value), 'd.M. HH:mm'),
    },
  ],
  legend: {
    icon: 'rect',
    data: ['total', 'cold', 'hot'],
    selected: { total: true, cold: false, hot: false },
  },
});
