import React from 'react';
import { Route } from 'react-router-dom';
import { Query } from 'react-apollo';

import { AUTHENTICATED_QUERY, CURRENT_USER_QUERY } from '../data/queries';
import { UserLevel } from '../utils/userLevel';
import PermissionDenied from '../components/common/PermissionDenied';

interface IProps {
  component: any;
  exact?: boolean;
  path: string;
}

class StaffRoute extends React.Component<IProps> {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Query query={AUTHENTICATED_QUERY}>
        {({ data: { authenticated } }) =>
          !authenticated ? (
            <Route
              {...rest}
              render={props => <PermissionDenied {...props} />}
            />
          ) : (
            <Query query={CURRENT_USER_QUERY}>
              {({
                data: {
                  currentUser: { userLevel },
                },
              }) =>
                userLevel > UserLevel.Tenant ? (
                  <Route {...rest} render={props => <Component {...props} />} />
                ) : (
                  <Route
                    {...rest}
                    render={props => <PermissionDenied {...props} />}
                  />
                )
              }
            </Query>
          )
        }
      </Query>
    );
  }
}

export default StaffRoute;
