import React from 'react';
import { Query } from 'react-apollo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { get } from 'lodash';

import Loading from '../common/Loading';
import Error from '../common/Error';
import { BUILDINGS_APARTMENTS_QUERY } from '../../data';

const styles = () =>
  createStyles({
    button: {
      marginTop: '24px',
    },
  });

interface IBuilding {
  id?: string;
  address: string;
}

interface IApartment {
  id: string;
  number: string;
  building: IBuilding;
}

interface IProps {
  classes: any;
  save: any;
  cancel: any;
  disabled?: boolean;
}

interface IState {
  selectedBuilding: string | IBuilding;
  selectedApartment: string | IApartment;
}

class ApartmentSelector extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedBuilding: '',
      selectedApartment: '',
    };
  }

  handleSave = () => {
    const { selectedApartment } = this.state;
    if (typeof selectedApartment !== 'string') {
      this.props.save({ ...selectedApartment });
    }
  };

  handleCancel = () => {
    this.props.cancel();
  };

  handleBuildingChange = event => {
    this.setState(state => ({
      ...state,
      selectedBuilding: event.target.value,
      selectedApartment: '',
    }));
  };

  handleApartmentChange = event => {
    this.setState(state => ({
      ...state,
      selectedApartment: event.target.value,
    }));
  };

  apartments = data => {
    const { selectedBuilding } = this.state;
    if (typeof selectedBuilding !== 'string') {
      if (selectedBuilding.id === 'other') {
        return data.apartments.map(items => ({
          ...items,
          building: { id: 'other' },
        }));
      }
      const building = get(data, 'buildings', []).find(
        obj => obj.id === selectedBuilding.id,
      );
      if (building) {
        return get(building, 'apartments', []);
      }
    }
    return [];
  };

  render() {
    const { classes, disabled } = this.props;
    const { selectedBuilding, selectedApartment } = this.state;

    return (
      <Query query={BUILDINGS_APARTMENTS_QUERY}>
        {({ loading, error, data }) =>
          loading ? (
            <Loading />
          ) : error ? (
            <Error message="Couldn't load buildings." />
          ) : (
            <React.Fragment>
              <FormControl variant="outlined" margin="normal">
                <InputLabel>Building</InputLabel>
                <Select
                  value={selectedBuilding}
                  renderValue={(value: any) => value.address}
                  disabled={disabled}
                  onChange={this.handleBuildingChange}
                  input={<OutlinedInput name="building" labelWidth={60} />}
                >
                  {get(data, 'buildings', [])
                    .concat([{ id: 'other', address: 'Not in building' }])
                    .map(building => (
                      <MenuItem key={building.id} value={building}>
                        {building.address}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" margin="normal">
                <InputLabel>Apartment</InputLabel>
                <Select
                  value={selectedApartment}
                  disabled={disabled}
                  onChange={this.handleApartmentChange}
                  input={<OutlinedInput name="apartment" labelWidth={80} />}
                >
                  {this.apartments(data).map(apartment => (
                    <MenuItem key={apartment.id} value={apartment}>
                      {apartment.number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                    disabled={disabled}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button
                    onClick={this.handleCancel}
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    classes={{ root: classes.button }}
                    disabled={disabled}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default withStyles(styles)(ApartmentSelector);
