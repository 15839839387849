import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Chip, Grid, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonSharp';
import { IUser } from '../sensors/types';

const styles = () =>
  createStyles({
    chip: {
      fontSize: '1rem',
    },
  });

interface IProps extends RouteComponentProps {
  classes: any;
  tenant: IUser;
  deletable: boolean;
  onDelete: (id: string) => void;
}

const tenantChipName = (tenant: IUser) => {
  const name = `${tenant.firstName} ${tenant.lastName}`;
  return name.length > 1 ? name : tenant.email;
};

class TenantChip extends React.Component<IProps> {
  render() {
    const { classes, history, tenant, deletable, onDelete } = this.props;
    const { id, firstName, lastName, email } = this.props.tenant;

    return (
      <Grid item={true} key={id} xs={12} md={6}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            {deletable ? (
              <Chip
                classes={{ root: classes.chip }}
                label={tenantChipName(tenant)}
                variant="outlined"
                color="secondary"
                icon={<PersonIcon />}
                onClick={() => history.push(`/users/${id}`)}
                onDelete={() => onDelete(id)}
              />
            ) : (
              <Chip
                classes={{ root: classes.chip }}
                label={tenantChipName(tenant)}
                variant="outlined"
                color="secondary"
                icon={<PersonIcon />}
                onClick={() => history.push(`/users/${id}`)}
              />
            )}
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Typography variant="body1">Firstname</Typography>
            <Typography>{firstName}</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Typography variant="body1">Lastname</Typography>
            <Typography>{lastName}</Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="body1">Email</Typography>
            <Typography>{email}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(TenantChip));
